<template>
    <v-container v-if="userAvatar.avatar_user.length > 0">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"/>
        <v-row class="my-4">
            <v-col :class="{'center_items': edit_avatar, 'left_items': !edit_avatar}" cols="12" sm="5" md="4">
                <div class="all-avatar">
                    <h1 v-if="edit_avatar">EDITAR PERSONAJE</h1>
                    <div class="avatar" :class="{'dimension_avatar': edit_avatar}">
                        <template v-if="!loading_avatar">
                            <div class="user__avatar" :class="{'dimension_user__avatar': edit_avatar}">
                                <div class="avatar__background" :style="{backgroundColor: userAvatar.avatar_user[0].avatar_background?.code_color}"></div>
                                <div class="avatar__hair" :style="style_modify_hair">
                                    <img :src="userAvatar.storage_avatar+'/'+userAvatar.avatar_user[0].avatar_hair?.image"/>
                                </div>
                                <div class="avatar__body" :style="style_modify_body">
                                    <img :src="userAvatar.storage_avatar+'/'+userAvatar.avatar_user[0].avatar_body?.image"/>
                                </div>
                                <div class="avatar__eyes" :style="style_modify_eye">
                                    <img :src="userAvatar.storage_avatar+'/'+userAvatar.avatar_user[0].avatar_eye?.image"/>
                                </div>
                                <div class="avatar__glasses">
                                    <img v-if="userAvatar.avatar_user[0].avatar_glass" :src="userAvatar.storage_avatar+'/glasses_default.png'"/>
                                </div>
                                <div class="avatar__clothes" :style="style_modify_clothe">
                                    <img :src="userAvatar.storage_avatar+'/'+userAvatar.avatar_user[0].avatar_clothe?.image"/>
                                </div>
                            </div>
                            <div v-if="!edit_avatar" class="info-points">
                                <p class="points-general-user"><v-icon>mdi-school-outline</v-icon>{{ points.total_score }} pts</p>
                            </div>
                        </template>
                        <template v-if="loading_avatar">
                            <v-skeleton-loader class="skeleton_avatar" :loading="loading_avatar" type="card">
                            </v-skeleton-loader>
                        </template>
                    </div>
                    <div v-if="edit_avatar">
                        <v-btn class="info-lesson mt-5" @click="editAvatar()">
                            <div class="mr-3">
                                <v-icon>mdi-arrow-left</v-icon>
                            </div>
                            <div>
                                <div class="option-button">Back</div>
                            </div>
                        </v-btn>
                    </div>
                </div>
                <div v-if="!edit_avatar">
                    <div class="status-user">
                        <template v-if="!loading_avatar">
                            <div class="img-range">
                                <!--<v-img alt="" class="" cover src="@/assets/images/Subtract.png" />-->
                                <ImagesRange :data="current_range" :width="'32'"/>
                            </div>
                            <p>{{ userAvatar.name }}</p>
                            <label>{{ current_range?.value }}</label>
                        </template>
                        <template v-if="loading_avatar">
                            <v-skeleton-loader class="skeleton_avatar" :loading="loading_avatar" type="chip">
                            </v-skeleton-loader>
                            <v-skeleton-loader class="skeleton_avatar" :loading="loading_avatar" type="list-item-two-line">
                            </v-skeleton-loader>
                        </template>
                        
                    </div>
                    <div class="options-user">
                        <template v-if="!loading_avatar">
                            <v-btn class="info-lesson" @click="editUser()">
                                <div>
                                    <div class="option-button">Datos personales</div>
                                </div>
                                <div>
                                    <v-icon>mdi-arrow-right</v-icon>
                                </div>
                            </v-btn>
                            <v-btn class="info-lesson" @click="editAvatar()">
                                <div>
                                    <div class="option-button">Editar personaje</div>
                                </div>
                                <div>
                                    <v-icon>mdi-arrow-right</v-icon>
                                </div>
                            </v-btn>
                            <v-btn class="info-lesson" @click="logout()">
                                <div>
                                    <div class="option-button-logout">Cerrar sesión</div>
                                </div>
                                <div>
                                    <v-icon>mdi-logout</v-icon>
                                </div>
                            </v-btn>
                        </template>
                        <template v-if="loading_avatar">
                            <v-skeleton-loader class="skeleton_avatar" :loading="loading_avatar" type="list-item, list-item, list-item">
                            </v-skeleton-loader>
                        </template>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="7" md="8">
                <div v-if="edit_user" class="form-edit-user">
                    <v-form v-model="valid" class="edit-user px-3 py-2">
                        <v-row>
                            <v-col align="start" cols="12" md="12">
                                <v-text-field
                                        v-model="user.name"
                                        :rules="rules.name"
                                        label="Nombre"
                                        required
                                        variant="underlined"
                                ></v-text-field>
                            </v-col>
                            <v-col align="start" cols="12" md="12">
                                <v-text-field
                                        v-model="userSession.email"
                                        label="Email"
                                        disabled
                                        variant="underlined"
                                ></v-text-field>
                            </v-col>
                            <v-col align="start" cols="12" md="12">
                                <v-file-input 
                                    variant="underlined"
                                    :label="'Foto (recomendado width:'+recomend_width_img+'px, height:'+recomend_height_img+'px)'"
                                    accept="image/png, image/jpg, image/jpeg"
                                    append-inner-icon
                                    @change="uploadArchive($event)">
                                </v-file-input>
                                <v-col v-if="user.image_photo" class="position-relative" cols="12" sm="3" md="2">
                                    <v-img :src="user.image_photo"/>
                                </v-col>
                            </v-col>
                            <v-row class="pt-4">
                                <v-col cols="12" md="12" class="d-flex">
                                    <v-btn class="mx-auto btn-blue" @click="update">
                                        Actualizar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-form>
                </div>

                <div v-if="edit_avatar" class="avatar-edit">
                    <div class="options_avatar">
                        <template v-if="!loading_edit_avatar">
                            <div class="option_line">
                                <div class="options_body">
                                    <p>Piel</p>
                                    <div class="bodies">
                                        <div v-for="item in avatar_bodies" class="content_body" :style="{backgroundColor: item.code_color}" @click="onSelectBody(item)">
                                            <v-icon v-if="select_body == item.code_color">mdi-check</v-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="options_background">
                                    <p>Fondo</p>
                                    <div class="backgrounds">
                                        <div v-for="item in avatar_backgrounds" class="content_background" :style="{backgroundColor: item.code_color}" @click="onSelectBackground(item)">
                                            <v-icon v-if="select_background == item.code_color">mdi-check</v-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="option_line pt-5">
                                <div class="options_eye">
                                    <p>Ojos</p>
                                    <div class="eyes">
                                        <div v-for="item in avatar_eyes" class="content_eye" @click="onSelectEye(item)">
                                            <img :src="userAvatar.storage_avatar+'/'+item.image" :class="{'select_eye': select_eye != item.image}"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="options_glass">
                                    <p>Lentes</p>
                                    <div class="glasses">
                                        <div class="content_glass" @click="onSelectGlass(1)"><label :class="{'select_glass': !select_glass}">Si</label></div>
                                        <div class="content_glass" @click="onSelectGlass(0)"><label :class="{'select_glass': select_glass}">No</label></div>
                                    </div>
                                </div>
                            </div>

                            <div class="option_line pt-5">
                                <div class="options_hair">
                                    <p>Cabello</p>
                                    <div class="hairs">
                                        <div v-for="item in avatar_hairs" class="content_hair" @click="onSelectHair(item)">
                                            <img :src="userAvatar.storage_avatar+'/'+item.image" :class="{'select_hair': select_hair != item.image}"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="option_line pt-5">
                                <div class="options_clothe">
                                    <p>Ropa</p>
                                    <div class="clothes">
                                        <div v-for="item in avatar_clothes" class="content_clothe" @click="onSelectClothe(item)">
                                            <img :src="userAvatar.storage_avatar+'/'+item.image" :class="{'select_clothe': select_clothe != item.image}"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="loading_edit_avatar">
                            <div class="option_line">
                                <div class="options_body">
                                    <v-skeleton-loader class="skeleton_edit_avatar" :loading="loading_edit_avatar" type="heading">
                                    </v-skeleton-loader>
                                    <div class="bodies">
                                        <div v-for="n in 4" class="content_body">
                                            <v-skeleton-loader class="skeleton_edit_avatar" :loading="loading_edit_avatar" type="image">
                                            </v-skeleton-loader>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div  v-for="m in 2" class="option_line pt-5">
                                <div class="options_body">
                                    <v-skeleton-loader class="skeleton_edit_avatar" :loading="loading_edit_avatar" type="heading">
                                    </v-skeleton-loader>
                                    <div class="bodies">
                                        <div v-for="n in 4" class="content_body">
                                            <v-skeleton-loader class="skeleton_edit_avatar" :loading="loading_edit_avatar" type="image">
                                            </v-skeleton-loader>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </v-col>
        </v-row>
        <div class="content_message_point_avatar" :class="{'active_message':message_first_update_avatar}">
            <v-img src="@/assets/images/message_points/message_5.png"></v-img>
        </div>
    </v-container>
    <v-container v-if="userAvatar.avatar_user.length == 0">
        <v-row class="my-4 justify-sm-center justify-md-start">
            <v-col align="start" cols="12" sm="12" md="12">
                <h1 style="font-size: 24px;">ELIJA SU AVATAR</h1>
            </v-col>
            <v-col cols="12" sm="8" md="4">
                <div class="all-avatar">
                    <div class="avatar dimension_avatar">
                        <template v-if="!loading_avatar">
                            <div class="user__avatar dimension_user__avatar choose_avatar">
                                <div class="avatar__background" :style="{backgroundColor: '#71ffff'}"></div>
                                <div class="avatar__hair">
                                    <img :src="userAvatar.storage_avatar+'/hair_default.png'"/>
                                </div>
                                <div class="avatar__body">
                                    <img :src="userAvatar.storage_avatar+'/body_default.png'"/>
                                </div>
                                <div class="avatar__eyes">
                                    <img :src="userAvatar.storage_avatar+'/eyes_default.png'"/>
                                </div>
                                <div class="avatar__glasses">
                                    <img v-if="true" :src="userAvatar.storage_avatar+'/glasses_default.png'"/>
                                </div>
                                <div class="avatar__clothes">
                                    <img :src="userAvatar.storage_avatar+'/clothes_default.png'"/>
                                </div>
                            </div>
                        </template>
                        <template v-if="loading_avatar">
                            <v-skeleton-loader class="skeleton_avatar" :loading="loading_avatar" type="card">
                            </v-skeleton-loader>
                        </template>
                    </div>
                    <div>
                        <v-btn class="info-lesson mt-5" @click="chooseAvatar('M')">
                            <div>
                                <div class="option-button">Elegir</div>
                            </div>
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="2">
            </v-col>
            <v-col cols="12" sm="8" md="4">
                <div class="all-avatar">
                    <div class="avatar dimension_avatar">
                        <template v-if="!loading_avatar">
                            <div class="user__avatar dimension_user__avatar choose_avatar">
                                <div class="avatar__background" :style="{backgroundColor: '#ff67fd'}"></div>
                                <div class="avatar__hair" :style="{left: '47.8%', top: '2px'}">
                                    <img :src="userAvatar.storage_avatar+'/female/hair_default.png'"/>
                                </div>
                                <div class="avatar__body">
                                    <img :src="userAvatar.storage_avatar+'/female/body_default.png'"/>
                                </div>
                                <div class="avatar__eyes">
                                    <img :src="userAvatar.storage_avatar+'/eyes_default.png'"/>
                                </div>
                                <div class="avatar__glasses">
                                    <img v-if="true" :src="userAvatar.storage_avatar+'/glasses_default.png'"/>
                                </div>
                                <div class="avatar__clothes" :style="{'z-index':'15'}">
                                    <img :src="userAvatar.storage_avatar+'/female/clothes_default.png'"/>
                                </div>
                            </div>
                        </template>
                        <template v-if="loading_avatar">
                            <v-skeleton-loader class="skeleton_avatar" :loading="loading_avatar" type="card">
                            </v-skeleton-loader>
                        </template>
                    </div>
                    <div>
                        <v-btn class="info-lesson mt-5" @click="chooseAvatar('F')">
                            <div>
                                <div class="option-button">Elegir</div>
                            </div>
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <div class="options-user">
                    <template v-if="!loading_avatar">
                        <v-btn class="info-lesson" @click="logout()">
                            <div>
                                <div class="option-button-logout">Cerrar sesión</div>
                            </div>
                            <div>
                                <v-icon>mdi-logout</v-icon>
                            </div>
                        </v-btn>
                    </template>
                    <template v-if="loading_avatar">
                        <v-skeleton-loader class="skeleton_avatar" :loading="loading_avatar" type="list-item">
                        </v-skeleton-loader>
                    </template>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.all-avatar h1{
    font-family: 'BebasNeue-Regular';
    font-size: 24px;
    font-weight: 400;
    color: #0D1329;
    padding-bottom: 18px;
}
.avatar-edit {
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 30px;
    padding: 60px 50px;
    width: 430px;
    margin: 0 auto;
}
.option_line {
    display: flex;
    justify-content: space-between;
}
.option_line .options_body,
.option_line .options_background,
.option_line .options_eye,
.option_line .options_glass,
.option_line .options_hair,
.option_line .options_clothe {
    text-align: justify;
}
.option_line .options_body p,
.option_line .options_background p,
.option_line .options_eye p,
.option_line .options_glass p,
.option_line .options_hair p,
.option_line .options_clothe p{
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 15px;
}
.option_line .options_body .bodies,
.option_line .options_background .backgrounds,
.option_line .options_glass .glasses {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.option_line .options_eye .eyes,
.option_line .options_hair .hairs,
.option_line .options_clothe .clothes {

    display: flex;
    flex-wrap: wrap;
    gap: 35px;
}
.option_line .options_body .bodies .content_body,
.option_line .options_background .backgrounds .content_background{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
}
.option_line .options_eye .eyes .content_eye{
    cursor: pointer;
    width: 54px
}
.option_line .options_hair .hairs .content_hair{
    cursor: pointer;
    width: 44px
}
.option_line .options_clothe .clothes .content_clothe{
    cursor: pointer;
    width: 65px
}
.option_line .options_eye .eyes .content_eye img,
.option_line .options_hair .hairs .content_hair img,
.option_line .options_clothe .clothes .content_clothe img{
    width: 100%;
}
.option_line .options_eye .eyes .content_eye img.select_eye,
.option_line .options_hair .hairs .content_hair img.select_hair,
.option_line .options_clothe .clothes .content_clothe img.select_clothe{
    opacity: .2;
}
.option_line .options_glass .glasses .content_glass{
    display: flex;
    align-items: center;
    width: 40px;
    height: 32px;
}
.option_line .options_glass .glasses .content_glass label{
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}
.option_line .options_glass .glasses .content_glass label.select_glass{
    opacity: .2;
}
.option_line .options_body .bodies .content_body i,
.option_line .options_background .backgrounds .content_background i{
    font-size: 16px;
}
.user__avatar{
    width: 220px;
    height: 250px;
    position: relative;
    z-index: 50;
    overflow: hidden;
}
.user__avatar.dimension_user__avatar{
    width: 300px;
}
.user__avatar.dimension_user__avatar.choose_avatar {
    width: 100%;
}
.avatar__background{
    height: 85%;
    width: 100%;
    bottom: 0;
    position: absolute;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
}
.user__avatar.dimension_user__avatar .avatar__background{
    border-radius: 0;
}
.avatar__hair{
    position: absolute;
    z-index: 10;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.avatar__body{
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
}
.avatar__eyes{
    position: absolute;
    top: 78px;
    left: 50%;
    transform: translateX(-50%);
}
.avatar__glasses{
    position: absolute;
    top: 81px;
    left: 50%;
    transform: translateX(-50%);
}
.avatar__clothes{
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-49.7%);
}
.info-points{
    background: linear-gradient(172deg, rgba(129,36,126,1) 0%, rgba(28,30,80,1) 47%, rgba(9,121,120,0.9920343137254902) 95%);
    color: #ffffff;
    width: max-content;
    padding: 6px 15px;
    border-end-end-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    bottom: 0;
    z-index: 50;
}
.info-points .points-general-user{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
}
.center_items {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.left_items {
    text-align: left;
}
.avatar {
    position: relative;
}
.center_items .avatar {
    width: 220px;
    height: 250px;
    margin: 0 auto;
}
.center_items .avatar.dimension_avatar{
    width: 300px;
}
.left_items .avatar {
    width: 220px;
    height: 250px;
}
.status-user {
    margin-top: 40px;
}
.status-user .img-range {
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
}
.status-user p {
    font-size: 20px;
    font-weight: 700;
    color: #3D4254;
}
.status-user label {
    font-size: 12px;
    font-weight: 700;
    color: #868994;
}
.options-user .info-lesson {
    display: block !important;
    width: 100%;
    padding: 10px;
    height: max-content;
    margin-top: 15px;
}
.options-user {
    padding-top: 30px;
}
.options-user .info-lesson .option-button {
    font-size: 16px;
    font-weight: 400;
    color: #0D1329;
}
.options-user .info-lesson .option-button-logout{
    font-size: 16px;
    font-weight: 700;
    color: #0D1329;
}
.form-edit-user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 150px;
}
.form-edit-user .edit-user {
    width: 100%;
}
.form-edit-user .edit-user .v-file-input {
    display: flex;
    flex-direction: row-reverse;
}
.form-edit-user .edit-user .v-file-input .v-input__control {
    width: 100%;
}
.form-edit-user .edit-user .box-img-user{
    width: 100px;
    margin-top: 25px;
}
.skeleton_avatar{
    background-color: transparent;
    height: 100%;
}
.skeleton_avatar .v-skeleton-loader__card .v-skeleton-loader__image {
    height: 200px;
}
.skeleton_avatar .v-skeleton-loader__chip,
.skeleton_avatar .v-skeleton-loader__list-item,
.skeleton_avatar .v-skeleton-loader__card .v-skeleton-loader__heading,
.skeleton_avatar .v-skeleton-loader__list-item-two-line .v-skeleton-loader__sentences .v-skeleton-loader__text{
    margin-left: 0;
}
.skeleton_edit_avatar {
    background-color: transparent;
    width: 100%;
    overflow: hidden;
}
.skeleton_edit_avatar .v-skeleton-loader__heading {
    margin-left: 0;
}
.skeleton_edit_avatar .v-skeleton-loader__image {
    height: 32px !important;
}

.content_message_point_avatar {
    width: 280px;
    position: absolute;
    height: max-content;
    top: 88%;
    left: 108%;
    opacity: 0;
    transform: translate(-50%, -50%);
}
.content_message_point_avatar.active_message{
    left: 85%;
    opacity: 1;
    transition: 1.5s all;
}
@media (max-width: 1279px) {
    .form-edit-user {
        padding: 0 100px;
    }
}
@media (max-width: 959px) {
    .form-edit-user {
        padding: 0 40px;
    }
    .avatar-edit{
        padding: 40px 25px;
        width: 340px;
    }
    .center_items .avatar.dimension_avatar {
        width: 220px;
    }
    .user__avatar.dimension_user__avatar {
        width: 100%;
    }
}
@media (max-width: 400px) {
    .form-edit-user {
        padding: 0px;
    }
    .avatar-edit {
        width: 100%;
    }
}
</style>

<script>
import { mapGetters } from "vuex";
import userService from "@/composables/user";
import avatarUserService from "@/composables/avatar_user";
import positionVideoService from "@/composables/position_videos";
import { toast } from 'vue3-toastify';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';
import ImagesRange from "../views/dashboard/imagesRange.vue";

export default {
    name:"Account",
    components: {
        Loading,
        VSkeletonLoader,
        ImagesRange
    },
    data: () => ({
        message_first_update_avatar: false,
        loading_avatar: true,
        loading_edit_avatar: true,
        count_edit: 0,
        isLoading: false,
        valid: false,
        edit_user: false,
        edit_avatar: false,
        avatar_bodies: [],
        avatar_backgrounds: [],
        avatar_eyes: [],
        avatar_hairs: [],
        avatar_clothes: [],
        select_body: null,
        select_background: null,
        select_eye: null,
        select_hair: null,
        select_clothe: null,
        select_glass: null,
        points: 0,
        user: {
            name: null,
            //email: null,
            image_photo: null,
        },
        current_range: null,
        recomend_width_img: 54,
        recomend_height_img: 54,
        style_modify_body: null,
        style_modify_hair: null,
        style_modify_clothe: null,
        style_modify_eye: null,
        rules: {
            name: [
                v => !!v || 'Nombre es requerido',
            ],
            /*email: [
                v => !!v || 'Email es requerido',
            ],*/
        },
    }),
    computed:{
        //Obtenemos la configuración de nuestro avatar y datos del usuario
        ...mapGetters(["userAvatar", "userSession"]),
    },
    mounted() {
        this.user = this.userSession;
        let data = this.userSession.data_dashboard;
        if (data) {
            this.current_range = this.userSession.ranges.find(item => item.id == data.rango_red);
        }
        if (this.userAvatar.avatar_user.length > 0) {
            this.select_glass = this.userAvatar.avatar_user[0].avatar_glass;
            this.processData();
        }
        
        setTimeout(() => {
            this.loading_avatar = false;
        }, 1000)
        if(this.edit_avatar) {
            setTimeout(() =>{
                this.loading_edit_avatar = false
            }, 1000);
        }
    },
    watch: {
    },
    methods: {
        editUser() {
            this.edit_user = !this.edit_user;
            this.edit_avatar = false
        },
        editAvatar() {
            this.edit_avatar = !this.edit_avatar;
            this.edit_user = false;
            this.count_edit ++;
            if (this.count_edit < 2) {
                setTimeout(() => {
                    this.loading_edit_avatar = false;
                }, 1000)
            } 
        },
        processData() {
            this.dataAvatar();
            this.pointUser();
            this.getAvatarBody();
            this.getAvatarBackground();
            this.getAvatarEye();
            this.getAvatarHair();
            this.getAvatarClothe();
        },
        dataAvatar() {
            //Verificamos si las partes del avatar tienen estilos definidos
            let body = this.userAvatar.avatar_user[0].avatar_body?.style_modify;
            let hair = this.userAvatar.avatar_user[0].avatar_hair?.style_modify;
            let clothe = this.userAvatar.avatar_user[0].avatar_clothe?.style_modify;
            let eye = this.userAvatar.avatar_user[0].avatar_eye?.style_modify;
            if (body) {
                this.style_modify_body = JSON.parse(body);
            } else {
                this.style_modify_body = null;
            }
            if (hair) {
                this.style_modify_hair = JSON.parse(hair);
            } else {
                this.style_modify_hair = null;
            }
            if (clothe) {
                this.style_modify_clothe = JSON.parse(clothe);
            } else {
                this.style_modify_clothe = null;
            }
            if (eye) {
                this.style_modify_eye = JSON.parse(eye);
            } else {
                this.style_modify_clothe = null;
            }
        },
        pointUser() {
            userService.getMyPoints(this.user.id).then(response => {
                this.points = response.data.data;
            }).catch(e => {
                console.log(e);
            })
        },
        getAvatarBody() {
            avatarUserService.getAvatarBody().then(response => {
                //Verificamos si las partes del avatar son de género Masculino o Femenino
                if (this.userAvatar.gender == 'F') {
                    response.data.data.forEach(item => {
                        if (item.image.includes('female')) {
                            this.avatar_bodies.push(item);
                        }
                    })
                } else {
                    response.data.data.forEach(item => {
                        if (!item.image.includes('female')) {
                            this.avatar_bodies.push(item);
                        }
                    })
                }
                this.select_body = this.userAvatar.avatar_user[0].avatar_body?.code_color;
            }, error => {
                console.log(error);
            });
        },
        getAvatarBackground() {
            avatarUserService.getAvatarBackground().then(response => {
                this.avatar_backgrounds = response.data.data;
                this.select_background = this.userAvatar.avatar_user[0].avatar_background?.code_color;
            }, error => {
                console.log(error);
            });
        },
        getAvatarEye() {
            avatarUserService.getAvatarEye().then(response => {
                this.avatar_eyes = response.data.data;
                this.select_eye = this.userAvatar.avatar_user[0].avatar_eye?.image;
            }, error => {
                console.log(error);
            });
        },
        getAvatarHair() {
            avatarUserService.getAvatarHair().then(response => {
                //Verificamos si las partes del avatar son de género Masculino o Femenino
                if (this.userAvatar.gender == 'F') {
                    response.data.data.forEach(item => {
                        if (item.image.includes('female')) {
                            this.avatar_hairs.push(item);
                        }
                    })
                } else {
                    response.data.data.forEach(item => {
                        if (!item.image.includes('female')) {
                            this.avatar_hairs.push(item);
                        }
                    })
                }
                this.select_hair = this.userAvatar.avatar_user[0].avatar_hair?.image;
            }, error => {
                console.log(error);
            });
        },
        getAvatarClothe() {
            avatarUserService.getAvatarClothe().then(response => {
                //Verificamos si las partes del avatar son de género Masculino o Femenino
                if (this.userAvatar.gender == 'F') {
                    response.data.data.forEach(item => {
                        if (item.image.includes('female')) {
                            this.avatar_clothes.push(item);
                        }
                    })
                } else {
                    response.data.data.forEach(item => {
                        if (!item.image.includes('female')) {
                            this.avatar_clothes.push(item);
                        }
                    })
                }
                this.select_clothe = this.userAvatar.avatar_user[0].avatar_clothe?.image;
            }, error => {
                console.log(error);
            });
        },
        onSelectBody(item) {
            this.select_body = item.code_color;
            this.user.avatar_user[0].avatar_body_id = item.id;
            this.updateAvatar(this.user.avatar_user[0]);
        },
        onSelectBackground(item) {
            this.select_background = item.code_color;
            this.user.avatar_user[0].avatar_background_id = item.id;
            this.updateAvatar(this.user.avatar_user[0]);
        },
        onSelectEye(item) {
            this.select_eye = item.image;
            this.user.avatar_user[0].avatar_eye_id = item.id;
            this.updateAvatar(this.user.avatar_user[0]);
        },
        onSelectGlass(validate) {
            this.select_glass = validate;
            this.user.avatar_user[0].avatar_glass = validate;
            this.updateAvatar(this.user.avatar_user[0]);
        },
        onSelectHair(item) {
            this.select_hair = item.image;
            this.user.avatar_user[0].avatar_hair_id = item.id;
            this.updateAvatar(this.user.avatar_user[0]);
        },
        onSelectClothe(item) {
            this.select_clothe = item.image;
            this.user.avatar_user[0].avatar_clothe_id = item.id;
            this.updateAvatar(this.user.avatar_user[0]);
        },
        updateAvatar(data) {
            this.isLoading = true
            //Actualizamos el avatar que tenemos en el localStorage
            this.$store.dispatch('updateAvatar',{item: data}).then(response => {
                this.dataAvatar();
                this.isLoading = false;
                toast.success('Cambio realizado', {autoClose: 1500});
                let first_update_avatar = response.data.message_first_update_avatar;
                if (first_update_avatar) {
                    this.message_first_update_avatar = true;
                    setTimeout(() => {
                        this.message_first_update_avatar = false;
                    }, 5000);
                }
            }, error => {
                toast.error('Ocurrio un error', {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            })
        },
        update() {
            this.isLoading = true;
            let item = Object.assign({}, this.user);

            this.$store.dispatch('update', {item: item}).then(response => {
                this.isLoading = false;
                this.$router.push({path: '/account'})
                toast.success(response.data.message, {autoClose: 1500});
            }, error => {
                toast.error(JSON.stringify(error.errors), {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            });
        },
        uploadArchive(e) {
            this.isLoading = true;
            let file = e.target.files[0];
            let formData = new FormData();
            formData.append('width', this.recomend_width_img);
            formData.append('height', this.recomend_height_img);
            formData.append('file', file);
            formData.append('path', 'usuarios');
            
            userService.uploadFileImage(formData).then(response => {
                toast.success(response.data.message, {autoClose: 1500});
                this.user.image_photo = response.data.file_url;
                this.isLoading = false;
            }, error => {
                toast.error(error.response.data.message, {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            });
        },
        chooseAvatar(gender) {
            if (this.userSession.role == 'student') {
                this.isLoading = true;
                let item = Object.assign({}, this.user);
                item.gender = gender;
                this.$store.dispatch('chooseAvatar', {item: item}).then(response => {
                    this.isLoading = false;
                    this.select_glass = this.userAvatar.avatar_user[0].avatar_glass;
                    this.processData();
                    toast.success(response.data.message, {autoClose: 1500});
                }, error => {
                    toast.error(JSON.stringify(error.errors), {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push('/login')
            })
        },
    },
    created() {
    }
}
</script>