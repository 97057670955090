<template>
    <v-row class="section__login" justify="center" style="margin: 0;">
        <v-col cols="6" md="7" class="v-col-md-7 v-col-6 d-none d-md-block image__login">
            <!--<v-img alt="" cover src="@/assets/images/login.png" width="100%" height="100%"/>-->
        </v-col>
        <v-col cols="12" md="5" sm="8" align="center" class="position-relative">
            <div class="content_info__login px-lg-14 px-md-10 px-4 pt-md-0 pt-8">
                <v-img alt="" class="align-center mb-12 d-md-none d-flex" container src="@/assets/images/Brand.png"
                       width="80"/>
                <v-form action @submit.prevent="resetPassword" class="form__login">
                    <div class="text__login font-main-bold text-md-left text-center mb-8">RECUPERAR CONTRASEÑA</div>

                    <v-text-field label="Email" required id="email" v-model="email" density="compact"
                                  variant="outlined"
                                  color="E7E7EA">
                    </v-text-field>
                    <v-btn color="#00FFFF" class="font-weight-bold text-capitalize" rounded="xl" size="x-large" block
                           elevation="0" value="login" type="submit" :disabled="loading">
                        <v-progress-circular v-if="loading" indeterminate class="mr-2"></v-progress-circular> Recuperar contraseña
                    </v-btn>

                    <v-btn color="#00FFFF" class="font-weight-bold text-capitalize mt-2" rounded="xl" :to="'/login'" size="x-large" block
                           elevation="0" value="login" type="button" >
                        Login
                    </v-btn>
                </v-form>
            </div>
            <div class="footer__login">
                <div class="content__footer">
                    <label>© LEGACY</label>
                    <label>2023</label>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<style scoped>
.section__login {
    height: 100%;
}
.content_info__login{
    width: 100%;
    position: absolute;
    top: 42%;
    transform: translateY(-50%);
}
.text__login {
    font-size: 68px;
}
.form__login {
    width: 70%;
}

.footer__login{
    width: 58%;
    bottom: 40px;
    position: absolute;
    left: 52%;
    transform: translateX(-50%);
}
.footer__login .content__footer{
    display: flex;
    justify-content: space-between;
}
.footer__login .content__footer label{
    font-size: 12px;
    font-weight: 400;
    color: #868994;
}
@media (max-width: 1520px) {
    .form__login {
        width: 100%;
    }
    .footer__login {
        width: 80%;
    }
}
@media (max-width: 1150px) {
    .text__login{
        font-size: 35px;
    }
    .footer__login {
        bottom: 15px;
    }
}
@media (max-width: 959px) {
    .content_info__login{
        top: 0%;
        transform: none;
        position: relative;
    }
}
@media (max-height: 640px) {
    .content_info__login {
        position: relative;
        top: 0;
        transform: none;
    }
    .footer__login {
        position: relative;
        left: 0;
        transform: none;
        bottom: 0px;
        padding-top: 40px;
    }
}
</style>

<script>
import { toast } from 'vue3-toastify';
import authService from "@/composables/auth";
export default {
    name: "Reset-Password",
    data: () => ({
        loading: false,
        email: null,
        error: false,
    }),
    methods: {
        resetPassword() {
            this.loading = true;
            authService.resetPassword(this.email).then(response => {
                this.loading = false;
                toast.success(response.data.message, {autoClose: 2000});
            }).catch((err) => {
                this.loading = false;
                toast.error(err.response.data.message, {autoClose: 2000});
                console.log(err);
            });
        },
    },
};
</script>
