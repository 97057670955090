<template>
    <div class="ma-lg-6 ma-0">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"/>
        <v-card>
            <Breadcrumbs :items="breadcrumbs"/>
            <v-form v-model="valid" class="px-3 py-2">
                <v-row>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Título</h4>
                        <v-text-field
                                v-model="news.title"
                                :rules="rules.title"
                                label="Título"
                                required
                                outlined
                                solo
                        ></v-text-field>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Imagen principal</h4>
                        <v-file-input 
                            variant="underlined"
                            :label="'Imagen (recomendado width:'+recomend_width_img+'px, height:'+recomend_height_img+'px)'"
                            accept="image/png, image/jpg, image/jpeg"
                            @change="uploadArchive1($event)">
                        </v-file-input>
                        <v-col v-if="news.main_image" class="position-relative" cols="12" sm="6" md="3">
                            <v-img :src="news.main_image"/>
                        </v-col>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Párrafos</h4>
                        <v-row v-for="(item, i) in news.paragraphs">
                            <v-col align="start" cols="12" md="8">
                                <v-textarea v-model="item.text" rows="2" label="Párrafo" v-on:keyup="paragraphText()"></v-textarea>
                            </v-col>
                            <v-col align="start" cols="12" md="4" class="d-flex justify-space-between">
                                <v-btn v-if="i == news.paragraphs.length-1" :disabled="validate_paragraph" @click="addParagraph()">
                                    <v-icon>mdi-plus-circle</v-icon>
                                    Add
                                </v-btn>
                                <v-btn v-if="i || i != news.paragraphs.length > 1" @click="removeParagraph(i)">
                                    <v-icon>mdi-minus-circle</v-icon>
                                    Remove
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Habilitar imagen o video</h4>
                        <v-switch
                            v-model="news.enable_image_video"
                            hide-details
                            inset
                            :label="`${news.enable_image_video ? 'Si' : 'No'}`" 
                        ></v-switch>
                    </v-col>
                    <v-col v-if="news.enable_image_video" align="start" cols="12" md="8">
                        <h4 class="mb-3">Posición de los párrafos</h4>
                        <v-radio-group v-model="news.position_paragraphs" inline>
                            <v-radio color="info" label="Derecha" value="1"></v-radio>
                            <v-radio color="info" label="Izquierda" value="-1"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col v-if="news.enable_image_video" align="start" cols="12" md="8">
                        <h4 class="mb-3">Agregar imagen o video</h4>
                        <v-radio-group v-model="image_video" inline >
                            <v-radio color="info" label="Imagen" value="1" @click="radioSelect($event)"></v-radio>
                            <v-radio color="info" label="Video" value="2" @click="radioSelect($event)"></v-radio>
                        </v-radio-group>

                    </v-col>
                    <v-col v-if="news.enable_image_video && image_video == '1'" align="start" cols="12" md="8">
                        <h4 class="mb-3">Imagen</h4>
                        <v-file-input 
                            variant="underlined"
                            :label="'Imagen'"
                            accept="image/png, image/jpg, image/jpeg"
                            @change="uploadArchive2($event)">
                        </v-file-input>
                        <v-col v-if="news.image" class="position-relative" cols="12" sm="6" md="3">
                            <v-img :src="news.image"/>
                        </v-col>
                    </v-col>
                    <v-col v-if="news.enable_image_video && image_video == '2'" align="start" cols="12" md="8">
                        <h4 class="mb-3">Método a usar</h4>
                        <v-switch
                            v-model="select_video_type"
                            hide-details
                            inset
                            @click="news.video = null; news.video_vimeo = null"
                            :label="`${select_video_type ? 'Subir archivo' : 'Vimeo'}`" 
                        ></v-switch>
                        
                        <template v-if="select_video_type">
                            <h4 class="mb-3">Subir archivo</h4>
                            <v-file-input 
                                variant="underlined"
                                label="Archivo"
                                accept="video/mp4"
                                @change="uploadArchive3($event)">
                            </v-file-input>
                            <v-col v-if="news.video" class="position-relative" cols="12" sm="6" md="4">
                                <Video :data="news.video"></Video>
                            </v-col>
                        </template>
                        <template v-if="!select_video_type">
                            <h4 class="mb-3">Vimeo</h4>
                            <v-text-field
                                v-model="news.video_vimeo"
                                label="ID video "
                                type="number"
                                required
                                outlined
                                solo
                            ></v-text-field>
                            <v-col v-if="news.video_vimeo" class="position-relative" cols="12" sm="6" md="4">
                                <vue-vimeo-player ref="newsVimeo" :video-id="news.video_vimeo" :player-width="300" :player-height="200"/>
                            </v-col>
                        </template>
                    </v-col>
                </v-row>

                <v-row class="pb-2">
                    <v-col cols="12" md="12" class="d-flex">
                        <v-btn :disabled="!valid" class="mx-auto btn-blue" @click="update">
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </div>
</template>

<style>
.v-switch {
    width: max-content;
}
</style>

<script>
import {vueVimeoPlayer} from 'vue-vimeo-player'
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import newsService from "@/composables/news";
import { toast } from 'vue3-toastify';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Video from '../video.vue';

export default {
    name: 'NewsCreated',
    components: {
        Breadcrumbs,
        Loading,
        Video,
        vueVimeoPlayer
    },
    data: () => ({
        isLoading: false,
        validate_paragraph: true,
        breadcrumbs: [
            {text: 'Noticias', disabled: false, to: '/noticias',},
            {text: 'Crear noticia', disabled: false, to: '/noticia/create',}
        ],
        image_video: null,
        select_video_type: true,
        news: {
            title: null,
            main_image: null,
            paragraphs: [
                {
                    text: '',
                }
            ],
            enable_image_video: true,
            position_paragraphs: '1',
            image: null,
            video_type: 1,
            video: null,
            video_vimeo: null,
        },
        recomend_width_img: 570,
        recomend_height_img: 570,
        recomend_width_img_secondary: null,
        recomend_height_img_secondary: null,
        valid: false,
        rules: {
            title: [
                v => !!v || 'Nombre es requerido',
            ],
        },
    }),
    mounted() {
        this.show(this.$route.params.id);
    },
    methods: {
        show(news_id) {
            newsService.show(news_id).then(response => {
                this.news = response.data.data;
                this.news.paragraphs = JSON.parse(this.news.paragraphs);
                this.news.enable_image_video = this.news.enable_image_video == 1 ? true : false;
                this.select_video_type = this.news.video_type == 1 ? true : false;
                if (this.news.image) {
                    this.image_video = '1';
                }
                if (this.news.video || this.news.video_vimeo) {
                    this.image_video = '2';
                }
            }, error => {
                console.log(error);
            })
        },
        paragraphText() {
            let count = 0
            this.news.paragraphs.forEach(item => {
                let letter = item.text.trim();
                if(letter.length == 0) {
                    count++;
                }
            })
            if (count > 0) {
                this.validate_paragraph = true;
            } else {
                this.validate_paragraph = false;
            }
        },
        addParagraph() {
            this.news.paragraphs.push({text: ''});
            this.paragraphText();
        },
        removeParagraph(index) {
            this.news.paragraphs.splice(index, 1);
            this.paragraphText();
        },
        radioSelect(e) {
            let data = e.target._value;
            if (data === "1") {
                this.news.video = null;
                this.news.video_vimeo = null;
            } else {
                this.news.image = null;
            }
        },
        update() {
            this.isLoading = true;
            let item = Object.assign({}, this.news);
            item.paragraphs = JSON.stringify(item.paragraphs);
            if (item.enable_image_video) {
                if (!item.image && (!item.video && !item.video_vimeo)) {
                    toast.error('Habilito imagen o video, necesita agregar uno de ellos', {autoClose: 2000});
                    this.isLoading = false;
                    return;
                }
            } else {
                item.position_paragraphs = '1';
                item.image = null;
                item.video = null;
                item.video_vimeo = null;
            }
            item.video_type = this.select_video_type ? 1 : 2;

            newsService.update(item).then(response => {
                this.isLoading = false;
                this.$router.push({path: '/noticias'})
            }, error => {
                toast.error(JSON.stringify(error.response.data.errors), {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            });
        },
        uploadArchive1(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('width', this.recomend_width_img);
                formData.append('height', this.recomend_height_img);
                formData.append('file', file);
                formData.append('path', 'noticias-image');
                
                newsService.uploadFileImage(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.news.main_image = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
        async uploadArchive2(e) {
            if(e.target.files.length > 0) {
                const photoUrl = URL.createObjectURL(e.target.files[0]);
                const image = new Image();
                const imageDimensions = await new Promise((resolve) => {
                    image.onload = () => {
                        const dimensions = {
                        height: image.height,
                        width: image.width,
                        };
                        resolve(dimensions);
                    };
                    image.src = photoUrl;
                });
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                console.log("data: ", e.target.files[0]);
                formData.append('width', imageDimensions.width);
                formData.append('height', imageDimensions.height);
                formData.append('file', file);
                formData.append('path', 'noticias-image');
                
                newsService.uploadFileImage(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.news.image = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
        uploadArchive3(e) {
            if(e.target.files.length > 0) {
                this.news.video = null;
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('file', file);
                formData.append('path', 'noticias-video');
                
                newsService.uploadFile(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.news.video = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
    }
}
</script>