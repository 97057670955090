import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import 'vue3-toastify/dist/index.css';
import './css/app.css';
import axios from "axios";
import 'vue-loading-overlay/dist/css/index.css';
import vue3GoogleLogin from 'vue3-google-login';

loadFonts()

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

const app = createApp(App)
  .use(router)
  .use(vue3GoogleLogin, {
    clientId: '542889885989-vqm87vnnl45g67rkqjfiusht83ouhbbj.apps.googleusercontent.com'
  })
  .use(store)
  .use(vuetify)
  .mount('#app')
