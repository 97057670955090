<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12">
                <p v-if="!loading_title_category" class="text-left font-weight-bold mb-4 text-h5">{{ name_category }}</p>
                <v-skeleton-loader v-if="loading_title_category" class="skeleton_title_category" :loading="true" type="heading">
                </v-skeleton-loader>
            </v-col>
        </v-row>
            <v-tabs v-model="tab" >
            <v-tab value="1" variant="outlined" rounded max-height="34" class="mr-3 text-capitalize" style="border: 1px solid #CFD0D4;
                border-radius: 34px;color: #3D4254;">Básico</v-tab>
            <v-tab value="2" variant="outlined" rounded max-height="34" class="mr-3 text-capitalize" style="border: 1px solid #CFD0D4;
                border-radius: 34px;color: #3D4254;">Intermedio</v-tab>
            <v-tab value="3" variant="outlined" rounded max-height="34" class="mr-3 text-capitalize" style="border: 1px solid #CFD0D4;
                border-radius: 34px;color: #3D4254;">Avanzado</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item value="1">
                <template v-if="!loading_courses">
                    <v-row class="my-4" v-for="item in subcategory">
                        <v-col cols="12" md="12">
                            <div class="font-weight-bold text-left" style="color: #6E717F;">{{ item.name }}</div>
                        </v-col>
                        <v-col class="pb-1" cols="12" sm="12" md="6" lg="4" v-for="(info, index) in item.course_basic">
                            <v-divider v-if="index == 1" class="separator-courses d-lg-none d-md-none d-sm-block "></v-divider>
                            <v-divider v-if="index == 2" class="separator-courses d-lg-none d-block "></v-divider>
                            <v-divider v-if="index > 2" class="separator-courses"></v-divider>
                            <v-btn class="content-info" :to="{path: `/course/${info.name_link}`}">
                                <v-avatar class="avatar" size="80" rounded="0">
                                    <v-img alt="" class="" cover :src="info.image" max-width="80" />
                                </v-avatar>
                                <div class="w-100 px-4">
                                    <div class="d-flex justify-space-between pb-1">
                                        <div class="font-weight-bold">{{ info.name }}</div>
                                        <div class="justify-self-end"><v-icon size="small">mdi-arrow-right</v-icon></div>
                                    </div>
                                    <div class="d-flex flex-row align-center flex-wrap">
                                        <div class="fs-14 pr-5 pt-1" style="font-weight: 500;"><v-icon color="#FF80FF"
                                                size="small">mdi-text-box-multiple-outline</v-icon> {{ info.count_lessons }} Lecciones</div>
                                        <div class="justify-self-end font-weight-medium fs-14 pt-1"><v-icon color="#FF80FF"
                                                size="small">mdi-clock-time-five-outline</v-icon> {{ info.duration }}</div>
                                    </div>
                                </div>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="loading_courses">
                    <v-row class="my-4" v-for="n in 2">
                        <v-col cols="12" md="12">
                            <v-skeleton-loader class="skeleton_title_subcategory" :loading="true" type="heading">
                            </v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="4" v-for="m in 3">
                            <v-skeleton-loader class="skeleton_courses" :loading="true" :elevation="1" type="list-item-avatar">
                            </v-skeleton-loader>
                        </v-col>
                    </v-row>
                </template>
            </v-window-item>
            <v-window-item value="2">
                <template v-if="!loading_courses">
                    <v-row class="my-4" v-for="item in subcategory">
                        <v-col cols="12" md="12">
                            <div class="font-weight-bold text-left" style="color: #6E717F;">{{ item.name }}</div>
                        </v-col>
                        <v-col class="pb-1" cols="12" sm="12" md="6" lg="4" v-for="(info, index) in item.course_middle">
                            <v-divider v-if="index == 1" class="separator-courses d-lg-none d-md-none d-sm-block "></v-divider>
                            <v-divider v-if="index == 2" class="separator-courses d-lg-none d-block "></v-divider>
                            <v-divider v-if="index > 2" class="separator-courses"></v-divider>
                            <v-btn class="content-info" :to="{path: `/course/${info.name_link}`}">
                                <v-avatar class="avatar" size="80" rounded="0">
                                    <v-img alt="" class="" cover :src="info.image" max-width="80" />
                                </v-avatar>
                                <div class="w-100 px-4">
                                    <div class="d-flex justify-space-between pb-1">
                                        <div class="font-weight-bold">{{ info.name }}</div>
                                        <div class="justify-self-end"><v-icon size="small">mdi-arrow-right</v-icon></div>
                                    </div>
                                    <div class="d-flex flex-row align-center flex-wrap">
                                        <div class="fs-14 pr-5 pt-1" style="font-weight: 500;"><v-icon color="#FF80FF"
                                                size="small">mdi-text-box-multiple-outline</v-icon> {{ info.count_lessons }} </div>
                                        <div class="justify-self-end font-weight-medium fs-14 pt-1"><v-icon color="#FF80FF"
                                                size="small">mdi-clock-time-five-outline</v-icon>{{ info.duration }}</div>
                                    </div>
                                </div>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="loading_courses">
                    <v-row class="my-4" v-for="n in 2">
                        <v-col cols="12" md="12">
                            <v-skeleton-loader class="skeleton_title_subcategory" :loading="true" type="heading">
                            </v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="4" v-for="m in 3">
                            <v-skeleton-loader class="skeleton_courses" :loading="true" :elevation="1" type="list-item-avatar">
                            </v-skeleton-loader>
                        </v-col>
                    </v-row>
                </template>
            </v-window-item>
            <v-window-item value="3">
                <template v-if="!loading_courses">
                    <v-row class="my-4" v-for="item in subcategory">
                        <v-col cols="12" md="12">
                            <div class="font-weight-bold text-left" style="color: #6E717F;">{{ item.name }}</div>
                        </v-col>
                        <v-col class="pb-1" cols="12" sm="12" md="6" lg="4" v-for="(info, index) in item.course_advanced">
                            <v-divider v-if="index == 1" class="separator-courses d-lg-none d-md-none d-sm-block "></v-divider>
                            <v-divider v-if="index == 2" class="separator-courses d-lg-none d-block "></v-divider>
                            <v-divider v-if="index > 2" class="separator-courses"></v-divider>
                            <v-btn class="content-info" :to="{path: `/course/${info.name_link}`}">
                                <v-avatar class="avatar" size="80" rounded="0">
                                    <v-img alt="" class="" cover :src="info.image" max-width="80" />
                                </v-avatar>
                                <div class="w-100 px-4">
                                    <div class="d-flex justify-space-between pb-1">
                                        <div class="font-weight-bold">{{ info.name }}</div>
                                        <div class="justify-self-end"><v-icon size="small">mdi-arrow-right</v-icon></div>
                                    </div>
                                    <div class="d-flex flex-row align-center flex-wrap">
                                        <div class="fs-14 pr-5 pt-1" style="font-weight: 500;"><v-icon color="#FF80FF"
                                                size="small">mdi-text-box-multiple-outline</v-icon> {{ info.count_lessons }} Lecciones</div>
                                        <div class="justify-self-end font-weight-medium fs-14 pt-1"><v-icon color="#FF80FF"
                                                size="small">mdi-clock-time-five-outline</v-icon>{{ info.duration }}</div>
                                    </div>
                                </div>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="loading_courses">
                    <v-row class="my-4" v-for="n in 2">
                        <v-col cols="12" md="12">
                            <v-skeleton-loader class="skeleton_title_subcategory" :loading="true" type="heading">
                            </v-skeleton-loader>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="4" v-for="m in 3">
                            <v-skeleton-loader class="skeleton_courses" :loading="true" :elevation="1" type="list-item-avatar">
                            </v-skeleton-loader>
                        </v-col>
                    </v-row>
                </template>
            </v-window-item>
        </v-window>
    </v-container>
</template>

<style>
.skeleton_title_category,
.skeleton_title_subcategory{
    background-color: transparent;
    width: 32%;
}
.skeleton_title_category .v-skeleton-loader__heading, 
.skeleton_title_subcategory .v-skeleton-loader__heading{
    margin: 16px 0px !important;
}
.skeleton_courses {
    border-radius: 4px;
}
.v-tab--selected .v-tab__slider {
    opacity: 0;
}
</style>
<style scoped>
.fs-14 {
    font-size: 14px;
}
.v-slide-group-item--active {
    background: #0D1329;
    border-radius: 34px;
    color: #FFFFFF !important;
}
.v-btn.v-btn--density-default {
    height: auto !important;
}
.separator-courses{
    padding-bottom: 16px;
    width: 96%;
    border-top: 1px solid #E7E7EA;
    opacity: 1;
}
.content-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0px;
    text-transform: initial;
    background-color: transparent;
    box-shadow: none;
    border-radius: 10px;
}
.content-info .avatar{
    border-radius: 10px !important;
}
</style>

<script>
import categoryService from "@/composables/categories";
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';

export default {
    name:"Type categories",
    components: {
        VSkeletonLoader
    },
    data: () => ({
        loading_title_category: true,
        loading_courses: true,
        name_category: null,
        tab: null,
        subcategory: [],
        levels: [
            {name: 'Básico', value: 1},
            {name: 'Intermedio', value: 2},
            {name: 'Avanzado', value: 3},
        ],
    }),
    computed:{
        getIdUrl() {
            return this.$route.params.id;
        },
    },
    mounted() {
    },
    watch: {
        getIdUrl: {
            handler(newVal, oldVal) {
                if (this.$route.params.id) {
                    this.getData();
                }
            },
            deep: true
        }
    },
    methods: {
        getData() {
            this.loading_title_category = true;
            this.loading_courses = true;
            this.subcategory = [];
            categoryService.show(this.getIdUrl).then(response => {
                this.name_category = response.data.data.name;
                let category_course = response.data.data.category_course;
                category_course.forEach(item => {
                    //Validamos que no se repitan las subcategorias
                    let validate = this.subcategory.find(data => data.id == item.sub_category_id);
                    if (!validate) {
                        let info = {
                            id: item.sub_category.id,
                            name: item.sub_category.name,
                            course_basic: [],
                            course_middle: [],
                            course_advanced: [],
                        }
                        this.subcategory.push(info);
                    }
                })
                this.loading_title_category = false;
                this.subcategory.forEach(item => {
                    category_course.forEach(data => {
                        if (item.id == data.sub_category_id) {
                            if (data.course.status == 1) {
                                //Agregamos los cursos por cada nivel
                                if (data.course.level == 1) {
                                    let info = {
                                        id: data.course.id,
                                        name: data.course.name,
                                        name_link:(data.course.name.toLowerCase()).replace(/[^a-z0-9]/g, "-")+'/'+data.course.id,
                                        image: data.course.image,
                                        duration: data.course.duration_format,
                                        count_lessons: data.course.lessons.filter(item => {return item.status==1}).length,
                                    }
                                    item.course_basic.push(info);
                                }
                                if (data.course.level == 2) {
                                    let info = {
                                        id: data.course.id,
                                        name: data.course.name,
                                        name_link:(data.course.name.toLowerCase()).replace(/[^a-z0-9]/g, "-")+'/'+data.course.id,
                                        image: data.course.image,
                                        duration: data.course.duration_format,
                                        count_lessons: data.course.lessons.filter(item => {return item.status==1}).length,
                                    }
                                    item.course_middle.push(info);
                                }
                                if (data.course.level == 3) {
                                    let info = {
                                        id: data.course.id,
                                        name: data.course.name,
                                        name_link:(data.course.name.toLowerCase()).replace(/[^a-z0-9]/g, "-")+'/'+data.course.id,
                                        image: data.course.image,
                                        duration: data.course.duration_format,
                                        count_lessons: data.course.lessons.filter(item => {return item.status==1}).length,
                                    }
                                    item.course_advanced.push(info);
                                }
                            }
                        }
                    })
                })
                setTimeout(() => {
                    this.loading_courses = false;
                }, 1000)
            }).catch(e => {
                console.log(e);
            })
        },
    },
    created() {
        this.getData();
    }
}
</script>