import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
  getAvatarBody() {
    return axios.get(ENDPOINT_PATH + `/avatar/avatar-body`);
  },
  getAvatarBackground() {
    return axios.get(ENDPOINT_PATH + `/avatar/avatar-background`);
  },
  getAvatarEye() {
    return axios.get(ENDPOINT_PATH + `/avatar/avatar-eye`);
  },
  getAvatarHair() {
    return axios.get(ENDPOINT_PATH + `/avatar/avatar-hair`);
  },
  getAvatarClothe() {
    return axios.get(ENDPOINT_PATH + `/avatar/avatar-clothe`);
  },
  update(data) {
    return axios.put(ENDPOINT_PATH + `/avatar/${data.id}`, data);
  },
};

