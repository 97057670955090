import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
  getUsers() {
    return axios.get(ENDPOINT_PATH + `/users`);
  },
  update(data) {
    return axios.put(ENDPOINT_PATH + `/users/${data.id}`, data);
  },
  uploadFileImage(formData) {
    return axios.post(ENDPOINT_PATH + `/store-file-image`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
  },
  getUsersPoints() {
    return axios.get(ENDPOINT_PATH + `/users-points`);
  },
  getMyPoints(id) {
    return axios.get(ENDPOINT_PATH + `/users/my-points/${id}`);
  },
};

