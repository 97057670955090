import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import store from '@/store/index'
import Dashboard from '../views/admin/index.vue'
import HomeView from '../views/HomeView.vue'
import Terms from '../views/Terms.vue'
import Range from '../views/dashboard/range.vue'
import NextRange from '../views/dashboard/nextRange.vue'
import Login from '../views/auth/Login.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import Elearning from '../views/elearning/Elearning.vue'
import Acciona from '../views/Acciona.vue'
import Gestiona from '../views/Gestiona.vue'
import News from '../views/news/index.vue'
import Information from '../views/news/information.vue'
/* Elearning */
import ContentCategories from '../views/elearning/categories.vue'
import ContentCourse from '../views/elearning/course.vue'
/* ---- */

/* Club */
import Product from '../views/club/products.vue'
import Benefit from '../views/club/benefits.vue'
import Affiliate from '../views/club/affiliate.vue'
/* ---- */

import Account from '../views/account.vue'
import Error from '../views/admin/404.vue'

/* Admin */
import AdminCategory from '../views/admin/category/index.vue'
import AdminCategoryCreated from '../views/admin/category/create.vue'
import AdminCategoryEdit from '../views/admin/category/edit.vue'
import AdminSubCategory from '../views/admin/subcategory/index.vue'
import AdminSubCategoryCreated from '../views/admin/subcategory/create.vue'
import AdminSubCategoryEdit from '../views/admin/subcategory/edit'
import AdminCourse from '../views/admin/course/index.vue'
import AdminCourseCreated from '../views/admin/course/create.vue'
import AdminCourseEdit from '../views/admin/course/edit.vue'
import AdminLesson from '../views/admin/lesson/index.vue'
import AdminLessonCreated from '../views/admin/lesson/create.vue'
import AdminLessonEdit from '../views/admin/lesson/edit.vue'
import AdminMaterial from '../views/admin/material/index.vue'
import AdminMaterialCreated from '../views/admin/material/create.vue'
import AdminMaterialEdit from '../views/admin/material/edit.vue'
import AdminNews from '../views/admin/news/index.vue'
import AdminNewsCreated from '../views/admin/news/create.vue'
import AdminNewsEdit from '../views/admin/news/edit.vue'
import AdminProduct from '../views/admin/product/index.vue'
import AdminProductCreated from '../views/admin/product/create.vue'
import AdminProductEdit from '../views/admin/product/edit.vue'
import AdminBenefit from '../views/admin/benefit/index.vue'
import AdminBenefitCreated from '../views/admin/benefit/create.vue'
import AdminBenefitEdit from '../views/admin/benefit/edit.vue'
/* ---- */

const routes = [
  {
    path: '/inicio',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/range',
    name: 'range',
    component: Range,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/nex-range',
    name: 'next range',
    component: NextRange,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/elearning',
    name: 'elearning',
    component: Elearning,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/news/:name/:newsId',
    name: 'information',
    component: Information,
    meta: {
      requiresAuth: true
    },
},
  {
    path: '/acciona',
    name: 'acciona',
    component: Acciona,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/gestiona',
    name: 'gestiona',
    component: Gestiona,
    meta: {
      requiresAuth: true
   }
  },
  {
    path: '/elearning/:name/:id',
    name: 'Contenido categoría',
    component: ContentCategories,
    meta: {
      requiresAuth: true,
   }
  },
  {
    path: '/course/:name/:courseId',
    name: 'Contenido curso',
    component: ContentCourse,
    meta: {
      requiresAuth: true,
   }
  },
  {
    path: '/products',
    name: 'Club Productos',
    component: Product,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/benefits',
    name: 'Club Beneficios',
    component: Benefit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/affiliate',
    name: 'Afiliate',
    component: Affiliate,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/account',
    name: 'Cuenta',
    component: Account,
    meta: {
      requiresAuth: true,
   }
  },
  /* ------------ Admin ------------- */
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/categorias',
    name: 'Categorías',
    component: AdminCategory,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/categoria/create',
    name: 'Crear categoría',
    component: AdminCategoryCreated,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/categoria/edit/:id',
    name: 'Actualizar categoría',
    component: AdminCategoryEdit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/subcategorias',
    name: 'Sub Categorías',
    component: AdminSubCategory,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/subcategoria/create',
    name: 'Crear sub categoría',
    component: AdminSubCategoryCreated,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/subcategoria/edit/:id',
    name: 'Actualizar sub categoría',
    component: AdminSubCategoryEdit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/cursos',
    name: 'Cursos',
    component: AdminCourse,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/curso/create',
    name: 'Crear curso',
    component: AdminCourseCreated,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/curso/edit/:id',
    name: 'Actualizar curso',
    component: AdminCourseEdit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/lecciones',
    name: 'Lecciones',
    component: AdminLesson,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/leccion/create',
    name: 'Crear lección',
    component: AdminLessonCreated,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/leccion/edit/:id',
    name: 'Actualizar lección',
    component: AdminLessonEdit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/materiales',
    name: 'Materiales',
    component: AdminMaterial,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/material/create',
    name: 'Crear material',
    component: AdminMaterialCreated,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/material/edit/:id',
    name: 'Actualizar material',
    component: AdminMaterialEdit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/noticias',
    name: 'Noticias',
    component: AdminNews,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/noticia/create',
    name: 'Crear noticia',
    component: AdminNewsCreated,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/noticia/edit/:id',
    name: 'Actualizar noticia',
    component: AdminNewsEdit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/productos',
    name: 'Productos',
    component: AdminProduct,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/producto/create',
    name: 'Crear producto',
    component: AdminProductCreated,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/producto/edit/:id',
    name: 'Actualizar producto',
    component: AdminProductEdit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/beneficios',
    name: 'Beneficios',
    component: AdminBenefit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/beneficio/create',
    name: 'Crear beneficio',
    component: AdminBenefitCreated,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/beneficio/edit/:id',
    name: 'Actualizar beneficio',
    component: AdminBenefitEdit,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  { 
    path: '/:pathMatch(.*)', 
    name: 'not-found',
    component: Error ,
    meta: {
      requiresAuth: true,
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
     next();
  }
})

export default router
