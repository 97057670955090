<template>
    <div class="info-played-video">
        <div class="title-video">
            <p>{{ name_video }}</p>
        </div>
        <div v-if="video_type == 1">
            <videoDashboardArchive :playedVideo="played_video" :videoInfo="videoInfo"/>
        </div>
        <div v-if="video_type == 2">
            <videoDashboardVimeo :playedVideo="played_video" :videoInfo="videoInfo"/>
        </div>
        <div v-if="link_redirect.id" class="link-redirect">
            <router-link :to="'course/'+(link_redirect.name.toLowerCase()).replace(' ', '-')+'/'+link_redirect.id">
                Ir al curso
            </router-link>
        </div>
    </div>
</template>

<style>
.info-played-video {
    background-color: #ffffff;
    border-radius: 28px;
    box-shadow: 0px 4px 4px rgb(197 197 197 / 16%);
    padding: 20px 0px;
}
.info-played-video .video-js{
    width: calc(59% - 5px);
    height: 200px;
}
.info-played-video .title-video{
    font-size: 20px;
    font-weight: 700;
    padding: 0px 25px 15px 25px;
}
.link-redirect {
    padding-top: 10px;
    font-weight: 700;
}
.link-redirect  a{
    color: #0D1329;
    text-decoration: none;
}
@media (max-width: 1279px) {
    .info-played-video .video-js {
        height: 150px;
    }
}
</style>

<script>
import videoDashboardArchive from "@/components/videoDashboard/archive.vue";
import videoDashboardVimeo from "@/components/videoDashboard/vimeo.vue";
import lessonService from "@/composables/lessons";
import { mapGetters } from "vuex";

export default {
    name: 'ElearningVideo',
    props: ['videoInfo', 'userData'],
    components: {
        videoDashboardArchive,
        videoDashboardVimeo,
    },
    data: () => ({
        player: null,
        options: {},
        played_video: null,
        name_video: null,
        new_courses: null,
        new_lessons: null,
        video_type: null,
        link_redirect: {
            id: null,
            name: null
        },
    }),
    computed: {
        ...mapGetters(["userSession"]),
    },
    mounted() {
        if (this.userSession.role == 'student') {
            this.createVideo();
        }
    },
    methods: {
        createVideo() {
            this.loading_video = true;
            this.options = {
                autoplay: false,
                controls: true,
                loop: false,
            };
            let courses = null;
            let lessons = null;
            let last_played_course = null;
            let last_played_lesson = null;
            let data = this.videoInfo.find(item => item.id == this.userData.id);

            if (data.position_video.length > 0) {
                courses = JSON.parse(data.position_video[0].course_videos ?? null);
                lessons = JSON.parse(data.position_video[0].lesson_videos ?? null);

                //Validamos si hay videos reproducidos de cursos
                if(courses.length > 0) {
                    //Ordenamos la fecha en que se pauso el video, de mayor a menor
                    courses = courses.sort((a, b) => {return b.last_pause - a.last_pause});
                    last_played_course = courses[0].last_pause;
                }

                //Validamos si hay videos reproducidos de lecciones
                if(lessons.length > 0) {
                    //Ordenamos la fecha en que se pauso el video, de mayor a menor
                    lessons = lessons.sort((a, b) => {return b.last_pause - a.last_pause});
                    last_played_lesson = lessons[0].last_pause
                }

                //Verificamos cual es el video con la fecha mayor
                if (last_played_course > last_played_lesson) {
                    this.played_video = {position_video_id: data.position_video[0].id, type: 'course', data: courses[0]};
                } else {
                    this.played_video = {position_video_id: data.position_video[0].id, type: 'lesson', data: lessons[0]};
                }

                this.name_video = this.played_video.data.name;
                this.video_type = this.played_video.data.video_type;
                if (this.played_video.type == 'course') {
                    this.link_redirect.id = this.played_video.data.course_id;
                    this.link_redirect.name = this.played_video.data.name;
                } else {
                    this.getCourse();
                }
            }
            this.loading_video = false;
        },
        getCourse() {
            lessonService.show(this.played_video.data.lesson_id).then(response => {
                this.link_redirect.id = response.data.data.course.id;
                this.link_redirect.name = response.data.data.course.name;
            }, error => {
                console.log(error);
            })
        }
    },
}
</script>