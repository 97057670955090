import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
  getCategories() {
    return axios.get(ENDPOINT_PATH + `/category`);
  },
  getCategoryCourses(id) {
    return axios.get(ENDPOINT_PATH + `/category/category-courses/${id}`);
  },
  show(id) {
    return axios.get(ENDPOINT_PATH + `/category/${id}`);
  },
  store(data) {
    return axios.post(ENDPOINT_PATH + `/category/create`, data);
  },
  update(data) {
    return axios.put(ENDPOINT_PATH + `/category/${data.id}`, data);
  },
  delete(id) {
    return axios.delete(ENDPOINT_PATH + `/category/delete/${id}`);
  },
  uploadFileImage(formData) {
    return axios.post(ENDPOINT_PATH + `/store-file-image`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
  },
};

