import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
  register(email, password) {
    const user = { email, password };
    return axios.post(ENDPOINT_PATH + "/register", user);
  },
  login(dni, password) {
    const user = { dni, password };
    return axios.post(ENDPOINT_PATH + "/auth/login", user);
  },
  resetPassword(email) {
    const user = { email };
    return axios.post(ENDPOINT_PATH + "/auth/reset-password", user);
  },
};

