<template>
    <v-container>
        <v-row class="pt-3">
            <v-col align="start" cols="12" sm="8" md="6" lg="6">
                <p class="title__affiliate">Afíliate con nosotros</p>
                <!--<div class="content-text pt-4">
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
                </div>-->
                <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <div class="input-code pt-4">
                            <v-text-field
                                v-on:keyup="codeText()"
                                v-model="code_user"
                                label="Código de patrocinador"
                                required
                                variant="outlined"
                            ></v-text-field>
                            <div class="content-identify">
                                <!--<p>Jeff Blass Asencios</p>-->
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-btn class="button-affiliate mt-5" @click="assingCode()">Afiliarme</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-if="enable_link" cols="12" md="12" class="text-start">
                <a :href="code_url" target="_blank">Haz click aquí para ir a la página de registro</a>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.title__affiliate {
    font-size: 24px;
    font-weight: 700;
    color: #0D1329;
}
.content-text p{
    text-align: justify;
    font-weight: 400;
    color: #3D4254;
    padding-bottom: 15px;
}
.content-identify p {
    font-size: 16px;
    font-weight: 400;
    color: #0D1329;
}
.button-affiliate {
    width: 100%;
    border-radius: 80px;
    background-color: #00FFFF;
    height: 48px !important;
}
.button-affiliate .v-btn__content {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    text-transform: none;
}
.iframe__affiliate {
    width: 100%;
    height: 700px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'confirmCode',
    data: () => ({
        code_user: null,
        enable_link: false,
        code_url: null,
    }),
    computed:{
        ...mapGetters(["userSession"]),
    },
    methods: {
        assingCode() {
            if (this.userSession.role == 'student') {
                let data = this.code_user ? this.code_user.trim() : '';
                if (data.length > 0 && (/^[0-9]+$/).test(data)) {
                    this.code_url = "https://bo.yosoylegacy.com/RegistroWEBOV.php?idpersona="+this.code_user;
                    this.enable_link = true;
                }
            }
        },
        codeText() {
            this.enable_link = false;
            this.code_url = null;
            /*let letter = this.code_user.trim();
            if(letter.length == 0) {
                this.enable_link = false;
            }*/
        }
    }
}
</script>