<template>
    <v-container>
      <v-row class="mt-0">
        <v-col cols="12" sm="12" md="7" lg="7" class="d-none d-sm-block ">
          <v-row v-if="!userSession.data_dashboard">
            <v-col cols="6" md="12">
              <v-img alt="" class="content-image-range d-none d-md-flex align-center justify-space-between" cover min-width="100%"
                src="@/assets/images/fondo.png" width="80">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col cols="12" md="8">
                    <h1 class="font-main-bold mb-0 title-ejecutivo">
                      {{ current_range?.value }}
                    </h1>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <ImagesRange :data="current_range"/>
                  </v-col>
                </v-row>
              </v-img>
              <v-img alt="" class="content-image-range d-flex d-md-none align-center justify-space-between" cover
                src="@/assets/images/fondo.png" width="100%" height="100%" style="border-radius: 16px;">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col cols="12" md="8" class="pt-sm-3 order-sm-1">
                    <h1 class="font-main-bold mb-0 title-ejecutivo">
                      {{ current_range?.value }}
                    </h1>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <ImagesRange :data="current_range"/>
                  </v-col>
                </v-row>
              </v-img>
            </v-col>
          </v-row>
          <v-row v-if="userSession.data_dashboard">
            <v-col cols="6" md="12">
              <v-img alt="" class="content-image-range d-none d-md-flex align-center justify-space-between" cover min-width="100%"
                src="@/assets/images/fondo.png" width="80">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col cols="12" md="8">
                    <h1 class="font-main-bold mb-0 title-ejecutivo">
                      {{ current_range?.value }}
                    </h1>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <ImagesRange :data="current_range"/>
                  </v-col>
                </v-row>
              </v-img>
              <v-img alt="" class="content-image-range d-flex d-md-none align-center justify-space-between" cover
                src="@/assets/images/fondo.png" width="100%" height="100%" style="border-radius: 16px;">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col cols="12" md="8" class="pt-sm-3 order-sm-1">
                    <h1 class="font-main-bold mb-0 title-ejecutivo">
                      {{ current_range?.value }}
                    </h1>
                  </v-col>
                  <v-col cols="12" md="4" align="center">
                    <ImagesRange :data="current_range"/>
                  </v-col>
                </v-row>
              </v-img>
            </v-col>
            <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_personales !== null">
              <v-card min-height="184" class="content-info-card">
                <v-card-item class="pb-12">
                  <div class="d-flex flex-row space-between align-center">
                    <v-img alt="" class="" container src="@/assets/images/user.png"/>
                    <v-spacer></v-spacer>
                    <!--<div>+10%</div>-->
                  </div>

                </v-card-item>
                <v-card-text justify="end">
                  <div class="text-number-box">{{ userSession.data_dashboard.puntos_personales }}</div>
                  <div class="text-subtext-box">Puntos personales</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_grupales !== null">
              <v-card min-height="184" class="content-info-card">
                <v-card-item class="pb-12">
                  <div class="d-flex flex-row space-between align-center">
                    <v-icon>mdi-account-multiple-outline</v-icon>
                    <v-spacer></v-spacer>
                    <!--<div>+10%</div>-->
                  </div>

                </v-card-item>
                <v-card-text justify="end">
                  <div class="text-number-box">{{ userSession.data_dashboard.puntos_grupales }}</div>
                  <div class="text-subtext-box">Puntos grupales</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_cliente_preferente !== null">
              <v-card min-height="184" class="content-info-card">
                <v-card-item class="pb-12">
                  <div class="d-flex flex-row space-between align-center">
                    <v-img alt="" class="" container src="@/assets/images/diversity.png"/>
                    <v-spacer></v-spacer>
                    <!--<div>+10%</div>-->
                  </div>

                </v-card-item>
                <v-card-text justify="end">
                  <div class="text-number-box">{{ userSession.data_dashboard.puntos_cliente_preferente }}</div>
                  <div class="text-subtext-box">Consumidores
                    preferentes</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_frontales !== null">
              <v-card min-height="184" class="content-info-card">
                <v-card-item class="pb-12">
                  <div class="d-flex flex-row space-between align-center">
                    <v-icon>mdi-account-group-outline</v-icon>
                    <v-spacer></v-spacer>
                    <!--<div>+10%</div>-->
                  </div>

                </v-card-item>
                <v-card-text justify="end">
                  <div class="text-number-box">{{ userSession.data_dashboard.puntos_frontales }}</div>
                  <div class="text-subtext-box">Puntos frontales</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" md="4" v-if="userSession.data_dashboard.bonificaciones !== null">
              <v-card min-height="184" class="content-info-card">
                <v-card-item class="pb-12">
                  <div class="d-flex flex-row space-between align-center">
                    <v-icon>mdi-currency-usd</v-icon>
                    <v-spacer></v-spacer>
                    <!--<div>+10%</div>-->
                  </div>

                </v-card-item>
                <v-card-text justify="end">
                  <div class="text-number-box">S/ {{ userSession.data_dashboard.bonificaciones }}</div>
                  <div class="text-subtext-box">Bonificaciones</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" md="4" v-if="userSession.role !== 'guest'">
              <v-card min-height="184" class="content-info-card">
                <v-card-item class="pb-12">
                  <div class="d-flex flex-row space-between align-center">
                    <v-img alt="" class="" container src="@/assets/images/edu.png"/>
                    <v-spacer></v-spacer>
                    <!--<div>+10%</div>-->
                  </div>
  
                </v-card-item>
                <v-card-text justify="end">
                  <div class="text-number-box">{{ my_points?.total_score }}</div>
                  <div class="text-subtext-box">Puntos E-learning</div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1" lg="1">
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <TopUsers/>
          <div class="mt-8">
            <template v-if="userSession.role == 'student'">
              <div v-if="!loading_video && validate_position_video.position_video.length > 0">
                <Video :videoInfo="users" :userData="userSession"></Video>
              </div>
            </template>
            <v-skeleton-loader v-if="loading_video" :loading="true" type="heading, image">
            </v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <style scoped>
  
  .content-image-range {
    filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.14));
  }
  .content-info-card {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(38, 47, 78, 0.12);
    border-radius: 16px;
  }
  .content-info-card .v-img{
    max-width: 24px;
    max-height: 24px;
  }
  .title-ejecutivo {
    font-size: 40px;
  }
  .text-number-box {
    text-align: left;
    line-height: 3.125rem;
    font-size: 38px;
    font-weight: 700;
    color: #0D1329;
  }
  .text-subtext-box {
    font-size: 10px;
    font-weight: 700;
    color: #868994;
    text-align: left;
    width: 70%;
    line-height: 14px;
  }
  @media (max-width: 768px) {
    .title-ejecutivo {
      font-size: 30px;
    }
  }
  @media (max-width: 1450px) {
    .text-number-box {
      font-size: 30px;
    }
  }
  @media (max-width: 1279px) {
    .text-number-box {
      font-size: 24px;
    }
  }
  </style>
  
  <script>
  import { mapGetters } from "vuex";
  import userService from "@/composables/user";
  import TopUsers from "../../views/topUsers.vue"
  import Video from '../../views/video.vue';
  import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';
  import ImagesRange from "../../views/dashboard/imagesRange.vue";
  
  export default {
    name: "HomeView",
    components: {
      VSkeletonLoader,
      TopUsers,
      Video,
      ImagesRange
    },
    data: () => ({
      tab: null,
      loading_video: true,
      users: [],
      my_points : null,
      current_range: null,
      validate_position_video: null
    }),
    computed: {
      ...mapGetters(["userSession"]),
    },
    mounted () {
      if (this.userSession.role == 'student') {
        this.getUsers();
        this.getMyPoints();
        this.convertData();
      } else {
        this.loading_video = false;
      }
    },
    methods: {
      getUsers() {
        this.loading_video = true;
        this.top_users = [];
        userService.getUsers().then(response => {
            this.users = response.data.data;
            this.validate_position_video = this.users.find(item => item.id == this.userSession.id);
            setTimeout(() => {
                this.loading_video = false;
            }, 1000)
        }).catch(e => {
            console.log(e);
        })
      },
      getMyPoints() {
        userService.getMyPoints(this.userSession.id).then(response => {
            this.my_points = response.data.data;
        }).catch(e => {
            console.log(e);
        })
      },
      convertData() {
        let data = this.userSession.data_dashboard;
        if (data) {
          this.current_range = this.userSession.ranges.find(item => item.id == data.rango_red);
        }
      }
    }
  
  };
  </script>
  