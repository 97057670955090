<template>
    <v-container>
        <v-row class="pt-3">
            <template v-if="!loading_news">
                <v-col v-for="item of news" align="left" cols="12" sm="6" md="6" lg="6">
                    <h1 class="news-title">{{ item.title }}</h1>
                    <div class="news-image" @click="infoProduct(item)">
                        <v-img cover :src="item.main_image"></v-img>
                    </div>
                </v-col>
            </template>
            <template v-if="loading_news">
                <v-col v-for="n of 4" align="left" cols="12" sm="6" md="6" lg="6">
                    <v-skeleton-loader class="skeleton_news" :loading="true" type="list-item-two-line, image">
                    </v-skeleton-loader>
                </v-col>
            </template>

        </v-row>
    </v-container>
</template>

<style>
.news-title{
    font-size: 14px;
    font-weight: 700;
    color: #3D4254;
    padding-bottom: 12px;
}
.news-image {
    cursor: pointer;
    max-width: 520px;
    max-height: 520px;
    overflow: hidden;
}
.skeleton_news {
    background-color: transparent;
}
.skeleton_news .v-skeleton-loader__image {
    height: 240px !important;
}
.skeleton_news .v-skeleton-loader__list-item-two-line .v-skeleton-loader__text {
    margin-left: 0;
}
@media (max-width: 1279px) {
    .skeleton_news {
        width: 100%;
    }
}
</style>

<script>
import newsService from '@/composables/news'
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';

export default {
    name: 'News',
    components: {
        VSkeletonLoader,
    },
    data: () => ({
        loading_news: true,
        news: [],
    }),
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading_news = true;
            newsService.getNews().then(response => {
                this.news = response.data;
                setTimeout(() => {
                    this.loading_news = false;
                }, 1000)
            }, error => {
                console.log(error);
            })
        },
        infoProduct(item) {
            let title = (item.title.toLowerCase()).replace(/[^a-z0-9]/g, "-")+"/"+item.id;
            this.$router.push(`/news/${title}`);
        },
    },
    created() {
    }
}
</script>