<template>
    <v-container id="contentAllCourse">
        <v-row id="contentVideoCourse" class="content__video_course">
            <v-col class="content__video_play" cols="12" md="7" lg="8" id="content__video">
                <div v-if="video_type == 1">
                    <courseVideoArchive 
                        :initCourse="initCourse" 
                        :initLesson="initLesson" 
                        :courseInfo="course" 
                        :selectLesson="select_lesson"
                        :courseVideos="course_videos" 
                        :lessonVideos="lesson_videos" 
                        :positionVideoId="position_video_id"
                        @setData="submit" />
                </div>
                <div v-if="video_type == 2">
                    <courseVideoVimeo 
                        :initCourse="initCourse" 
                        :initLesson="initLesson" 
                        :courseInfo="course" 
                        :selectLesson="select_lesson"
                        :courseVideos="course_videos" 
                        :lessonVideos="lesson_videos" 
                        :positionVideoId="position_video_id"
                        @setData="submit" />
                </div>
                <div v-if="loading_video">
                    <v-skeleton-loader class="skeleton_information_img_video" :loading="loading_video" type="image">
                    </v-skeleton-loader>
                </div>
            </v-col>
            <v-col id="window__tabs_course" cols="12" md="5" lg="4">
                <div class="content__components">
                    <div class="content__tab_windows">
                        <v-tabs v-model="tab" @click="onSelectTab" class="header-tab-course">
                            <v-tab value="one"  class="text-capitalize">Descripción</v-tab>
                            <v-tab value="two" class="text-capitalize">Contenido</v-tab>
                            <v-tab value="three" class="text-capitalize">Materiales</v-tab>
                        </v-tabs>

                        <v-window v-model="tab">
                            <v-window-item value="one">
                                <template v-if="!loading_info_course">
                                    <section class="pt-4">
                                        <div class="title-course font-weight-bold text-left currency">
                                            {{ course.name }}
                                        </div>
                                        <p class="description-course text-left">
                                            {{ course.description }}
                                        </p>
                                        <div class="d-flex flex-row align-center mt-1 mb-6">
                                            <div class="fs-14 mr-4" style="font-weight: 500;">
                                                <v-icon color="#FF80FF"
                                                        size="small">mdi-text-box-multiple-outline
                                                </v-icon>
                                                {{ count_lessons }} lecciones
                                            </div>
                                            <div class="justify-self-end font-weight-medium fs-14">
                                                <v-icon color="#FF80FF"
                                                        size="small">mdi-clock-time-five-outline
                                                </v-icon>
                                                {{ course.duration_format }}
                                            </div>
                                        </div>
                                    </section>
                                </template>
                                <template v-if="loading_info_course">
                                    <section class="pt-4">
                                        <v-skeleton-loader class="skeleton_info_course d-block" :loading="true" type="heading, paragraph">
                                        </v-skeleton-loader>
                                    </section>
                                </template>
                            </v-window-item>

                            <v-window-item value="two">
                                <template v-if="!loading_info_course">
                                    <section class="mt-5">
                                        <div v-for="item in course.lessons" class="content-lesson">
                                            <v-btn v-if="item.status == 1" class="info-lesson" @click="onSelectLesson(item)">
                                                <div>
                                                    <div class="font-weight-bold">{{ item.name }}</div>
                                                    <div class="text-caption text-left pt-1">{{ item.duration_format }}</div>
                                                </div>
                                                <div class="icon-finish" v-if="item.finish">
                                                    <v-img src="@/assets/images/check_video.png"></v-img>
                                                </div>
                                                <div v-else="item.finish">
                                                    <v-icon>mdi-play-outline</v-icon>
                                                </div>
                                            </v-btn>
                                            <v-divider v-if="item.status == 1" class="separator-lesson my-3"></v-divider>
                                        </div>
                                    </section>
                                </template>
                                <template v-if="loading_info_course">
                                    <section class="pt-4">
                                        <div v-for="n in 3" class="content-lesson">
                                            <v-skeleton-loader class="skeleton_info_course d-block" :loading="true" type="paragraph">
                                            </v-skeleton-loader>
                                            <v-divider class="my-3"></v-divider>
                                        </div>
                                    </section>
                                </template>
                            </v-window-item>
                            <v-window-item value="three">
                                <template v-if="!loading_info_course">
                                    <section class="pt-4">
                                        <div v-for="item in course.lessons" class="content-material">
                                            <div v-if="item.status == 1" class="title-lesson pb-2 pl-2">
                                                <p>{{ item.name }}</p>
                                            </div>
                                            <div v-for="(data, index) in item.materials" class="info-material">
                                                <template  v-if="data.status == 1">
                                                    <v-btn href="javascript:void(0);" @click="downloadPDF(data)">
                                                        <div>
                                                            <p>{{ data.name }}.pdf</p>
                                                            <p class="file-size">{{ data.file_size >= 1000000 ? ((data.file_size / 1000000).toFixed(1) + ' mb') : ((data.file_size / 1000).toFixed(1) + ' kb') }}</p>
                                                        </div>
                                                        <v-icon>mdi-download</v-icon>
                                                    </v-btn>
                                                    <v-divider v-if="(index + 1)< item.materials.length" class="separator-material my-2"></v-divider>
                                                </template>
                                            </div>
                                        </div>
                                    </section>
                                </template>
                                <template v-if="loading_info_course">
                                    <section class="pt-4">
                                        <div v-for="n in 3" class="content-material">
                                            <v-skeleton-loader class="skeleton_info_course d-block" :loading="true" type="paragraph">
                                            </v-skeleton-loader>
                                        </div>
                                    </section>
                                </template>
                            </v-window-item>
                        </v-window>
                    </div>
                    <MessagePoint :data_message="data_message" :data_message_material="data_message_material"/>
                </div>
            </v-col>
        </v-row>
    </v-container>

</template>

<style scoped>
.skeleton_video {
    width: calc(100% - 5px);
    height: calc(100vh - 100px);
}
.skeleton_info_course {   
    background-color: transparent;
}
.v-slide-group-item--active {
    border-bottom: 2px solid #00F0F0;
    border-radius: 0;
}
.content__video_play .video-js {
    position: relative;
    width: calc(100% - 5px);
    height: calc(100vh - 100px);
}
.content__components {
    display: grid;
    align-content: space-between;
    height: 100%;
}
#window__tabs_course {
    position: relative;
}
.title-course {
    font-size: 20px;
}
.description-course {
    padding: 10px 0;
}
.content-lesson {
    padding: 0 5px;
}
.info-lesson {
    display: block !important;
    width: 100%;
    padding: 10px;
    height: max-content;
    background-color: transparent;
    box-shadow: none;
    padding-bottom: 0;
    padding-top: 0;
}
.info-lesson .icon-finish {
    width: 20px;
}
.content-material {
    text-align: justify;
    padding: 0px 10px 10px 10px;
}
.title-lesson {
    font-size: 16px;
    font-weight: 700;
    color: #0D1329;
}
.info-material a {
    display: block;
    width: 100%;
    text-transform: none;
    font-size: 12px;
    font-weight: 700;
    color: #0D1329;
    margin: 4px 0;
    background-color: transparent;
    box-shadow: none;
    padding: 0 8px;
}
.content-lesson .separator-lesson {
    width: 94%;
    margin: 0 auto;
}
.info-material .separator-material {
    width: 95%;
    margin: 0 auto;
}
p.file-size {
    color: #6E717F;
    font-size: 10px;
}
</style>

<script>
import courseVideoVimeo from "@/components/course/vimeo.vue";
import courseVideoArchive from "@/components/course/archive.vue";
import { mapGetters } from "vuex";
import courseService from "@/composables/courses"
import positionVideoService from "@/composables/position_videos"
import materialService from "@/composables/materials"
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';
import { toast } from 'vue3-toastify';
import MessagePoint from '../elearning/messagePoints.vue';

export default {
    name: "Detail course",
    components: {
        VSkeletonLoader,
        MessagePoint,
        courseVideoVimeo,
        courseVideoArchive,
    },
    data () {
        return {
            data_message: null,
            data_message_material: null,
            loading_info_course: true,
            tab:null,
            name: null,
            course: {},
            count_lessons: [],
            player: null,
            options: {},
            position_video_id: null,
            course_videos: [],
            lesson_videos: [],
            select_lesson: null,
            initCourse: false,
            initLesson: false,
            firstTime: null,
            video_type: null,
            loading_video: false,
        }
    },
    computed:{
        ...mapGetters(["userSession"]),
        getIdUrl() {
            return this.$route.params.courseId;
        },
    },
    mounted () {
    },
    watch: {
        getIdUrl: {
            handler(newVal, oldVal) {
                if (this.$route.params.courseId) {
                    this.getPositionVideo();
                }
            },
            deep: true
        }
    },
    methods: {
        getData() {
            this.loading_info_course = true;
            this.initCourse = true;
            this.loading_video = true;
            courseService.show(this.getIdUrl).then(response => {
                this.course = response.data.data;
                this.count_lessons = this.course.lessons.filter(item => {return item.status == 1}) .length;
                //Actualizamos la data de las lecciones finalizadas
                this.course.lessons.forEach(item1 => {
                    this.lesson_videos.forEach(item2 => {
                        if (item1.id == item2.lesson_id && item2.finish) {
                            item1.finish = true;
                        }
                    })
                })
                if (this.userSession.role == 'student') {
                    this.video_type = this.course.video_type;
                    this.loading_video = false;
                }
                this.loading_info_course = false;
            }).catch(e => {
                console.log(e);
            })
        },
        getPositionVideo() {
            positionVideoService.getPositionVideo(this.userSession.id).then(response => {
                this.course_videos = response.data.course_videos ? JSON.parse(response.data.course_videos) : [];
                this.lesson_videos = response.data.lesson_videos ? JSON.parse(response.data.lesson_videos) : [];
                this.position_video_id = response.data.id;
                this.getData();
            }).catch(e => {
                console.log(e);
            })
        },
        submit(info) {
            //Actualizamos los datos el video reproducido
            if (this.position_video_id) {
                info.id = this.position_video_id
                positionVideoService.update(info).then(response => {
                    this.course_videos = response.data.data.course_videos ? JSON.parse(response.data.data.course_videos) : [];
                    this.lesson_videos = response.data.data.lesson_videos ? JSON.parse(response.data.data.lesson_videos) : [];
                    this.position_video_id = response.data.data.id;
                    //Variable donde se validan los mensajes de los puntos obtenidos
                    this.data_message = response.data;
                }).catch(e => {
                    console.log(e);
                })
            } else {
                //Guardamos los datos del video reproducido
                positionVideoService.store(info).then(response => {
                    this.course_videos = response.data.data.course_videos ? JSON.parse(response.data.data.course_videos) : [];
                    this.lesson_videos = response.data.data.lesson_videos ? JSON.parse(response.data.data.lesson_videos) : [];
                    this.position_video_id = response.data.data.id;
                    //Variable donde se validan los mensajes de los puntos obtenidos
                    this.data_message = response.data;
                }).catch(e => {
                    console.log(e);
                })
            }
        },
        onSelectLesson(data) {
            this.video_type = null;
            this.initLesson = true;
            this.initCourse = false;
            this.loading_video = true;
            setTimeout(() => {
                if (this.userSession.role == 'student') {
                    this.select_lesson = data;
                    this.video_type = data.video_type;
                    this.loading_video = false;
                }
            }, 1200);
            
        },
        onSelectTab() {
            if (this.tab == 'one' && this.initLesson) {
                this.video_type = null;
                this.initCourse = true;
                this.initLesson = false;
                this.loading_video = true;
                setTimeout(() => {
                    if (this.userSession.role == 'student') {
                        this.video_type = this.course.video_type;
                        this.loading_video = false;
                    }
                }, 1200);
            }
        },
        downloadPDF(data) {
            if (this.userSession.role == 'student') {
                materialService.downloadPDF(data).then(response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = data.name + '.pdf';
                    link.click()
                    URL.revokeObjectURL(link.href)
                    if (response.status == 200) {
                        data.user_id = this.userSession.id;
                        materialService.pointsToDownload(data).then(resp => {
                            //Variable donde se valida el mensaje de los puntos obtenidos
                            this.data_message_material = resp.data;
                        }).catch(err => {
                            console.log(error);
                        })
                    }
                }).catch(error => {
                    toast.error('Ocurrio un error al descargar el pdf, intentelo más tarde', {autoClose: 2500});
                    console.log(error);
                })
            }
        }
    },
    created() {
        this.getPositionVideo()
    }
}

</script>