<template>
    <div class="content__messages_points" :class="{'change_height': (message_first_position || message_second_position || message_third_position)}">
        <div class="messages_points" :class="{'active_message':message_first_lesson}">
            <v-img src="@/assets/images/message_points/message_1.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_lesson}">
            <v-img src="@/assets/images/message_points/message_2.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_course}">
            <v-img src="@/assets/images/message_points/message_3.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_download_material}">
            <v-img src="@/assets/images/message_points/message_4.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_theme}">
            <v-img src="@/assets/images/message_points/message_6.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_basic_pillar}">
            <v-img src="@/assets/images/message_points/message_7.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_middle_pillar}">
            <v-img src="@/assets/images/message_points/message_8.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_advanced_pillar}">
            <v-img src="@/assets/images/message_points/message_9.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_all_level_pillar}">
            <v-img src="@/assets/images/message_points/message_10.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_all_pillar}">
            <v-img src="@/assets/images/message_points/message_11.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_first_position}">
            <v-img src="@/assets/images/message_points/first_position.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_second_position}">
            <v-img src="@/assets/images/message_points/second_position.png"></v-img>
        </div>
        <div class="messages_points" :class="{'active_message':message_third_position}">
            <v-img src="@/assets/images/message_points/third_position.png"></v-img>
        </div>
    </div>
</template>

<style>
.content__messages_points {
    display: grid;
    justify-items: end;
    position: relative;
    height: 86px;
}
.content__messages_points .messages_points {
    width: 65%;
    transform: translateX(400px);
    opacity: 0;
    position: absolute;
}
.content__messages_points .messages_points.active_message {
    opacity: 1;
    transition: 1.5s all;
    transform: translateX(0px);
}
.content__messages_points.change_height{
    height: 330px !important;
}
@media (max-width: 959px) {
    .content__messages_points {
        margin-top: 20px;
        justify-items: center;
    }
    .content__messages_points .messages_points {
        width: 50%;
    }
}
</style>

<script>
export default {
    name: "Message Points",
    props: ['data_message', 'data_message_material'],
    data () {
        return {
            message_first_lesson: false,
            message_lesson: false,
            message_course: false,
            message_theme: false,
            message_basic_pillar: false,
            message_middle_pillar: false,
            message_advanced_pillar: false,
            message_all_level_pillar: false,
            message_all_pillar:false,
            message_download_material: false,
            message_first_position: false,
            message_second_position: false,
            message_third_position: false
        }
    },
    computed: {
        getMessage() {
            return this.data_message;
        },
        getMessageMaterial() {
            return this.data_message_material;
        }
    },
    watch: {
        getMessage: {
            handler(newVal, oldVal) {
                this.messageTimeout();
            },
            deep: true
        },
        getMessageMaterial: {
            handler(newVal, oldVal) {
                this.messageTimeoutMaterial();
            },
            deep: true
        }
    },
    methods: {
        messageTimeoutMaterial() {
            if (this.data_message_material.message_download_material) {
                this.message_download_material = true;
                setTimeout(() => {
                    this.message_download_material = false;
                }, 5000);
            }
        },
        messageTimeout() {
            let complete_first_lesson = this.data_message.message_points.message_lesson.complete_first_lesson;
            let complete_lesson = this.data_message.message_points.message_lesson.complete_lesson;
            let complete_course = this.data_message.message_points.message_course;
            let complete_theme = this.data_message.message_points.message_theme;
            let complete_basic_pillar = this.data_message.message_points.message_basic_pillar;
            let complete_middle_pillar = this.data_message.message_points.message_middle_pillar;
            let complete_advanced_pillar = this.data_message.message_points.message_advanced_pillar;
            let complete_all_level_pillar = this.data_message.message_points.message_all_level_pillar;
            let complete_all_pillar = this.data_message.message_points.message_all_pillar;
            let message_position = this.data_message.message_points.message_position;

            if (complete_first_lesson) {
                this.message_first_lesson = true;
                setTimeout(() => {
                    this.message_first_lesson = false;
                }, 5000);

                if (complete_lesson) {
                    setTimeout(() => {
                        this.message_lesson = true;
                    }, 6000);

                    setTimeout(() => {
                        this.message_lesson = false;
                    }, 11000);

                    if (complete_course) {
                        setTimeout(() => {
                            this.message_course= true;
                        }, 12000);

                        setTimeout(() => {
                            this.message_course = false;
                        }, 17000);

                        if (complete_theme) {
                            setTimeout(() => {
                                this.message_theme= true;
                            }, 18000);

                            setTimeout(() => {
                                this.message_theme = false;
                            }, 23000);

                            if (complete_basic_pillar) {
                                setTimeout(() => {
                                    this.message_basic_pillar= true;
                                }, 24000);

                                setTimeout(() => {
                                    this.message_basic_pillar = false;
                                }, 29000);

                                if (complete_all_level_pillar) {
                                    setTimeout(() => {
                                        this.message_all_level_pillar= true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_all_level_pillar = false;
                                    }, 35000);

                                    if (complete_all_pillar) {
                                        setTimeout(() => {
                                            this.message_all_pillar= true;
                                        }, 36000);

                                        setTimeout(() => {
                                            this.message_all_pillar = false;
                                        }, 41000);

                                        if (message_position == 1) {
                                            setTimeout(() => {
                                                this.message_first_position = true;
                                            }, 42000);

                                            setTimeout(() => {
                                                this.message_first_position = false;
                                            }, 47000);
                                        }

                                        if (message_position == 2) {
                                            setTimeout(() => {
                                                this.message_second_position = true;
                                            }, 42000);

                                            setTimeout(() => {
                                                this.message_second_position = false;
                                            }, 47000);
                                        }

                                        if (message_position == 3) {
                                            setTimeout(() => {
                                                this.message_third_position= true;
                                            }, 42000);

                                            setTimeout(() => {
                                                this.message_third_position = false;
                                            }, 47000);
                                        }
                                    }
                                }
                            }

                            if (complete_middle_pillar) {
                                setTimeout(() => {
                                    this.message_middle_pillar= true;
                                }, 24000);

                                setTimeout(() => {
                                    this.message_middle_pillar = false;
                                }, 29000);

                                if (complete_all_level_pillar) {
                                    setTimeout(() => {
                                        this.message_all_level_pillar= true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_all_level_pillar = false;
                                    }, 35000);

                                    if (complete_all_pillar) {
                                        setTimeout(() => {
                                            this.message_all_pillar= true;
                                        }, 36000);

                                        setTimeout(() => {
                                            this.message_all_pillar = false;
                                        }, 41000);

                                        if (message_position == 1) {
                                            setTimeout(() => {
                                                this.message_first_position = true;
                                            }, 42000);

                                            setTimeout(() => {
                                                this.message_first_position = false;
                                            }, 47000);
                                        }

                                        if (message_position == 2) {
                                            setTimeout(() => {
                                                this.message_second_position = true;
                                            }, 42000);

                                            setTimeout(() => {
                                                this.message_second_position = false;
                                            }, 47000);
                                        }

                                        if (message_position == 3) {
                                            setTimeout(() => {
                                                this.message_third_position= true;
                                            }, 42000);

                                            setTimeout(() => {
                                                this.message_third_position = false;
                                            }, 47000);
                                        }
                                    }
                                }
                            }

                            if (complete_advanced_pillar) {
                                setTimeout(() => {
                                    this.message_advanced_pillar= true;
                                }, 24000);

                                setTimeout(() => {
                                    this.message_advanced_pillar = false;
                                }, 29000);

                                if (complete_all_level_pillar) {
                                    setTimeout(() => {
                                        this.message_all_level_pillar= true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_all_level_pillar = false;
                                    }, 35000);

                                    if (complete_all_pillar) {
                                        setTimeout(() => {
                                            this.message_all_pillar= true;
                                        }, 36000);

                                        setTimeout(() => {
                                            this.message_all_pillar = false;
                                        }, 41000);

                                        if (message_position == 1) {
                                            setTimeout(() => {
                                                this.message_first_position = true;
                                            }, 42000);

                                            setTimeout(() => {
                                                this.message_first_position = false;
                                            }, 47000);
                                        }

                                        if (message_position == 2) {
                                            setTimeout(() => {
                                                this.message_second_position = true;
                                            }, 42000);

                                            setTimeout(() => {
                                                this.message_second_position = false;
                                            }, 47000);
                                        }

                                        if (message_position == 3) {
                                            setTimeout(() => {
                                                this.message_third_position= true;
                                            }, 42000);

                                            setTimeout(() => {
                                                this.message_third_position = false;
                                            }, 47000);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                if (complete_lesson) {
                    this.message_lesson = true;
                    setTimeout(() => {
                        this.message_lesson = false;
                    }, 5000);

                    if (complete_course) {
                        setTimeout(() => {
                            this.message_course= true;
                        }, 6000);

                        setTimeout(() => {
                            this.message_course = false;
                        }, 11000);

                        if (complete_theme) {
                            setTimeout(() => {
                                this.message_theme= true;
                            }, 12000);

                            setTimeout(() => {
                                this.message_theme = false;
                            }, 17000);

                            if (complete_basic_pillar) {
                                setTimeout(() => {
                                    this.message_basic_pillar= true;
                                }, 18000);

                                setTimeout(() => {
                                    this.message_basic_pillar = false;
                                }, 23000);

                                if (complete_all_level_pillar) {
                                    setTimeout(() => {
                                        this.message_all_level_pillar= true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_all_level_pillar = false;
                                    }, 29000);

                                    if (complete_all_pillar) {
                                        setTimeout(() => {
                                            this.message_all_pillar= true;
                                        }, 30000);

                                        setTimeout(() => {
                                            this.message_all_pillar = false;
                                        }, 35000);

                                        if (message_position == 1) {
                                            setTimeout(() => {
                                                this.message_first_position = true;
                                            }, 36000);

                                            setTimeout(() => {
                                                this.message_first_position = false;
                                            }, 41000);
                                        }

                                        if (message_position == 2) {
                                            setTimeout(() => {
                                                this.message_second_position = true;
                                            }, 36000);

                                            setTimeout(() => {
                                                this.message_second_position = false;
                                            }, 41000);
                                        }

                                        if (message_position == 3) {
                                            setTimeout(() => {
                                                this.message_third_position= true;
                                            }, 36000);

                                            setTimeout(() => {
                                                this.message_third_position = false;
                                            }, 41000);
                                        }
                                    }
                                }
                            }

                            if (complete_middle_pillar) {
                                setTimeout(() => {
                                    this.message_middle_pillar= true;
                                }, 18000);

                                setTimeout(() => {
                                    this.message_middle_pillar = false;
                                }, 23000);

                                if (complete_all_level_pillar) {
                                    setTimeout(() => {
                                        this.message_all_level_pillar= true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_all_level_pillar = false;
                                    }, 29000);

                                    if (complete_all_pillar) {
                                        setTimeout(() => {
                                            this.message_all_pillar= true;
                                        }, 30000);

                                        setTimeout(() => {
                                            this.message_all_pillar = false;
                                        }, 35000);

                                        if (message_position == 1) {
                                            setTimeout(() => {
                                                this.message_first_position = true;
                                            }, 36000);

                                            setTimeout(() => {
                                                this.message_first_position = false;
                                            }, 41000);
                                        }

                                        if (message_position == 2) {
                                            setTimeout(() => {
                                                this.message_second_position = true;
                                            }, 36000);

                                            setTimeout(() => {
                                                this.message_second_position = false;
                                            }, 41000);
                                        }

                                        if (message_position == 3) {
                                            setTimeout(() => {
                                                this.message_third_position= true;
                                            }, 36000);

                                            setTimeout(() => {
                                                this.message_third_position = false;
                                            }, 41000);
                                        }
                                    }
                                }
                            }

                            if (complete_advanced_pillar) {
                                setTimeout(() => {
                                    this.message_advanced_pillar= true;
                                }, 18000);

                                setTimeout(() => {
                                    this.message_advanced_pillar = false;
                                }, 23000);

                                if (complete_all_level_pillar) {
                                    setTimeout(() => {
                                        this.message_all_level_pillar= true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_all_level_pillar = false;
                                    }, 29000);

                                    if (complete_all_pillar) {
                                        setTimeout(() => {
                                            this.message_all_pillar= true;
                                        }, 30000);

                                        setTimeout(() => {
                                            this.message_all_pillar = false;
                                        }, 35000);

                                        if (message_position == 1) {
                                            setTimeout(() => {
                                                this.message_first_position = true;
                                            }, 36000);

                                            setTimeout(() => {
                                                this.message_first_position = false;
                                            }, 41000);
                                        }

                                        if (message_position == 2) {
                                            setTimeout(() => {
                                                this.message_second_position = true;
                                            }, 36000);

                                            setTimeout(() => {
                                                this.message_second_position = false;
                                            }, 41000);
                                        }

                                        if (message_position == 3) {
                                            setTimeout(() => {
                                                this.message_third_position= true;
                                            }, 36000);

                                            setTimeout(() => {
                                                this.message_third_position = false;
                                            }, 41000);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            if (!complete_first_lesson && !complete_lesson && complete_course) {
                this.message_course= true;
                setTimeout(() => {
                    this.message_course = false;
                }, 5000);

                if (complete_theme) {
                    setTimeout(() => {
                        this.message_theme= true;
                    }, 6000);

                    setTimeout(() => {
                        this.message_theme = false;
                    }, 11000);

                    if (complete_basic_pillar) {
                        setTimeout(() => {
                            this.message_basic_pillar= true;
                        }, 12000);

                        setTimeout(() => {
                            this.message_basic_pillar = false;
                        }, 17000);

                        if (complete_all_level_pillar) {
                            setTimeout(() => {
                                this.message_all_level_pillar= true;
                            }, 18000);

                            setTimeout(() => {
                                this.message_all_level_pillar = false;
                            }, 23000);

                            if (complete_all_pillar) {
                                setTimeout(() => {
                                    this.message_all_pillar= true;
                                }, 24000);

                                setTimeout(() => {
                                    this.message_all_pillar = false;
                                }, 29000);

                                if (message_position == 1) {
                                    setTimeout(() => {
                                        this.message_first_position = true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_first_position = false;
                                    }, 35000);
                                }

                                if (message_position == 2) {
                                    setTimeout(() => {
                                        this.message_second_position = true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_second_position = false;
                                    }, 35000);
                                }

                                if (message_position == 3) {
                                    setTimeout(() => {
                                        this.message_third_position= true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_third_position = false;
                                    }, 35000);
                                }
                            }
                        }
                    }

                    if (complete_middle_pillar) {
                        setTimeout(() => {
                            this.message_middle_pillar= true;
                        }, 12000);

                        setTimeout(() => {
                            this.message_middle_pillar = false;
                        }, 17000);

                        if (complete_all_level_pillar) {
                            setTimeout(() => {
                                this.message_all_level_pillar= true;
                            }, 18000);

                            setTimeout(() => {
                                this.message_all_level_pillar = false;
                            }, 23000);

                            if (complete_all_pillar) {
                                setTimeout(() => {
                                    this.message_all_pillar= true;
                                }, 24000);

                                setTimeout(() => {
                                    this.message_all_pillar = false;
                                }, 29000);

                                if (message_position == 1) {
                                    setTimeout(() => {
                                        this.message_first_position = true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_first_position = false;
                                    }, 35000);
                                }

                                if (message_position == 2) {
                                    setTimeout(() => {
                                        this.message_second_position = true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_second_position = false;
                                    }, 35000);
                                }

                                if (message_position == 3) {
                                    setTimeout(() => {
                                        this.message_third_position= true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_third_position = false;
                                    }, 35000);
                                }
                            }
                        }
                    }

                    if (complete_advanced_pillar) {
                        setTimeout(() => {
                            this.message_advanced_pillar= true;
                        }, 12000);

                        setTimeout(() => {
                            this.message_advanced_pillar = false;
                        }, 17000);

                        if (complete_all_level_pillar) {
                            setTimeout(() => {
                                this.message_all_level_pillar= true;
                            }, 18000);

                            setTimeout(() => {
                                this.message_all_level_pillar = false;
                            }, 23000);

                            if (complete_all_pillar) {
                                setTimeout(() => {
                                    this.message_all_pillar= true;
                                }, 24000);

                                setTimeout(() => {
                                    this.message_all_pillar = false;
                                }, 29000);

                                if (message_position == 1) {
                                    setTimeout(() => {
                                        this.message_first_position = true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_first_position = false;
                                    }, 35000);
                                }

                                if (message_position == 2) {
                                    setTimeout(() => {
                                        this.message_second_position = true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_second_position = false;
                                    }, 35000);
                                }

                                if (message_position == 3) {
                                    setTimeout(() => {
                                        this.message_third_position= true;
                                    }, 30000);

                                    setTimeout(() => {
                                        this.message_third_position = false;
                                    }, 35000);
                                }
                            }
                        }
                    }
                } else {
                    if (complete_basic_pillar) {
                        setTimeout(() => {
                            this.message_basic_pillar= true;
                        }, 6000);

                        setTimeout(() => {
                            this.message_basic_pillar = false;
                        }, 11000);

                        if (complete_all_level_pillar) {
                            setTimeout(() => {
                                this.message_all_level_pillar= true;
                            }, 12000);

                            setTimeout(() => {
                                this.message_all_level_pillar = false;
                            }, 17000);

                            if (complete_all_pillar) {
                                setTimeout(() => {
                                    this.message_all_pillar= true;
                                }, 18000);

                                setTimeout(() => {
                                    this.message_all_pillar = false;
                                }, 23000);

                                if (message_position == 1) {
                                    setTimeout(() => {
                                        this.message_first_position = true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_first_position = false;
                                    }, 29000);
                                }

                                if (message_position == 2) {
                                    setTimeout(() => {
                                        this.message_second_position = true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_second_position = false;
                                    }, 29000);
                                }

                                if (message_position == 3) {
                                    setTimeout(() => {
                                        this.message_third_position= true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_third_position = false;
                                    }, 29000);
                                }
                            }
                        }
                    }

                    if (complete_middle_pillar) {
                        setTimeout(() => {
                            this.message_middle_pillar= true;
                        }, 6000);

                        setTimeout(() => {
                            this.message_middle_pillar = false;
                        }, 11000);

                        if (complete_all_level_pillar) {
                            setTimeout(() => {
                                this.message_all_level_pillar= true;
                            }, 12000);

                            setTimeout(() => {
                                this.message_all_level_pillar = false;
                            }, 17000);

                            if (complete_all_pillar) {
                                setTimeout(() => {
                                    this.message_all_pillar= true;
                                }, 18000);

                                setTimeout(() => {
                                    this.message_all_pillar = false;
                                }, 23000);

                                if (message_position == 1) {
                                    setTimeout(() => {
                                        this.message_first_position = true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_first_position = false;
                                    }, 29000);
                                }

                                if (message_position == 2) {
                                    setTimeout(() => {
                                        this.message_second_position = true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_second_position = false;
                                    }, 29000);
                                }

                                if (message_position == 3) {
                                    setTimeout(() => {
                                        this.message_third_position= true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_third_position = false;
                                    }, 29000);
                                }
                            }
                        }
                    }

                    if (complete_advanced_pillar) {
                        setTimeout(() => {
                            this.message_advanced_pillar= true;
                        }, 6000);

                        setTimeout(() => {
                            this.message_advanced_pillar = false;
                        }, 11000);

                        if (complete_all_level_pillar) {
                            setTimeout(() => {
                                this.message_all_level_pillar= true;
                            }, 12000);

                            setTimeout(() => {
                                this.message_all_level_pillar = false;
                            }, 17000);

                            if (complete_all_pillar) {
                                setTimeout(() => {
                                    this.message_all_pillar= true;
                                }, 18000);

                                setTimeout(() => {
                                    this.message_all_pillar = false;
                                }, 23000);

                                if (message_position == 1) {
                                    setTimeout(() => {
                                        this.message_first_position = true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_first_position = false;
                                    }, 29000);
                                }

                                if (message_position == 2) {
                                    setTimeout(() => {
                                        this.message_second_position = true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_second_position = false;
                                    }, 29000);
                                }

                                if (message_position == 3) {
                                    setTimeout(() => {
                                        this.message_third_position= true;
                                    }, 24000);

                                    setTimeout(() => {
                                        this.message_third_position = false;
                                    }, 29000);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</script>