<template>
    <v-container>
        <v-row class="pt-3">
            <v-col align="left" cols="12" md="12">
                <p class="title__discount pb-3">Para acceder al beneficio deberás mostrar tu cupón y tu dashboard activo en el comercio</p>
                <p class="title__discount">Descuentos</p>
            </v-col>
            <template v-if="!loading_benefit">
                <v-col v-for="item in benefits_discounts" cols="12" sm="6" md="4" lg="4">
                    <div class="content-text">
                        <p>{{ item.description }}</p>
                    </div>
                    <div class="benefit-image">
                        <v-img cover :src="item.image"></v-img>
                    </div>
                </v-col>
            </template>
            <template v-if="loading_benefit">
                <v-col v-for="n in 3" cols="12" sm="6" md="4" lg="4">
                    <v-skeleton-loader class="skeleton_benefit" :loading="loading_benefit" type="paragraph, image">
                    </v-skeleton-loader>
                </v-col>
            </template>
            <v-col align="left" cols="12" md="12">
                <p class="title__discount">Promociones</p>
            </v-col>
            <template v-if="!loading_benefit">
                <v-col v-for="item in benefits_promotions" cols="12" sm="6" md="4" lg="4">
                    <div class="content-text">
                        <p>{{ item.description }}</p>
                    </div>
                    <div class="benefit-image">
                        <v-img cover :src="item.image"></v-img>
                    </div>
                </v-col>
            </template>
            <template v-if="loading_benefit">
                <v-col v-for="n in 3" cols="12" sm="6" md="4" lg="4">
                    <v-skeleton-loader class="skeleton_benefit" :loading="loading_benefit" type="paragraph, image">
                    </v-skeleton-loader>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<style>
.title__discount{
    font-size: 20px;
    font-weight: 700;
    color: #0D1329;
}
.content-text p{
    text-align: justify;
    font-weight: 400;
    color: #3D4254;
    padding-bottom: 15px;
}
.skeleton_benefit {
    background-color: transparent;
}
.skeleton_benefit .v-skeleton-loader__paragraph .v-skeleton-loader__text {
    margin-left: 0;
}
</style>

<script>
import benefitService from '@/composables/benefits'
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';

export default {
    name: 'Club Benefits',
    components: {
        VSkeletonLoader
    },
    data: () => ({
        loading_benefit: true,
        benefits_discounts: [],
        benefits_promotions: [],
    }),
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading_product = true;
            benefitService.getBenefits().then(response => {
                response.data.forEach(item => {
                    if (item.type == 1) {
                        this.benefits_discounts.push(item);
                    } else {
                        this.benefits_promotions.push(item);
                    }
                })
                setTimeout(() => {
                    this.loading_benefit = false;
                }, 1000);
            }, error => {
                console.log(error);
            })
        },
    }
}
</script>