<template>
    <div class="ma-lg-6 ma-0">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"/>
        <v-card>
            <Breadcrumbs :items="breadcrumbs"/>
            <v-form v-model="valid" v-on:submit="submit" class="px-3 py-2">
                <v-row>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Lección</h4>
                        <v-select
                            v-model="select_lesson"
                            :items="lessons"
                            :rules="rules.select_lesson"
                            item-title="name"
                            item-value="id"
                            label="Lección"
                            required
                            outlined
                            solo
                        ></v-select>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Nombre</h4>
                        <v-text-field
                            v-model="material.name"
                            :rules="rules.name"
                            label="Nombre"
                            required
                            outlined
                            solo
                        ></v-text-field>
                    </v-col>
                    <!--<v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Descripción</h4>
                        <v-textarea
                            rows="2"
                            v-model="material.description"
                            :rules="rules.description"
                            label="Descripción"
                            required
                            outlined
                            solo
                        ></v-textarea>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Imagen</h4>
                        <v-file-input 
                            variant="underlined"
                            label="Imagen"
                            accept="image/png, image/jpg, image/jpeg"
                            @change="uploadArchive1($event)">
                        </v-file-input>
                    </v-col>-->
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Archivo</h4>
                        <v-file-input 
                            variant="underlined"
                            label="Archivo"
                            accept=".pdf"
                            @change="uploadArchive2($event)">
                        </v-file-input>
                    </v-col>
                </v-row>

                <v-row class="pb-2">
                    <v-col cols="12" md="12" class="d-flex">
                        <v-btn :disabled="!valid" class="mx-auto btn-blue" @click="submit">
                            Agregar material
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import materialService from "@/composables/materials";
import lessonService from "@/composables/lessons";
import { toast } from 'vue3-toastify';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name: 'MaterialCreated',
    components: {
        Breadcrumbs,
        Loading
    },
    data: () => ({
        isLoading: false,
        breadcrumbs: [
            {text: 'Materiales', disabled: false, to: '/materiales',},
        ],
        material: {
            name: null,
            description: null,
            lesson_id: null,
            pdf: null,
            image: null,
            file_size: null,
        },
        valid: false,
        select_lesson: {name: "Seleccione una lección", value: null},
        lessons: [],
        rules: {
            name: [
                v => !!v || 'Nombre es requerido',
            ],
            select_lesson: [
                v => !!v || 'Lección es requerido',
            ]
        },
    }),
    mounted() {
        this.getLessons();
    },
    methods: {
        getLessons() {
            lessonService.getLessons().then(response => {
                this.lessons = response.data;
            });
        },
        submit() {
            this.isLoading = true;
            this.material.lesson_id = this.select_lesson ?? null;

            materialService.store(this.material).then(response => {
                this.isLoading = false;
                this.$router.push({path: '/materiales'})
            }, error => {
                toast.error(JSON.stringify(error.response.data.errors), {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            });
        },
        uploadArchive1(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('file', file);
                formData.append('path', 'materiales-image');
                
                materialService.uploadFileImage(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.material.image = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
        uploadArchive2(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                this.material.file_size = file.size;
                let formData = new FormData();
                formData.append('file', file);
                formData.append('path', 'materiales-pdf');
                
                materialService.uploadFile(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.material.pdf = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        }
    }
}
</script>