<template>
    <vue-vimeo-player v-if="video_in_progress" class="last-course-vimeo" ref="playedVimeoDashboard" :video-url="video_in_progress" @pause="pauseVideo($event)" @ready="onReady($event)"/>
</template>

<style>
.last-course-vimeo {
    width: calc(59% - 5px) !important;
    height: 200px !important;
    background-color: black;
    margin: auto;
}
.last-course-vimeo iframe {
    width: 100%;
    height: 100%;
}
</style>

<script>
import { mapGetters } from "vuex";
import { vueVimeoPlayer } from 'vue-vimeo-player'
import positionVideoService from "@/composables/position_videos"

export default {
    name: "LastPlayedVimeo",
    props: ['playedVideo', 'videoInfo'],
    data: function() {
        return {
            player: null,
            video_in_progress: null,
            new_courses: null,
            new_lessons: null,
        }  
    },
    components: {
        vueVimeoPlayer
    },
    computed:{
        ...mapGetters(["userSession"]),
    },
    mounted() {
        this.onReady();
    },
    methods: {
        onReady(e) {
            this.player = this.$refs.playedVimeoDashboard;
            this.video_in_progress = 'https://player.vimeo.com/video/'+this.playedVideo.data.video_vimeo;
            if (e) {
                if ((this.playedVideo.data.video_duration - 2) < this.playedVideo.data.video_paused) {
                    e.setCurrentTime(0);
                } else{
                    e.setCurrentTime(this.playedVideo.data.video_paused ?? 0);
                }
            }
        },
        pauseVideo(e) {
            let currentTime = Math.round(e.seconds);
            let duration = Math.round(e.duration);

            if (this.playedVideo.type === 'course') {
                let isExist = this.videoInfo.find(item => item.id == this.userSession.id);
                if (isExist) {
                    this.new_courses = JSON.parse(isExist.position_video[0].course_videos ?? null);
                    this.new_lessons = JSON.parse(isExist.position_video[0].lesson_videos ?? null);
                    this.new_courses.forEach(item => {
                        if (item.course_id == this.playedVideo.data.course_id) {
                            item.video_paused = currentTime;
                            item.last_pause = (new Date()).getTime();
                            //Validamos si se finalizo la reproducción del video
                            if ((currentTime <= duration && currentTime >= duration - 2) && !isExist.finish) {
                                item.finish = true;
                                item.finish_play = (new Date()).getTime();
                            }
                            this.update();
                        }
                    })
                } 
            }

            if (this.playedVideo.type === 'lesson') {
                let isExist = this.videoInfo.find(item => item.id == this.userSession.id);
                if (isExist) {
                    this.new_courses = JSON.parse(isExist.position_video[0].course_videos ?? null);
                    this.new_lessons = JSON.parse(isExist.position_video[0].lesson_videos ?? null);
                    this.new_lessons.forEach(item => {
                        if (item.lesson_id == this.playedVideo.data.lesson_id) {
                            item.video_paused = currentTime;
                            item.last_pause = (new Date()).getTime();
                            //Validamos si se finalizo la reproducción del video
                            if ((currentTime <= duration && currentTime >= duration - 2) && !isExist.finish) {
                                item.finish = true;
                                item.finish_play = (new Date()).getTime();
                            }
                            this.update();
                        }
                    })
                }
            }
        },
        update() {
            let data = Object.assign({});
            data.id = this.playedVideo.position_video_id;
            data.user_id = this.userSession.id;
            data.course_videos = this.new_courses ? JSON.stringify(this.new_courses) : null;
            data.lesson_videos = this.new_lessons ? JSON.stringify(this.new_lessons) : null;

            positionVideoService.update(data).then(response => {
                //console.log(response.data);
            }).catch(e => {
                console.log(e);
            })
        }
    }
}

</script>