<template>
    <!--     extended
      extension-height="50" -->
      <v-card flat height="64" title class="d-lg-none d-block">
      <v-toolbar app class="bg-white" dense>
          <v-img alt="" class="pl-5 align-center justify-space-between" container src="@/assets/images/Brand2.png"
            max-width="93" />
        <v-spacer></v-spacer>

        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-toolbar>
    </v-card>
    <div id="hamburgerContent" class="button__hamburger_content" @click="disableElements()">
      <v-img src="@/assets/images/hamburger.png"></v-img>
    </div>
    <div id="navegationDraw">
      <v-navigation-drawer app color="#FFFFFF" v-model="drawer" prominent :clipped="clipped" style="
      border: 0;">
        <v-list text-left nav class="content-routes ma-lg-6 ma-0"> 
          <div>
            <v-list-item-title avatar>
              <div class="d-flex flex-row pl-8 pr-4">
                <v-img alt="Vuetify Name" class="shrink mt-1" contain min-width="80" src="" width="80" />
                <v-app-bar-nav-icon @click.stop="drawer = !drawer" text elevation="0"
                  class="pa-0 d-lg-none d-block"></v-app-bar-nav-icon>
              </div>
              <!-- <v-divider></v-divider> -->
            </v-list-item-title>

            <div id="hamburgerNav" class="button__hamburger_nav" @click="enableElements()">
              <v-img src="@/assets/images/hamburger.png"></v-img>
            </div>

            <div class="px-4 pb-6 pt-8">
              <div class="img__user">
                <v-img v-if="userAvatar.image_photo" alt="" class="align-center justify-space-between" cover max-width="54" radius="15" :src="userAvatar.image_photo"
                width="80" />
                <v-img v-if="!userAvatar.image_photo" alt="" class="align-center justify-space-between" cover max-width="54" src="@/assets/images/photo.png"
                width="80" />
              </div>
              <p class="text-left mt-4">Hola,</p>
              <div class="font-weight-bold text-left">{{ userAvatar.name }}</div>
            </div>

            <template v-for="(item, i) in validRoutes">
              <v-list-item v-if="!item.children" :value="i" :key="i" :to="item.link" link :prepend-icon="item.icon" @click="clickRoute(item)">
                <v-list-item-title v-text="item.title" class="body-1 mt-2 mb-2"></v-list-item-title>
              </v-list-item>

              <v-list-group v-if="item.children" :key="i" v-model="item.active" :prepend-icon="item.action"  sub-group>
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" :value="i" :key="i" @click="toRedirect(item)" :prepend-icon="item.icon" :active="compareTitle == item.title && validInit ? true : (compareTitle == item.title ? true : false)">
                    <v-list-item-title v-text="item.title" class="body-1 mt-2 mb-2" :value="item.title"></v-list-item-title>
                  </v-list-item>
                </template>

                <v-list-item v-for="(itemChildren, j) in item.children" prepend-icon="mdi-circle" 
                                    :to="itemChildren.link"
                                    :value="itemChildren.value + j+100" 
                                    :key="itemChildren.value + j"
                                    :id="itemChildren.diff">
                    <v-list-item-title v-text="itemChildren.title" class="body-1 mt-2 mb-2"></v-list-item-title>
                </v-list-item>
              </v-list-group>

            </template>
              
            <v-list-item v-if="userAvatar.role === 'admin'" variant="plain" key="Logout" prepend-icon="mdi-close-circle-outline"  @click="logout()">
                <v-list-item-title class="body-1 mt-2 mb-2" v-text="'Logout'" value="Logout"></v-list-item-title>
            </v-list-item>
          </div>
          <div class="legacy__logo">
            <v-img src="@/assets/images/legacy_logo.png"></v-img>
          </div>
        </v-list>
      </v-navigation-drawer>
    </div>
</template>

<style>
.content-routes::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: rgba(39, 43, 65, 0.05);
}
.content-routes::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.content-routes::-webkit-scrollbar-thumb {
  background-color: #e3e6ef;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
.img__user img{
  border-radius: 10px;
}
.button__hamburger_content {
    position: absolute;
    display: none;
    width: 30px;
    height: 30px;
    margin-top: 40px;
    margin-left: 16px;
    cursor: pointer;
    background-color: #868994;
    z-index: 10;
    padding: 6px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
.button__hamburger_nav {
  display: block;
  padding-top: 40px;
  width: 12%;
  margin-left: 16px;
  cursor: pointer;
}
.legacy__logo {
  display: flex;
  align-items: center;
  width: 80px;
  margin: 40px 0px 60px 10px;
  align-self: flex-end;
}
.disable__nav {
  display: none;
}
@media (max-width: 1279px) {
  .button__hamburger_content {
    margin-top: 100px;
  }
  .button__hamburger_nav {
    display: none;
  }
}
</style>

<script>
import categoryService from '@/composables/categories';
import { mapGetters } from "vuex";

export default {
  name: 'Sidebar',
  computed: {
    ...mapGetters(["userAvatar"]),
  },
  data() {
    return {
      validInit: null,
      compareTitle: '',
      validRoutes: [],
      categories: [],
      drawer: true,
      clipped: false,
      selectedItem: 1,
      items: [],
      right: null,
    }
  },
  mounted() {
    this.validInit = this.$store.getters.isLoggedIn;
    if (this.$route.path == '/login') {
      this.compareTitle = 'Dashboard';
    }
  },
  methods: {
    disableElements() {
      let navigationDraw = document.getElementById("navegationDraw");
      navigationDraw?.classList.remove("disable__nav");

      let mainContent = document.getElementById("mainContent");
      mainContent?.classList.remove("enable__video");

      let contentAllCourse = document.getElementById("contentAllCourse");
      contentAllCourse?.classList.remove("disable__all_video")

      let contentVideoCourse = document.getElementById("contentVideoCourse");
      contentVideoCourse?.classList.remove("disable__margin");

      let content__video = document.getElementById("content__video");
      content__video?.classList.remove("disable__padding_video");

      let window__tabs_course = document.getElementById("window__tabs_course");
      window__tabs_course?.classList.remove("enable__padding_tab");

      let hamburgerContent = document.getElementById("hamburgerContent");
      hamburgerContent?.classList.remove("enable__button");
    },
    enableElements() {
      let navigationDraw = document.getElementById("navegationDraw");
      navigationDraw?.classList.add("disable__nav");

      let mainContent = document.getElementById("mainContent");
      mainContent?.classList.add("enable__video");

      let contentAllCourse = document.getElementById("contentAllCourse");
      contentAllCourse?.classList.add("disable__all_video")

      let contentVideoCourse = document.getElementById("contentVideoCourse");
      contentVideoCourse?.classList.add("disable__margin");

      let content__video = document.getElementById("content__video");
      content__video?.classList.add("disable__padding_video");

      let window__tabs_course = document.getElementById("window__tabs_course");
      window__tabs_course?.classList.add("enable__padding_tab");

      let hamburgerContent = document.getElementById("hamburgerContent");
      hamburgerContent?.classList.add("enable__button");
    },
    logout() {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    },
    getDataFromApi() {
      categoryService.getCategories().then(response => {
        this.categories = response.data.data;
        let count = 10;
        response.data.data.forEach(e => {
          let info = {
            value: count,
            title: e.name,
            link: '/elearning/'+(e.name.toLowerCase()).replace(/[^a-z0-9]/g, "-")+'/'+e.id,
            roles: ['student', 'guest'],
          }
          this.items[1].children.push(info);
          count++;
        })
      }).catch(e => {
          console.log(e);
      })
    },
    toRedirect(item) {
      this.compareTitle = item.title;
      this.validInit = false;
      if (item.title !== 'Club') {
        this.$router.push(`${item.link}`)
      }
    },
    clickRoute(item) {
      this.compareTitle = item.title;
      this.validInit = false;
    }
  },
  created() {
    this.items = [
      {
        title: 'Dashboard', link: '/inicio',
        icon: 'mdi-view-dashboard-outline',
        roles: ['student', 'guest'],
        children: [
          {
            value: 1000,
            diff: 'info',
            title: 'Rango', link: '/range' ,
            roles: ['student', 'guest'],
          },
          {
            value: 1001,
            diff: 'info',
            title: 'Próximo rango', link: '/nex-range' ,
            roles: ['student', 'guest'],
          }
        ]
      },
      {
        title: 'Elearning', link: '/elearning',
        icon: 'mdi-school-outline',
        roles: ['student', 'guest'],
        children: []
      },
      { title: 'Noticias', link: '/news',
        icon: 'mdi-file-document-outline',
        roles: ['student', 'guest'],
      },
      { title: 'Club',
        icon: 'mdi-tag-outline',
        roles: ['student', 'guest'],
        children: [
          {
            value: 100,
            title: 'Productos', link: '/products' ,
            roles: ['student', 'guest'],
          },
          {
            value: 101,
            title: 'Beneficios', link: '/benefits' ,
            roles: ['student', 'guest'],
          },
          {
            value: 102,
            title: 'Afiliate', link: '/affiliate' ,
            roles: ['student', 'guest'],
          }
        ],
      },
      { title: 'Cuenta', link: '/account' ,
        icon: 'mdi-account-circle-outline',
        roles: ['student', 'guest'],
      },
      { title: 'Categorías', link: '/categorias' ,
        icon: 'mdi-file-document-outline',
        roles: ['admin'],
      },
      { title: 'Sub categorías', link: '/subcategorias' ,
        icon: 'mdi-file-document-outline',
        roles: ['admin'],
      },
      { title: 'Cursos', link: '/cursos' ,
        icon: 'mdi-file-document-outline',
        roles: ['admin'],
      },
      { title: 'Lecciones', link: '/lecciones' ,
        icon: 'mdi-file-document-outline',
        roles: ['admin'],
      },
      { title: 'Materiales', link: '/materiales' ,
        icon: 'mdi-file-document-outline',
        roles: ['admin'],
      },
      { title: 'Noticias', link: '/noticias' ,
        icon: 'mdi-file-document-outline',
        roles: ['admin'],
      },
      { title: 'Productos', link: '/productos' ,
        icon: 'mdi-file-document-outline',
        roles: ['admin'],
      },
      { title: 'Beneficios', link: '/beneficios' ,
        icon: 'mdi-file-document-outline',
        roles: ['admin'],
      },
    ]
    if (this.userAvatar.role === "student" || this.userAvatar.role === "guest") {
      this.getDataFromApi();
    }
    this.validRoutes = this.items.filter((item) => item.roles.includes(this.userAvatar.role));
  },

}

</script>