import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getMaterials () {
        return axios.get(ENDPOINT_PATH + `/materials`);
    },
    show(id) {
        return axios.get(ENDPOINT_PATH + `/materials/${id}`);
    },
    store(data) {
        return axios.post(ENDPOINT_PATH + `/materials`, data);
    },
    update(data) {
        return axios.put(ENDPOINT_PATH + `/materials/${data.id}`, data);
    },
    delete(id) {
        return axios.delete(ENDPOINT_PATH + `/materials/${id}`);
    },
    uploadFile(formData) {
        return axios.post(ENDPOINT_PATH + `/store-file`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    uploadFileImage(formData) {
        return axios.post(ENDPOINT_PATH + `/store-file-image`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    downloadPDF(data) {
        return axios.post(ENDPOINT_PATH + `/materials/download-pdf`, data, {responseType: 'blob'});
    },
    pointsToDownload(data) {
        return axios.post(ENDPOINT_PATH + `/materials/points-to-download`, data);
    }
};

