<template>
    <div class="ma-lg-6 ma-0">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"/>
        <v-card>
            <Breadcrumbs :items="breadcrumbs"/>
            <v-form v-model="valid" class="px-3 py-2">
                <v-row>
                    <v-col align="start" cols="12" md="6">
                        <h4 class="mb-3">Nombre</h4>
                        <v-text-field
                                v-model="product.name"
                                :rules="rules.name"
                                label="Nombre"
                                required
                                outlined
                                solo
                        ></v-text-field>
                    </v-col>
                    <v-col align="start" cols="12" md="6">
                        <h4 class="mb-3">Imagen principal</h4>
                        <v-file-input 
                            :rules="rules.image"
                            variant="underlined"
                            :label="'Imagen (recomendado width:'+recomend_width_img+'px, height:'+recomend_height_img+'px)'"
                            accept="image/png, image/jpg, image/jpeg"
                            @change="uploadArchive1($event)">
                        </v-file-input>
                        <v-col v-if="product.image" class="position-relative" cols="2" md="2">
                            <v-img :src="product.image"/>
                        </v-col>
                    </v-col>
                    <v-col align="start" cols="12" md="12">
                        <h4 class="mb-3">Resumen</h4>
                        <v-row v-for="(item, i) in product.resume">
                            <v-col align="start" cols="12" md="6">
                                <v-textarea v-model="item.text" rows="2" label="Descripción" v-on:keyup="resumeText()"></v-textarea>
                            </v-col>
                            <v-col align="start" cols="12" md="4">
                                <v-file-input 
                                    variant="underlined"
                                    :label="'Imagen (recomendado width:'+recomend_width_img_resume+'px, height:'+recomend_height_img_resume+'px)'"
                                    accept="image/png, image/jpg, image/jpeg"
                                    @change="uploadArchive2($event, i)">
                                </v-file-input>
                                <v-col v-if="item.image" class="position-relative" cols="2" md="2">
                                    <v-img :src="item.image"/>
                                </v-col>
                            </v-col>
                            <v-col align="start" cols="12" md="2" class="d-flex justify-space-between">
                                <v-btn v-if="i == product.resume.length-1" :disabled="validate_resume" @click="addResume()">
                                    <v-icon>mdi-plus-circle</v-icon>
                                    Add
                                </v-btn>
                                <v-btn v-if="i || i != product.resume.length > 1" @click="removeResume(i)">
                                    <v-icon>mdi-minus-circle</v-icon>
                                    Remove
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col align="start" cols="12" md="12">
                        <h4 class="mb-3">Preparación</h4>
                        <v-row>
                            <v-col align="start" cols="12" md="6">
                                <v-file-input 
                                    variant="underlined"
                                    :label="'Imagen (recomendado width:'+recomend_width_img_preparation+'px, height:'+recomend_height_img_preparation+'px)'"
                                    accept="image/png, image/jpg, image/jpeg"
                                    @change="uploadArchive3($event)">
                                </v-file-input>
                                <v-col v-if="product.preparation_image" class="position-relative" cols="2" md="2">
                                    <v-img :src="product.preparation_image"/>
                                </v-col>
                            </v-col>
                            <v-col align="start" cols="12" md="6"></v-col>
                            <v-col align="start" cols="12" md="6">
                                <h4 class="mb-3">Recuerda</h4>
                                <v-row v-for="(item, i) in product.remember">
                                    <v-col align="start" cols="12" md="8">
                                        <v-textarea v-model="item.text" rows="2" label="Recuerda" v-on:keyup="rememberText()"></v-textarea>
                                    </v-col>
                                    <v-col align="start" cols="12" md="4" class="d-flex justify-space-between">
                                        <v-btn v-if="i == product.remember.length-1" :disabled="validate_remember" @click="addRemember()">
                                            <v-icon>mdi-plus-circle</v-icon>
                                            Add
                                        </v-btn>
                                        <v-btn v-if="i || i != product.remember.length > 1" @click="removeRemember(i)">
                                            <v-icon>mdi-minus-circle</v-icon>
                                            Remove
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col align="start" cols="12" md="6">
                        <h4 class="mb-3">Ficha</h4>
                        <v-file-input 
                            variant="underlined"
                            label="Archivo"
                            accept=".pdf"
                            @change="uploadArchive4($event)">
                        </v-file-input>
                    </v-col>
                    <v-col align="start" cols="12" md="6"></v-col>
                    <v-col align="start" cols="12" md="6">
                        <h4 class="mb-3">FAQ</h4>
                        <v-file-input 
                            variant="underlined"
                            label="Archivo"
                            accept=".pdf"
                            @change="uploadArchive5($event)">
                        </v-file-input>
                    </v-col>
                </v-row>

                <v-row class="pb-2">
                    <v-col cols="12" md="12" class="d-flex">
                        <v-btn :disabled="!valid" class="mx-auto btn-blue" @click="update">
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import productService from '@/composables/products';
import { toast } from 'vue3-toastify';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name: 'ProductCreated',
    components: {
        Breadcrumbs,
        Loading
    },
    data: () => ({
        isLoading: false,
        validate_resume: true,
        validate_remember: true,
        breadcrumbs: [
            {text: 'Productos', disabled: false, to: '/productos',},
            {text: 'Crear producto', disabled: false, to: '/producto/create',}
        ],
        product: {
            name: null,
            image: null,
            resume: [
                {
                    text: '',
                    image: null,
                }
            ],
            preparation_image: null,
            remember: [
                {
                    text: '',
                }
            ],
            file: null,
            file_faq: null
        },
        recomend_width_img: 370,
        recomend_height_img: 310,
        recomend_width_img_resume: 360,
        recomend_height_img_resume: 700,
        recomend_width_img_preparation: 270,
        recomend_height_img_preparation: 680,
        valid: false,
        rules: {
            name: [
                v => !!v || 'Nombre es requerido',
            ],
        },
    }),
    mounted() {
        this.show(this.$route.params.id);
    },
    methods: {
        show(product_id) {
            productService.show(product_id).then(response => {
                this.product = response.data.data;
                this.product.resume = JSON.parse(this.product.resume);
                this.product.remember = JSON.parse(this.product.remember);
            }, error => {
                console.log(error);
            })
        },
        resumeText() {
            let count = 0
            this.product.resume.forEach(item => {
                let letter = item.text.trim();
                if(letter.length == 0) {
                    count++;
                }
            })
            if (count > 0) {
                this.validate_resume = true;
            } else {
                this.validate_resume = false;
            }
        },
        rememberText() {
            let count = 0
            this.product.remember.forEach(item => {
                let letter = item.text.trim();
                if(letter.length == 0) {
                    count++;
                }
            })
            if (count > 0) {
                this.validate_remember = true;
            } else {
                this.validate_remember = false;
            }
        },
        addResume() {
            this.product.resume.push({text: '', image: null});
            console.log(this.product.resume);
            this.resumeText();
        },
        removeResume(index) {
            this.product.resume.splice(index, 1);
            this.resumeText();
        },
        addRemember() {
            this.product.remember.push({text: ''});
            console.log(this.product.remember);
            this.rememberText();
        },
        removeRemember(index) {
            this.product.remember.splice(index, 1);
            this.rememberText();
        },
        update() {
            this.isLoading = true;
            let item = Object.assign({}, this.product);
            item.resume = JSON.stringify(item.resume);
            item.remember = JSON.stringify(item.remember);

            productService.update(item).then(response => {
                this.isLoading = false;
                this.$router.push({path: '/productos'})
            }, error => {
                toast.error(JSON.stringify(error.response.data.errors), {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            });
        },
        uploadArchive1(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('width', this.recomend_width_img);
                formData.append('height', this.recomend_height_img);
                formData.append('file', file);
                formData.append('path', 'productos-image');
                
                productService.uploadFileImage(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.product.image = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
        uploadArchive2(e, index) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('width', this.recomend_width_img_resume);
                formData.append('height', this.recomend_height_img_resume);
                formData.append('file', file);
                formData.append('path', 'productos-image');
                
                productService.uploadFileImage(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.product.resume[index].image = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
        uploadArchive3(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('width', this.recomend_width_img_preparation);
                formData.append('height', this.recomend_height_img_preparation);
                formData.append('file', file);
                formData.append('path', 'productos-image');
                
                productService.uploadFileImage(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.product.preparation_image = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
        uploadArchive4(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('file', file);
                formData.append('path', 'productos-pdf');
                
                productService.uploadFile(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.product.file = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
        uploadArchive5(e){
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('file', file);
                formData.append('path', 'productos-pdf');
                
                productService.uploadFile(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.product.file_faq = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        }
    }
}
</script>