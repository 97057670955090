import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getNews () {
        return axios.get(ENDPOINT_PATH + `/news`);
    },
    show(id) {
        return axios.get(ENDPOINT_PATH + `/news/${id}`);
    },
    store(data) {
        return axios.post(ENDPOINT_PATH + `/news`, data);
    },
    update(data) {
        return axios.put(ENDPOINT_PATH + `/news/${data.id}`, data);
    },
    delete(id) {
        return axios.delete(ENDPOINT_PATH + `/news/${id}`);
    },
    uploadFile(formData) {
        return axios.post(ENDPOINT_PATH + `/store-file`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    uploadFileImage(formData) {
        return axios.post(ENDPOINT_PATH + `/store-file-image`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
};

