<template>
    <v-container>
        <v-row v-if="info.enable_image_video" class="pt-3">
            <v-col cols="12" md="8" lg="8" id="content_news_video">
                    <div v-if="info.image">
                        <v-skeleton-loader class="skeleton_information_img_video" :loading="loading_information" type="image">
                            <v-img cover :src="info.image"></v-img>
                        </v-skeleton-loader>
                    </div>
                    <div v-if="info.video_type == 1 && info.video">
                        <v-skeleton-loader class="skeleton_information_img_video" :loading="loading_information_video" type="image">
                            <Video :data="info.video"></Video>
                        </v-skeleton-loader>
                    </div>
                    <div v-if="info.video_type == 2 && info.video">
                        <v-skeleton-loader class="skeleton_information_img_video" :loading="loading_information_video" type="image">
                                <vue-vimeo-player class="news-vimeo" ref="newsVimeo" :video-id="info.video"/>
                        </v-skeleton-loader>
                    </div>
            </v-col>
            <v-col cols="12" md="4" lg="4" :style="{'order': info.position_paragraphs}">
                <template v-if="!loading_information">
                    <div class="info-news">
                        <h1 class="title">{{ info.title }}</h1>
                        <div class="content-paragraphs" v-if="count > 0 && info.paragraphs[0].text">
                            <p v-for="item in info.paragraphs">
                                {{ item.text }}
                            </p>
                        </div>
                    </div>
                </template>
                
                <template v-if="loading_information">
                    <div class="info-news">
                        <v-skeleton-loader class="skeleton_information_text" :loading="loading_information" type="heading, paragraph, paragraph, paragraph">
                        </v-skeleton-loader>
                    </div>
                </template>
            </v-col>
        </v-row>
        <v-row v-if="!info.enable_image_video" class="mx-5 my-5 px-lg-5">
            <v-col cols="12" md="12" lg="12">
                <template v-if="!loading_information">
                    <div class="info-news">
                        <h1 class="title">{{ info.title }}</h1>
                        <div v-if="count > 0 && info.paragraphs[0].text">
                            <p v-for="item in info.paragraphs">
                                {{ item.text }}
                            </p>
                        </div>
                    </div>
                </template>
                <template v-if="loading_information">
                    <div class="info-news">
                        <v-skeleton-loader class="skeleton_information_text" :loading="loading_information" type="heading, paragraph, paragraph, paragraph">
                        </v-skeleton-loader>
                    </div>
                </template>
            </v-col>
        </v-row>
    </v-container>
    
</template>

<style>
#content_news_video .video-js {
    width: calc(100% - 5px) !important;
    height: calc(100vh - 100px) !important;
}
.info-news {
    text-align: justify;
}
.info-news .title {
    font-size: 20px;
    font-weight: 700;
    color: #0D1329;
    padding-bottom: 10px;
}
.info-news .content-paragraphs {
    padding-right: 60px;
}
.info-news p {
    padding-bottom: 15px;
}
.skeleton_information_img_video {
    background-color: transparent;
    align-items: flex-start;
    height: calc(100vh - 100px) !important;
}
.skeleton_information_img_video .v-skeleton-loader__image{
    height: 100%;
}
.skeleton_information_text {
    background-color: transparent;
}
.skeleton_information_text .v-skeleton-loader__heading{
    max-width: 26%;
}
.news-vimeo ,
.news-vimeo iframe {
    width: 100%;
    height: 100%;
    background-color: #000000;
}
@media(max-width: 959px) {
    .skeleton_information_img_video {
        height: 100% !important;
    }
}
</style>

<script>
import { mapGetters } from "vuex";
import {vueVimeoPlayer} from 'vue-vimeo-player'
import newsService from '@/composables/news'
import Video from '../news/video.vue';
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';

export default {
    name: 'Information',
    components: {
        VSkeletonLoader,
        Video,
        vueVimeoPlayer
    },
    data: () => ({
        loading_information: true,
        loading_information_video: true,
        info: [],
        count: 0,
    }),
    computed:{
        ...mapGetters(["userSession"]),
        getIdUrl() {
            return this.$route.params.newsId;
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading_information = true;
            this.loading_information_video = true;
            newsService.show(this.getIdUrl).then(response => {
                this.info = response.data.data;
                this.info.paragraphs = JSON.parse(this.info.paragraphs);
                this.count = this.info.paragraphs.length;
                this.loading_information = false;
                if (this.userSession.role === 'student') {
                    setTimeout(() => {
                        this.loading_information_video = false;
                    }, 1000)
                }
            }).catch(e => {
                console.log(e);
            })
        },
    },
    created() {
    }
}
</script>