import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getCourses () {
        return axios.get(ENDPOINT_PATH + `/courses`);
    },
    show(id) {
        return axios.get(ENDPOINT_PATH + `/courses/${id}`);
    },
    store(data) {
        return axios.post(ENDPOINT_PATH + `/courses`, data);
    },
    update(data) {
        return axios.put(ENDPOINT_PATH + `/courses/${data.id}`, data);
    },
    delete(id) {
        return axios.delete(ENDPOINT_PATH + `/courses/${id}`);
    },
    uploadFile(formData) {
        return axios.post(ENDPOINT_PATH + `/store-file`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
    uploadFileImage(formData) {
        return axios.post(ENDPOINT_PATH + `/store-file-image`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
};

