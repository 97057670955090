<template>
    <v-container>
        <div class="text-left font-weight-bold mb-4 text-h5">Acciona</div>
        <v-tabs v-model="tab">
            <v-tab value="one" variant="outlined" rounded max-height="34" class="mr-3 text-capitalize" style="border: 1px solid #CFD0D4;
                border-radius: 34px;color: #3D4254;">Básico</v-tab>
            <v-tab value="two" variant="outlined" rounded max-height="34" class="mr-3 text-capitalize" style="border: 1px solid #CFD0D4;
                border-radius: 34px;color: #3D4254;">Intermedio</v-tab>
            <v-tab value="three" variant="outlined" rounded max-height="34" class="mr-3 text-capitalize" style="border: 1px solid #CFD0D4;
                border-radius: 34px;color: #3D4254;">Avanzado</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item value="one">
                <v-row class="my-4">
                    <v-col cols="12" md="4">
                        <div class="font-weight-bold text-left mb-4" style="color: #6E717F;">Digitalización</div>
                        <v-card elevation="0">
                            <div class="d-flex flex-no-wrap justify-space-between align-center">

                                <v-avatar class="" size="80" rounded="0">
                                    <v-img alt="" class="" cover src="@/assets/images/user1.png" max-width="80" />
                                </v-avatar>
                                <div class="w-100 px-2">
                                    <div class="d-flex justify-space-between">
                                        <div class="font-weight-bold">Presencial online</div>
                                        <v-spacer></v-spacer>
                                        <div class="justify-self-end"><v-icon size="small">mdi-arrow-right</v-icon></div>
                                    </div>
                                    <br>
                                    <div class="d-flex flex-row justify-space-between align-center">
                                        <div class="fs-14" style="font-weight: 500;"><v-icon color="#FF80FF"
                                                size="small">mdi-text-box-multiple-outline</v-icon> 5 lecciones</div>
                                        <div class="justify-self-end font-weight-medium fs-14"><v-icon color="#FF80FF"
                                                size="small">mdi-clock-time-five-outline</v-icon> 1h 25 min</div>
                                    </div>
                                </div>

                            </div>
                        </v-card>
                        <v-divider class="my-4"></v-divider>
                        <v-card elevation="0">
                            <div class="d-flex flex-no-wrap justify-space-between align-center">

                                <v-avatar class="" size="80" rounded="0">
                                    <v-img alt="" class="" cover src="@/assets/images/user1.png" max-width="80" />
                                </v-avatar>
                                <div class="w-100 px-2">
                                    <div class="d-flex justify-space-between">
                                        <div class="font-weight-bold">Presencial online</div>
                                        <v-spacer></v-spacer>
                                        <div class="justify-self-end"><v-icon size="small">mdi-arrow-right</v-icon></div>
                                    </div>
                                    <br>
                                    <div class="d-flex flex-row justify-space-between align-center">
                                        <div class="fs-14" style="font-weight: 500;"><v-icon color="#FF80FF"
                                                size="small">mdi-text-box-multiple-outline</v-icon> 5 lecciones</div>
                                        <div class="justify-self-end font-weight-medium fs-14"><v-icon color="#FF80FF"
                                                size="small">mdi-clock-time-five-outline</v-icon> 1h 25 min</div>
                                    </div>
                                </div>

                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">

                        <div class="font-weight-bold text-left mb-4" style="color: #6E717F;">Necessitatibus</div>
                        <v-card elevation="0">
                            <div class="d-flex flex-no-wrap justify-space-between align-center">

                                <v-avatar class="" size="80" rounded="0">
                                    <v-img alt="" class="" cover src="@/assets/images/user1.png" max-width="80" />
                                </v-avatar>
                                <div class="w-100 px-2">
                                    <div class="d-flex justify-space-between">
                                        <div class="font-weight-bold">Presencial online</div>
                                        <v-spacer></v-spacer>
                                        <div class="justify-self-end"><v-icon size="small">mdi-arrow-right</v-icon></div>
                                    </div>
                                    <br>
                                    <div class="d-flex flex-row justify-space-between align-center">
                                        <div class="fs-14" style="font-weight: 500;"><v-icon color="#FF80FF"
                                                size="small">mdi-text-box-multiple-outline</v-icon> 5 lecciones</div>
                                        <div class="justify-self-end font-weight-medium fs-14"><v-icon color="#FF80FF"
                                                size="small">mdi-clock-time-five-outline</v-icon> 1h 25 min</div>
                                    </div>
                                </div>

                            </div>
                        </v-card>
                        <v-divider class="my-4"></v-divider>
                        <v-card elevation="0">
                            <div class="d-flex flex-no-wrap justify-space-between align-center">

                                <v-avatar class="" size="80" rounded="0">
                                    <v-img alt="" class="" cover src="@/assets/images/user1.png" max-width="80" />
                                </v-avatar>
                                <div class="w-100 px-2">
                                    <div class="d-flex justify-space-between">
                                        <div class="font-weight-bold">Presencial online</div>
                                        <v-spacer></v-spacer>
                                        <div class="justify-self-end"><v-icon size="small">mdi-arrow-right</v-icon></div>
                                    </div>
                                    <br>
                                    <div class="d-flex flex-row justify-space-between align-center">
                                        <div class="fs-14" style="font-weight: 500;"><v-icon color="#FF80FF"
                                                size="small">mdi-text-box-multiple-outline</v-icon> 5 lecciones</div>
                                        <div class="justify-self-end font-weight-medium fs-14"><v-icon color="#FF80FF"
                                                size="small">mdi-clock-time-five-outline</v-icon> 1h 25 min</div>
                                    </div>
                                </div>

                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <div class="font-weight-bold text-left mb-4" style="color: #6E717F;">Necessitatibus</div>
                        <v-card elevation="0">
                            <div class="d-flex flex-no-wrap justify-space-between align-center">

                                <v-avatar class="" size="80" rounded="0">
                                    <v-img alt="" class="" cover src="@/assets/images/user1.png" max-width="80" />
                                </v-avatar>
                                <div class="w-100 px-2">
                                    <div class="d-flex justify-space-between">
                                        <div class="font-weight-bold">Presencial online</div>
                                        <v-spacer></v-spacer>
                                        <div class="justify-self-end"><v-icon size="small">mdi-arrow-right</v-icon></div>
                                    </div>
                                    <br>
                                    <div class="d-flex flex-row justify-space-between align-center">
                                        <div class="fs-14" style="font-weight: 500;"><v-icon color="#FF80FF"
                                                size="small">mdi-text-box-multiple-outline</v-icon> 5 lecciones</div>
                                        <div class="justify-self-end font-weight-medium fs-14"><v-icon color="#FF80FF"
                                                size="small">mdi-clock-time-five-outline</v-icon> 1h 25 min</div>
                                    </div>
                                </div>

                            </div>
                        </v-card>
                        <v-divider class="my-4"></v-divider>
                        <v-card elevation="0">
                            <div class="d-flex flex-no-wrap justify-space-between align-center">

                                <v-avatar class="" size="80" rounded="0">
                                    <v-img alt="" class="" cover src="@/assets/images/user1.png" max-width="80" />
                                </v-avatar>
                                <div class="w-100 px-2">
                                    <div class="d-flex justify-space-between">
                                        <div class="font-weight-bold">Presencial online</div>
                                        <v-spacer></v-spacer>
                                        <div class="justify-self-end"><v-icon size="small">mdi-arrow-right</v-icon></div>
                                    </div>
                                    <br>
                                    <div class="d-flex flex-row justify-space-between align-center">
                                        <div class="fs-14" style="font-weight: 500;"><v-icon color="#FF80FF"
                                                size="small">mdi-text-box-multiple-outline</v-icon> 5 lecciones</div>
                                        <div class="justify-self-end font-weight-medium fs-14"><v-icon color="#FF80FF"
                                                size="small">mdi-clock-time-five-outline</v-icon> 1h 25 min</div>
                                    </div>
                                </div>

                            </div>
                        </v-card>
                    </v-col>
                </v-row>

            </v-window-item>

            <v-window-item value="two">
                Two
            </v-window-item>

            <v-window-item value="three">
                Three
            </v-window-item>
        </v-window>
    </v-container>
</template>
<style>
.v-tab--selected .v-tab__slider {
    opacity: 0;
}
</style>
<style scoped>
.fs-14 {
    font-size: 14px;
}
.v-slide-group-item--active {
    background: #0D1329;
    border-radius: 34px;
    color: #FFFFFF !important;
}
</style>
<script>
export default {
    data: () => ({
        tab: null,
    }),
}
</script>