<template>
    <div class="ma-lg-6 ma-0">
        <v-card>           
            <v-data-table 
            :headers="headers"
            :items="courses"
            :search="searchCourse"
            item-value="name"
            :items-per-page="countCourse"
            :loading="loading"
            class="elevation-1">
                <template v-slot:top>
                    <v-toolbar>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title align="center" class="headline">Estás seguro de eliminar este curso?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" :disabled="disabled" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" :disabled="disabled" text @click="deleteItemConfirm">Sí, estoy
                                        seguro
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-row align="center" class="px-4">
                            <v-col cols="12" md="4">
                                <v-toolbar-title class="text-start">Cursos</v-toolbar-title>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-form>
                                    <v-text-field
                                        bg-color="#ffffff"
                                        v-model="searchCourse"
                                        label="Buscar curso"
                                        prepend-inner-icon="mdi-magnify"
                                        single-line
                                        hide-details
                                    ></v-text-field>
                                </v-form>
                            </v-col>
                            <v-col cols="12" md="4" align="end">
                                <v-btn dark class="btn-green" to="/curso/create">
                                    <v-icon left> mdi-plus </v-icon>
                                    Agregar curso
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-toolbar>
                </template>
                <template v-slot:item.id="{ item, index }">
                    {{ index+1 }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn icon class="mr-3" :to="'/curso/edit/' + item.raw.id">
                        <v-icon>
                            mdi-square-edit-outline
                        </v-icon>
                    </v-btn>
                    <v-icon @click="deleteItem(item)">
                        mdi-trash-can-outline
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import courseService from '@/composables/courses';
import { VDataTable } from 'vuetify/labs/VDataTable'
import { toast } from 'vue3-toastify';

export default {
    name: "Course",
    components: {
        VDataTable,
    },
    data: () => ({
        disabled: false,
        searchCourse: '',
        countCourse: 0,
        courses: [],
        loading: true,
        dialogDelete: false,
        editedIndex: null,
        headers: [
            {
                width: '4%',
                title: 'N°',
                align: 'center',
                sortable: false,
                key: 'id',
            },
            {
                width: '10%',
                title: 'Curso',
                align: 'center',
                sortable: false,
                key: 'name',
            },
            {
                width: '40%',
                title: 'Descripción',
                align: 'center',
                sortable: false,
                key: 'description',
            },
            {
                width: '10%',
                title: 'Nivel',
                align: 'center',
                sortable: false,
                key: 'level_name',
            },
            {
                width: '10%',
                title: 'Duración',
                align: 'center',
                sortable: false,
                key: 'duration_format',
            },
            {width: '10%', title: 'Acciones', key: 'actions', sortable: false, align: 'center'},
        ],
    }),
    watch: {
        params: {
            handler() {
                this.getDataFromApi()
            },
        },
    },
    computed: {
    },
    mounted() {
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi() {
            this.loading = true;
            courseService.getCourses().then(response => {
                this.courses = response.data;
                this.countCourse = response.data.lenght;
                this.loading = false;
            }).catch(e => {
                this.loading = false;
                console.log(e);
            })
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.editedIndex = item.raw.id;
        },
        deleteItemConfirm() {
            this.disabled = true;
            courseService.delete(this.editedIndex).then(result => {
                this.getDataFromApi();
                this.closeDelete();
                toast.success(result.data.message, {autoClose: 1500});
                this.disabled = false;
            }, error => {
                this.disabled = false;
                console.log(error);
            });
        },
    },
}
</script>