<template>
    <v-container class="text-left">
        <h1 class="text-center">Términos y condiciones</h1>
        <div class="text-h6">Fecha de vigencia: 01/24/27</div>
        <div class="text-h6">1. Introducción</div>
        <p>Bienvenido a NuevaMNT. Nos comprometemos a proteger su privacidad. Esta
            Política de Privacidad explica cómo recopilamos, usamos, divulgamos y
            protegemos la información que obtenemos a través de nuestro sitio web
            ubicado en https://www.nuevamnt.com</p>
        <div class="text-h6">2. Información que Recopilamos</div>
        <p>Podemos recopilar información personal y no personal sobre usted. Esto puede
            incluir, pero no se limita a, su nombre, dirección de correo electrónico, detalles
            de contacto, preferencias, intereses, y otra información relevante para encuestas
            y/u ofertas.</p>
        <div class="text-h6">3. Cómo Utilizamos su Información</div>
        <p>Utilizamos su información para entender sus necesidades y brindarle un mejor
            servicio, en particular por las siguientes razones:</p>
        <ul style="margin-left: 3rem;">
            <li>Mantenimiento interno de registros.</li>
            <li>Mejora de nuestros productos y servicios.</li>
            <li>Envío periódico de correos electrónicos promocionales sobre nuevos
                productos, ofertas especiales u otra información que consideremos
                relevante para usted.</li>
            <li>Personalización del sitio web según sus intereses.</li>
        </ul>
        <div class="text-h6">4. Seguridad</div>
        <p>Estamos comprometidos a garantizar que su información esté segura. Para
            prevenir el acceso no autorizado o la divulgación, hemos puesto en marcha
            procedimientos físicos, electrónicos y administrativos adecuados para
            salvaguardar y asegurar la información que recopilamos en línea.</p>
        <div class="text-h6">5. Control de su Información Personal</div>
        <p>Usted puede optar por restringir la recopilación o el uso de su información
            personal de las siguientes maneras:</p>
        <ul style="margin-left: 3rem;">
            <li>Siempre que se le pida que complete un formulario en el sitio web,
                busque la casilla en la que puede hacer clic para indicar que no desea
                que la información sea utilizada por nadie con fines de marketing directo.</li>
            <li>Si previamente ha acordado con nosotros el uso de su información
                personal para fines de marketing directo, puede cambiar de opinión en
                cualquier momento escribiéndonos o enviándonos un correo electrónico.</li>
        </ul>
        <div class="text-h6">6. Enlaces a Otros Sitios Web</div>
        <p>Nuestro sitio web puede contener enlaces a otros sitios web de interés. Sin
            embargo, una vez que haya utilizado estos enlaces para abandonar nuestro
            sitio, debe tener en cuenta que no tenemos ningún control sobre ese otro sitio
            web. Por lo tanto, no podemos ser responsables de la protección y privacidad
            de cualquier información que proporcione mientras visita tales sitios y dichos
            sitios no están gobernados por esta declaración de privacidad.</p>
        <div class="text-h6">7. Cambios a Nuestra Política de Privacidad</div>
        <p>Nos reservamos el derecho de modificar esta política de privacidad en cualquier
            momento, por lo que le recomendamos revisarla frecuentemente. Los cambios y
            aclaraciones entrarán en vigor inmediatamente después de su publicación en el
            sitio web.</p>
        <div class="text-h6">8. Eliminar cuenta</div>
        <p>Primero necesita contactarnos para verificar y resolver cualquier situación, deuda o contrato. Para eliminar su cuenta, por favor contáctenos: atencion@yosoylegacy.com y proporcione su identificación de cuenta, nombre completo y su documento nacional de identidad.</p>
        <div class="text-h6">9. Contacto</div>
        <p>Si tiene preguntas sobre esta Política de Privacidad, puede contactarnos
            mediante hola@legacy.com</p>
    </v-container>
</template>

<script>
export default {
    name: "Terms",
}
</script>