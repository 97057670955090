<template>
    <v-container class="content-elearning">
        <v-row>
            <v-col cols="12" md="7" lg="7" class="pb-0">
                <div class="info-categories">
                    <template v-if="!loading_category_img">
                        <div v-for="item in categories" style="height: max-content;">
                            <router-link class="redirect-elearning" :to="'elearning/'+(item.name.toLowerCase()).replace(' ', '-')+'/'+item.id"><v-img alt="" class="img-category align-end" cover :src="item.image" style="border-radius: 16px;">
                                <div class="text-category pa-3">{{ item.name }}</div>
                                <div class="background-opacity"></div>
                            </v-img></router-link>
                        </div>
                    </template>
                    <template v-if="loading_category_img">
                        <div v-for="n in 4">
                            <v-skeleton-loader class="skeleton_category" :loading="true" type="card">
                            </v-skeleton-loader>
                        </div>
                    </template>
                </div>
            </v-col>
            <v-col cols="12" md="1" lg="1">
            </v-col>
            <v-col cols="12" md="4" lg="4">
                <TopUsers/>
                <div class="mt-8">
                    <template v-if="userSession.role == 'student'">
                        <div v-if="!loading_video && validate_position_video.position_video.length > 0">
                            <Video :videoInfo="users" :userData="userSession"></Video>
                        </div>
                    </template>
                    <v-skeleton-loader v-if="loading_video" :loading="true" type="heading, image">
                    </v-skeleton-loader>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.img-category {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 4px !important;
}
.text-category{
    position: relative;
    font-weight: 700;
    color: #ffffff;
    text-align: left;
    z-index: 10;
}
.background-opacity{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    bottom: 0;
    background: linear-gradient(180deg, rgba(13, 19, 41, 0) 52%, #0D1329 100%);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.content-elearning {
    height: calc(100vh - 10px);
    margin-top: 8px;
}
.content-elearning .info-categories {
    max-height: calc(100vh - 30px);
    height: max-content;
    display: grid;
    overflow-y: auto;
    grid-template-columns: repeat(2, minmax(220px, 1fr));
    grid-gap: 30px;
}
.content-elearning .info-categories::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: rgba(39, 43, 65, 0.05);
}
.content-elearning .info-categories::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.content-elearning .info-categories::-webkit-scrollbar-thumb {
  background-color: #e3e6ef;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
.content-elearning .info-categories .redirect-elearning {
    text-decoration: none;
}
.skeleton_category{
    height: 100%;
    border-radius: 16px;
}
.skeleton_category .v-skeleton-loader__bone.v-skeleton-loader__card{
    height: 100%;
    align-items: start;
}
@media (max-width: 1279px) {
    .content-elearning .info-categories {
        max-height: calc(100vh - 150px);
    }
}
@media (max-width: 530px) {
    .content-elearning .info-categories {
        grid-template-columns: repeat(1, minmax(220px, 1fr));
    }
}
</style>

<script>
import { mapGetters } from "vuex";
import categoryService from "@/composables/categories";
import userService from "@/composables/user";
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';
import Video from '../../views/video.vue';
import TopUsers from '../../views/topUsers.vue';

export default {
    name: "Elearning",
    components: {
        VSkeletonLoader,
        TopUsers,
        Video
    },
    data: () => ({
        loading_video: true,
        loading_category_img: true,
        player: null,
        users: [],
        categories: [],
        options: {},
        played_video: null,
        name_video: null,
        new_courses: null,
        new_lessons: null,
        validate_position_video: null
    }),
    computed: {
        ...mapGetters(["userSession"]),
    },
    mounted () {
        this.getData();
        this.getUsers();
    },
    methods: {
        async getData() {
            this.loading_category_img = true;
            categoryService.getCategories().then(response => {
                this.categories = response.data.data;
                setTimeout(() => {
                    this.loading_category_img = false;
                }, 1000)
            }).catch(e => {
                this.loading_category_img = false;
                console.log(e);
            })
        },
        getUsers() {
            this.loading_video = true;
            userService.getUsers().then(response => {
                this.users = response.data.data;
                this.validate_position_video = this.users.find(item => item.id == this.userSession.id);
                setTimeout(() => {
                    this.loading_video = false;
                }, 1000)
            }).catch(e => {
                this.loading_video = false;
                console.log(e);
            })
        },
    },
    created() {
    }
};
</script>