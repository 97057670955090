<template>
    <video ref="playedArchiveDashboard" class="video-js" v-on:pause="pauseVideo()"></video>
</template>

<script>
import { mapGetters } from "vuex";
import positionVideoService from "@/composables/position_videos"
import videojs from 'video.js';

export default {
    name: 'CourseVideo',
    props: ['playedVideo', 'videoInfo'],
    data: function() {
        return {
            player: null,
            options: {
                autoplay: false,
                controls: true,
                loop: false,
            },
            new_courses: null,
            new_lessons: null,
        }
    },
    computed:{
        ...mapGetters(["userSession"]),
    },
    mounted() {
       this.createVideo();
    },
    methods: {
        createVideo() {
            this.player = videojs(this.$refs.playedArchiveDashboard, this.options);
            this.player.src({type: 'video/mp4', src: this.playedVideo.data.video});
            if ((this.playedVideo.data.video_duration - 2) < this.playedVideo.data.video_paused) {
                this.player.currentTime(0);
            } else {
                this.player.currentTime(this.playedVideo.data.video_paused ?? 0);
            }
        },
        pauseVideo() {
            let currentTime = Math.round(this.player.cache_.currentTime);
            let duration = Math.round(this.player.cache_.duration);

            if (this.playedVideo.type === 'course') {
                let isExist = this.videoInfo.find(item => item.id == this.userSession.id);
                if (isExist) {
                    this.new_courses = JSON.parse(isExist.position_video[0].course_videos ?? null);
                    this.new_lessons = JSON.parse(isExist.position_video[0].lesson_videos ?? null);
                    this.new_courses.forEach(item => {
                        if (item.course_id == this.playedVideo.data.course_id) {
                            item.video_paused = this.player.cache_.currentTime;
                            item.last_pause = (new Date()).getTime();
                            //Validamos si se finalizo la reproducción del video
                            if ((currentTime <= duration && currentTime >= duration - 2) && !isExist.finish) {
                                item.finish = true;
                                item.finish_play = (new Date()).getTime();
                            }
                            this.update();
                        }
                    })
                } 
            }

            if (this.playedVideo.type === 'lesson') {
                let isExist = this.videoInfo.find(item => item.id == this.userSession.id);
                if (isExist) {
                    this.new_courses = JSON.parse(isExist.position_video[0].course_videos ?? null);
                    this.new_lessons = JSON.parse(isExist.position_video[0].lesson_videos ?? null);
                    this.new_lessons.forEach(item => {
                        if (item.lesson_id == this.playedVideo.data.lesson_id) {
                            item.video_paused = this.player.cache_.currentTime;
                            item.last_pause = (new Date()).getTime();
                            //Validamos si se finalizo la reproducción del video
                            if ((currentTime <= duration && currentTime >= duration - 2) && !isExist.finish) {
                                item.finish = true;
                                item.finish_play = (new Date()).getTime();
                            }
                            this.update();
                        }
                    })
                }
            }
        },
        update() {
            let data = Object.assign({});
            data.id = this.playedVideo.position_video_id;
            data.user_id = this.userSession.id;
            data.course_videos = this.new_courses ? JSON.stringify(this.new_courses) : null;
            data.lesson_videos = this.new_lessons ? JSON.stringify(this.new_lessons) : null;

            positionVideoService.update(data).then(response => {
                //console.log(response.data);
            }).catch(e => {
                console.log(e);
            })
        }
    },
}
</script>