import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getPositionVideo(id) {
        return axios.get(ENDPOINT_PATH + `/position-videos/${id}`,);
    },
    store(data) {
        return axios.post(ENDPOINT_PATH + `/position-videos`, data);
    },
    update(data) {
        return axios.put(ENDPOINT_PATH + `/position-videos/${data.id}`, data);
    },
};

