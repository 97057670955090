<template>
    <v-container>
        <v-img alt="" class="" cover src="@/assets/images/video.png" max-width="812" />
        <v-tabs align-tabs="center" class="my-4" v-model="tab">
            <v-tab value="one" class="text-capitalize">Descripción</v-tab>
            <v-tab value="two" class="text-capitalize">Contenido</v-tab>
            <v-tab value="three" class="text-capitalize">Materiales</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item value="one">
                <section v-if="errored">
                    <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
                </section>

                <section v-else>
                    <div v-if="loading">Loading...</div>

                    <div v-else v-for="currency in info">
                        <div class="font-weight-bold text-left currency">
                            {{ currency.name }}
                        </div>
                        <p class="text-left">
                             {{ currency.description }}
                        </p>
                        <div class="d-flex flex-row align-center mt-1 mb-6">
                            <div class="fs-14 mr-4" style="font-weight: 500;">
                                <v-icon color="#FF80FF"
                                        size="small">mdi-text-box-multiple-outline
                                </v-icon>
                                {{ currency.description }} lecciones
                            </div>
                            <div class="justify-self-end font-weight-medium fs-14">
                                <v-icon color="#FF80FF"
                                        size="small">mdi-clock-time-five-outline
                                </v-icon>
                                1h 25 min
                            </div>
                        </div>

                    </div>

                </section>


            </v-window-item>

            <v-window-item value="two">
                <div class="d-flex flex-row justify-space-between align-center">
                    <div>
                        <div class="font-weight-bold">¿Por qué construir presencia online?</div>
                        <div class="text-caption text-left">28 min</div>
                    </div>
                    <div>
                        <v-icon>mdi-play-outline</v-icon>
                    </div>
                </div>
                <v-divider class="my-3"></v-divider>
                <div class="d-flex flex-row justify-space-between align-center">
                    <div>
                        <div class="font-weight-bold">Paso 1</div>
                        <div class="text-caption text-left">28 min</div>
                    </div>
                    <div>
                        <v-icon>mdi-play-outline</v-icon>
                    </div>
                </div>
            </v-window-item>

            <v-window-item value="three">
                Three
            </v-window-item>
        </v-window>

    </v-container>

</template>
<style scoped>
/* .v-tab--selected .v-tab__slider {
    background: #00F0F0 !important;

} */
.v-slide-group-item--active {
    border-bottom: 2px solid #00F0F0;
    border-radius: 0;
}
</style>

<script>
import axios from 'axios';
import useCourses from '../../src/composables/courses'
import {onMounted} from "vue";

export default{

  data () {
    return {
        tab:null,
        info: null,
        loading: true,
        errored: false
    }
  },

  mounted () {
    /*axios
      .get('http://localhost/api/courses')
      .then(response => {
        this.info = response.data.data.data
      })
      .catch(error => {
        console.log(error)
        this.errored = true
      })
      .finally(() => this.loading = false)*/
  },
  // setup(){
  //   const {courses, getCourses} = useCourses();
  //   onMounted(getCourses);
  //   return{
  //       courses,
  //   }
  // }
}

</script>