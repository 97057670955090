<template>
    <video ref="newsVideo" class="video-js news-video"></video>
</template>

<style>
.video-js.news-video{
    width: 100%;
    height: 270px;
}
</style>

<script>
import videojs from 'video.js';

export default {
    name: 'Video',
    props: ['data'],
    data: () => ({
        player: null,
        options: {},
    }),
    mounted() {
        this.createVideo();
    },
    methods: {
        createVideo() {
            this.options = {
                autoplay: false,
                controls: true,
                loop: false,
            };

            this.player = videojs(this.$refs.newsVideo, this.options);
            this.player.src({type: 'video/mp4', src: this.data});
        },
    },
}
</script>