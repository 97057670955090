<template>
    <div class="ma-lg-6 ma-0">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"/>
        <v-card>
            <Breadcrumbs :items="breadcrumbs"/>
            <v-form v-model="valid" class="px-3 py-2">
                <v-row>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Categoría</h4>
                        <v-select
                            v-on:update:model-value="onSelecCategory($event)"
                            v-model="select_category"
                            :items="categories"
                            :rules="rules.select_category"
                            item-title="name"
                            item-value="id"
                            label="Categoría"
                            required
                            outlined
                            solo
                        ></v-select>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Sub categoría</h4>
                        <v-select
                            v-model="select_subcategory"
                            :items="subcategories"
                            :rules="rules.select_sub_category"
                            item-title="name"
                            item-value="id"
                            label="Sub categoría"
                            required
                            outlined
                            solo
                        ></v-select>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Nivel</h4>
                        <v-select
                            v-model="select_level"
                            :items="levels"
                            :rules="rules.level"
                            item-title="name"
                            item-value="id"
                            label="Nivel"
                            required
                            outlined
                            solo
                        ></v-select>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Nombre</h4>
                        <v-text-field
                            v-model="curso.name"
                            :rules="rules.name"
                            label="Nombre"
                            required
                            outlined
                            solo
                        ></v-text-field>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Descripción</h4>
                        <v-textarea
                            rows="2"
                            v-model="curso.description"
                            :rules="rules.description"
                            label="Descripción"
                            required
                            outlined
                            solo
                        ></v-textarea>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Imagen</h4>
                        <v-file-input 
                            variant="underlined"
                            :label="'Imagen (recomendado width:'+recomend_width_img+'px, height:'+recomend_height_img+'px)'"
                            accept="image/png, image/jpg, image/jpeg"
                            @change="uploadArchive1($event)">
                        </v-file-input>
                        <v-col v-if="curso.image" class="position-relative" cols="12" sm="3" md="2">
                            <v-img :src="curso.image"/>
                        </v-col>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Duración</h4>
                        <VueDatePicker v-model="time" time-picker mode-height="120" :teleport="true"/>
                    </v-col>
                     <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Método a usar para el video</h4>
                        <v-switch
                            v-model="select_video_type"
                            hide-details
                            inset
                            @click="curso.video = null; curso.video_vimeo = null"
                            :label="`${select_video_type ? 'Subir archivo' : 'Vimeo'}`" 
                        ></v-switch>
                        <template v-if="select_video_type">
                            <h4 class="mb-3">Subir archivo</h4>
                            <v-file-input 
                                variant="underlined"
                                label="Archivo"
                                accept="video/mp4"
                                @change="uploadArchive2($event)">
                            </v-file-input>
                            <v-col v-if="curso.video" class="position-relative" cols="12" sm="6" md="4">
                                <Video :data="curso.video"></Video>
                            </v-col>
                        </template>
                        <template v-if="!select_video_type">
                            <h4 class="mb-3">Vimeo</h4>
                            <v-text-field
                                v-model="curso.video_vimeo"
                                label="ID video "
                                type="number"
                                required
                                outlined
                                solo
                            ></v-text-field>
                            <v-col v-if="curso.video_vimeo" class="position-relative" cols="12" sm="6" md="4">
                                <vue-vimeo-player ref="courseVimeo" :video-id="curso.video_vimeo" :player-width="300" :player-height="200"/>
                            </v-col>
                        </template>
                    </v-col>
                </v-row>

                <v-row class="pb-2">
                    <v-col cols="12" md="12" class="d-flex">
                        <v-btn :disabled="!valid" class="mx-auto btn-blue" @click="update">
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import {vueVimeoPlayer} from 'vue-vimeo-player';
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import courseService from '@/composables/courses';
import categoryService from "@/composables/categories";
import { toast } from 'vue3-toastify';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Video from '../video.vue';

export default {
    name: 'CourseEdit',
    components: {
        Breadcrumbs,
        VueDatePicker,
        Loading,
        Video,
        vueVimeoPlayer
    },
    data: () => ({
        isLoading: false,
        breadcrumbs: [
            {text: 'Cursos', disabled: false, to: '/cursos',},
            {text: 'Crear curso', disabled: false, to: '/curso/create',}
        ],
        select_video_type: true,
        curso: {
            name: null,
            description: null,
            duration: null,
            image: null,
            video_type: 1,
            video: null,
            video_vimeo: null,
        },
        recomend_width_img: 80,
        recomend_height_img: 80,
        time: null,
        valid: false,
        select_category: null,
        select_subcategory: null,
        categories: [],
        subcategories: [],
        select_level: null,
        levels: [
            {name: 'Básico', id: 1},
            {name: 'Intermedio', id: 2},
            {name: 'Avanzado', id: 3},
        ],
        rules: {
            name: [
                v => !!v || 'Nombre es requerido',
            ],
            description: [
                v => !!v || 'Descripción es requerido',
            ],
            select_level: [
                v => !!v || 'Nivel es requerido',
            ],
            select_category: [
                v => !!v || 'Categoría es requerido',
            ],
            select_sub_category: [
                v => !!v || 'Sub categoría es requerido',
            ]
        },
    }),
    computed: {
    },
    mounted() {
        this.show(this.$route.params.id);
    },
    methods: {
        getAllCategories(category_id = null,subcategory_id = null) {
            categoryService.getCategories().then(response => {
                this.categories = response.data.data;
                if (category_id && subcategory_id) {
                    let data = this.categories.find(item => item.id == category_id);
                    this.subcategories = data.subcategory;
                    this.select_category = category_id;
                    this.select_subcategory = subcategory_id;
                }
            }, error => {
                console.log(error);
            });
        },
        onSelecCategory(e) {
            this.select_subcategory = null;
            this.subcategories = [];
            let data = this.categories.find(item => {if(item.id == e) {return item}});
            this.subcategories = data.subcategory;
        },
        show(course_id) {
            courseService.show(course_id).then(response => {
                this.curso = response.data.data;
                this.select_video_type = this.curso.video_type == 1 ? true : false;
                this.time = JSON.parse(response.data.data.duration);
                let initLevel = this.levels.find(item => {if(item.id == response.data.data.level) {return item.id}});
                this.select_level = initLevel.id;
                if (response.data.data.category_course.length > 0) {
                    let category_id = response.data.data.category_course[0].category_id;
                    let subcategory_id = response.data.data.category_course[0].sub_category_id;
                    this.getAllCategories(category_id, subcategory_id);
                } else {
                    this.getAllCategories();
                }
            }, error => {
                console.log(error);
            })
        },
        update() {
            this.isLoading = true;
            let item = Object.assign({}, this.curso);
            item.duration = JSON.stringify(this.time);
            item.category_id = this.select_category;
            item.sub_category_id = this.select_subcategory;
            item.level = this.select_level;
            item.video_type = this.select_video_type ? 1 : 2;
           
            courseService.update(item).then(response => {
                this.isLoading = false;
                this.$router.push({path: '/cursos'})
            }, error => {
                toast.error(JSON.stringify(error.response.data.errors), {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            });
        },
        uploadArchive1(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('width', this.recomend_width_img);
                formData.append('height', this.recomend_height_img);
                formData.append('file', file);
                formData.append('path', 'cursos');
                
                courseService.uploadFileImage(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.curso.image = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        },
        uploadArchive2(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('file', file);
                formData.append('path', 'cursos-videos');
                
                courseService.uploadFile(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.curso.video = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        }
    },
}
</script>