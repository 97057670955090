import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export const AUTH_REQUEST_SUCCES = 'AUTH_REQUEST_SUCCES'
export const AUTH_REQUEST_FAILURE = 'AUTH_REQUEST_FAILURE'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const REMOVE_TOKEN = 'REMOVE_TOKEN'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const UPDATE_AVATAR = 'UPDATE_AVATAR'
export const SET_INIT_APPLE = 'SET_INIT_APPLE'

export default createStore({
  plugins: [createPersistedState()],
  state: {
    status: '',
    initApple: false,
    token: localStorage.getItem('token') || '',
    user : {},
    avatar: {},
  },
  mutations: {
    [AUTH_REQUEST_SUCCES](state, { user, token }) {
      state.status = 'success';
      localStorage.setItem('token', token);
      state.token = token;
      localStorage.setItem('user', JSON.stringify(user));
      state.user = user;
      state.avatar = user;
      axios.defaults.headers.common['Authorization'] = "Bearer " + token;
      state.initApple = false;
    },
    [AUTH_REQUEST_FAILURE](state, { error }) {
      state.initApple = false;
      state.status = 'error';
    },
    [AUTH_LOGOUT](state) {
      state.user = {};
      state.status = '';
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      state.token = '';
      state.initApple = false;
    },
    [REMOVE_TOKEN](state) {
      state.initApple = false;
      localStorage.removeItem('token');
    },
    [UPDATE_USER_DATA](state, { data }) {
      //localStorage.setItem('user', JSON.stringify(data));
      state.avatar.name = data.name;
      state.avatar.email = data.email;
      state.avatar.image_photo = data.image_photo;
    },
    [UPDATE_AVATAR](state, { data }) {
      state.avatar = data;
      state.user.avatar_user = data.avatar_user;
      state.user.gender = data.gender;
    },
    [SET_INIT_APPLE](state, value) {
      state.initApple = value;
    },
  },
  actions: {
    login({ commit }, { user }) {
      return new Promise((resolve, reject) => {
          axios.post(ENDPOINT_PATH + `/auth/login`, user)
              .then((response) => {
                if (response.data) {
                  commit(AUTH_REQUEST_SUCCES, { ...response.data })
                  resolve(response)
                } else {
                  commit(AUTH_REQUEST_FAILURE, { error: err.response.data })
                  commit(REMOVE_TOKEN)
                  reject(err.response.data)
                }
              })
              .catch((err) => {
                  commit(AUTH_REQUEST_FAILURE, { error: err.response.data })
                  commit(REMOVE_TOKEN)
                  reject(err.response.data)
              })
      })
    },
    loginGoogle({ commit }, { user }) {
      return new Promise((resolve, reject) => {
          axios.post(ENDPOINT_PATH + `/auth/login-google`, user)
              .then((response) => {
                if (response.data) {
                  commit(AUTH_REQUEST_SUCCES, { ...response.data })
                  resolve(response)
                } else {
                  commit(AUTH_REQUEST_FAILURE, { error: err.response.data })
                  commit(REMOVE_TOKEN)
                  reject(err.response.data)
                }
              })
              .catch((err) => {
                  console.log(err);
                  commit(AUTH_REQUEST_FAILURE, { error: err.response.data })
                  commit(REMOVE_TOKEN)
                  reject(err.response.data)
              })
      })
    },
    loginApple({ commit }, { data }) {
      return new Promise((resolve, reject) => {
          axios.post(ENDPOINT_PATH + `/auth/login-apple`, data)
              .then((response) => {
                if (response.data) {
                  commit(AUTH_REQUEST_SUCCES, { ...response.data })
                  resolve(response)
                } else {
                  commit(AUTH_REQUEST_FAILURE, { error: err.response.data })
                  commit(REMOVE_TOKEN)
                  reject(err.response.data)
                }
              })
              .catch((err) => {
                  commit(AUTH_REQUEST_FAILURE, { error: err.response.data })
                  commit(REMOVE_TOKEN)
                  reject(err.response.data)
              })
      })
    },
    update({commit}, { item }) {
      return new Promise((resolve, reject) => {
        axios.put(ENDPOINT_PATH + `/users/${item['id']}`, item)
            .then((response) => {
                commit(UPDATE_USER_DATA, { ...response.data })
                resolve(response)
            })
            .catch((err) => {
                reject(err.response.data)
            })
    })
    },
    updateAvatar({commit}, { item }) {
      return new Promise((resolve, reject) => {
        axios.put(ENDPOINT_PATH + `/avatar/${item.id}`, item)
            .then((response) => {
                commit(UPDATE_AVATAR, { ...response.data })
                resolve(response)
            })
            .catch((err) => {
                reject(err.response.data)
            })
    })
    },
    chooseAvatar({commit}, { item }) {
      return new Promise((resolve, reject) => {
        axios.post(ENDPOINT_PATH + `/avatar/chooseAvatar/${item.id}`, item)
            .then((response) => {
                commit(UPDATE_AVATAR, { ...response.data })
                resolve(response)
            })
            .catch((err) => {
                reject(err.response.data)
            })
    })
    },
    logout({ commit }) {
      commit(AUTH_LOGOUT)
    },
    setInitApple({ commit }, value) {
      commit(SET_INIT_APPLE, value);
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    initApple: state => state.initApple,
    authStatus: state => state.status,
    userSession: state => state.user,
    userAvatar: state => state.avatar
  },
  modules: {
  }
})
