import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
  getSubCategories() {
    return axios.get(ENDPOINT_PATH + `/subcategory`);
  },
  show(id) {
    return axios.get(ENDPOINT_PATH + `/subcategory/${id}`);
  },
  store(data) {
    return axios.post(ENDPOINT_PATH + `/subcategory`, data);
  },
  update(data) {
    return axios.put(ENDPOINT_PATH + `/subcategory/${data.id}`, data);
  },
  delete(id) {
    return axios.delete(ENDPOINT_PATH + `/subcategory/${id}`);
  },
};

