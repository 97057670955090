<template>
    <div class="ma-lg-6 ma-0">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"/>
        <v-card>
            <Breadcrumbs :items="breadcrumbs"/>
            <v-form v-model="valid" v-on:submit="submit" class="px-3 py-2">
                <v-row>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Categoría</h4>
                        <v-select
                            v-model="select_category"
                            :items="items"
                            :rules="rules.category"
                            item-title="name"
                            item-value="id"
                            label="Categoría"
                            required
                            outlined
                            solo
                        ></v-select>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Nombre</h4>
                        <v-text-field
                                v-model="subcategoria.name"
                                :rules="rules.name"
                                label="Nombre"
                                required
                                outlined
                                solo
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="pb-2">
                    <v-col cols="12" md="12" class="d-flex">
                        <v-btn :disabled="!valid" class="mx-auto btn-blue" @click="submit">
                            Agregar sub categoría
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import categoryService from "@/composables/categories";
import subCategoryService from "@/composables/subcategory";
import { toast } from 'vue3-toastify';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name: 'CategoryCreated',
    components: {
        Breadcrumbs,
        Loading
    },
    data: () => ({
        isLoading: false,
        breadcrumbs: [
            {text: 'Sub categorías', disabled: false, to: '/subcategorias',},
        ],
        select_category: {name: 'Seleciona una categoria', id: null},
        subcategoria: {
            category_id: '',
            name: '',
            created_by: null,
            updated_by: null,
        },
        valid: false,
        items: [],
        rules: {
            category: [
                v => !!v || 'Categoría es requerido',
            ],
            name: [
                v => !!v || 'Nombre es requerido',
            ]
        },
    }),
    computed: {
        userSession: function () {
            return this.$store.getters.userSession
        },
    },
    mounted() {
        this.categories();
    },
    methods: {
        submit() {
            this.isLoading = true;
            this.subcategoria.category_id = this.select_category;
            this.subcategoria.created_by = this.userSession.id;
            subCategoryService.store(this.subcategoria).then(response => {
                this.isLoading = false;
                this.$router.push({path: '/subcategorias'})
            }, error => {
                toast.error(JSON.stringify(error.response.data.errors), {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            });
        },
        categories() {
            categoryService.getCategories().then(response => {
                this.items = response.data.data;
            }, error => {
                console.log(error);
            })
        }
    }
}
</script>