<template>
    <video ref="courseVideo" class="video-js" v-on:pause="pauseVideo()"></video>
</template>

<script>
import { mapGetters } from "vuex";
import videojs from 'video.js';

export default {
    name: 'Video Archive',
    props: ['courseInfo', 'initCourse', 'initLesson', 'courseVideos', 'lessonVideos', 'positionVideoId', 'selectLesson'],
    data: function() {
        return {
            player: null,
            options: {
                autoplay: false,
                controls: true,
                loop: false,
            },
            init_course: this.initCourse,
            init_lesson: this.initLesson,
            course_info: this.courseInfo,
            course_videos: this.courseVideos,
            lesson_videos: this.lessonVideos,
            position_video_id: this.positionVideoId,
            select_lesson: this.selectLesson,
        }
    },
    computed:{
        ...mapGetters(["userSession"]),
    },
    mounted() {
       this.createVideo();
    },
    methods: {
        createVideo() {
            let isExist = 0;
            //Verificamos si existen videos reproducidos de cursos
            if (this.init_course) {
                if (this.course_videos.length > 0) {
                    this.course_videos.forEach(item => {
                        //Validamos si el video pertenece al curso seleccionado
                        if (item.course_id == this.course_info.id) {
                            this.player = videojs(this.$refs.courseVideo, this.options);
                            //Se inserta el video al reproductor
                            this.player.src({type: 'video/mp4', src: this.course_info.video});
                            //Validamos si en que minuto se pauso el video
                            if (item.video === this.course_info.video) {
                                this.player.currentTime(item.video_paused ?? 0);
                            } else {
                                this.player.currentTime(0);
                            }
                            isExist++
                        }
                    })
                    if (isExist == 0) {
                        this.player = videojs(this.$refs.courseVideo, this.options);
                        this.player.src({type: 'video/mp4', src: this.course_info.video});
                    }
                } else {
                    this.player = videojs(this.$refs.courseVideo, this.options);
                    this.player.src({type: 'video/mp4', src: this.course_info.video});
                }
            }

            if (this.init_lesson) {
                if (this.lesson_videos.length > 0) {
                    this.lesson_videos.forEach(item => {
                        //Validamos si el video pertenece al curso seleccionado
                        if (item.lesson_id == this.select_lesson.id) {
                            this.player = videojs(this.$refs.courseVideo, this.options);
                            //Se inserta el video al reproductor
                            this.player.src({type: 'video/mp4', src: this.select_lesson.video});
                            //Validamos si en que minuto se pauso el video
                            if (item.video === this.select_lesson.video) {
                                this.player.currentTime(item.video_paused ?? 0);
                            } else {
                                this.player.currentTime(0);
                            }
                            isExist++
                        }
                    })
                    if (isExist == 0) {
                        this.player = videojs(this.$refs.courseVideo, this.options);
                        this.player.src({type: 'video/mp4', src: this.select_lesson.video});
                    }
                } else {
                    this.player = videojs(this.$refs.courseVideo, this.options);
                    this.player.src({type: 'video/mp4', src: this.select_lesson.video});
                }
            }
        },
        pauseVideo() {
            let currentTime = Math.round(this.player.cache_.currentTime);
            let duration = Math.round(this.player.cache_.duration);
            let info = {};

            //Validamos si se encuentra en el tab 'Descripción'
            if (this.init_course) {
                if (this.course_videos.length == 0 && this.lesson_videos.length == 0) {
                    info = {
                        course_id: this.course_info.id,
                        name: this.course_info.name,
                        video: this.course_info.video,
                        video_vimeo: null,
                        video_type: this.course_info.video_type,
                        video_duration: this.player.cache_.duration,
                        video_paused: this.player.cache_.currentTime,
                        first_play: (new Date()).getTime(),
                        last_pause: (new Date()).getTime(),
                        finish: (currentTime <= duration && currentTime >= duration - 2) ? true : false,
                        finish_play: (currentTime <= duration && currentTime >= duration - 2) ? (new Date()).getTime() : null,
                    }
                    this.course_videos.push(info);
                    this.submit();
                } else {
                    let isExist = this.course_videos.find(item => item.course_id == this.course_info.id);
                    if (isExist) {
                        isExist.name = this.course_info.name,
                        isExist.video = this.course_info.video,
                        isExist.video_vimeo = null,
                        isExist.video_type = this.course_info.video_type,
                        isExist.video_paused = this.player.cache_.currentTime;
                        isExist.last_pause = (new Date()).getTime();
                        //Validamos si se finalizo la reproducción del video
                        if ((currentTime <= duration && currentTime >= duration - 2) && !isExist.finish) {
                            isExist.finish = true;
                            isExist.finish_play = (new Date()).getTime();
                        }
                        this.submit();
                    } else {
                        info = {
                            course_id: this.course_info.id,
                            name: this.course_info.name,
                            video: this.course_info.video,
                            video_vimeo: null,
                            video_type: this.course_info.video_type,
                            video_duration: this.player.cache_.duration,
                            video_paused: this.player.cache_.currentTime,
                            first_play: (new Date()).getTime(),
                            last_pause: (new Date()).getTime(),
                            finish: (currentTime <= duration && currentTime >= duration - 2) ? true : false,
                            finish_play: (currentTime <= duration && currentTime >= duration - 2) ? (new Date()).getTime() : null,
                        }
                        this.course_videos.push(info);
                        this.submit();
                    }
                }
            }

            //Validamos si se encuentra en el tab 'Contenido'
            if (this.init_lesson) {
                if (this.course_videos.length == 0 && this.lesson_videos.length == 0) {
                    info = {
                        lesson_id: this.select_lesson.id,
                        name: this.select_lesson.name,
                        video: this.select_lesson.video,
                        video_vimeo: null,
                        video_type: this.select_lesson.video_type,
                        video_duration: this.player.cache_.duration,
                        video_paused: this.player.cache_.currentTime,
                        first_play: (new Date()).getTime(),
                        last_pause: (new Date()).getTime(),
                        finish: (currentTime <= duration && currentTime >= duration - 2) ? true : false,
                        finish_play: (currentTime <= duration && currentTime >= duration - 2) ? (new Date()).getTime() : null,
                    }
                    this.lesson_videos.push(info);
                    this.submit();
                } else {
                    let isExist = this.lesson_videos.find(item => item.lesson_id == this.select_lesson.id);
                    if (isExist) {
                        isExist.name = this.select_lesson.name,
                        isExist.video = this.select_lesson.video,
                        isExist.video_vimeo = null,
                        isExist.video_type = this.select_lesson.video_type,
                        isExist.video_paused = this.player.cache_.currentTime;
                        isExist.last_pause = (new Date()).getTime();
                        //Validamos si se finalizo la reproducción del video
                        if ((currentTime <= duration && currentTime >= duration - 2) && !isExist.finish) {
                            isExist.finish = true;
                            isExist.finish_play = (new Date()).getTime();
                        }
                        this.submit();
                    } else {
                        info = {
                            lesson_id: this.select_lesson.id,
                            name: this.select_lesson.name,
                            video: this.select_lesson.video,
                            video_vimeo: null,
                            video_type: this.select_lesson.video_type,
                            video_duration: this.player.cache_.duration,
                            video_paused: this.player.cache_.currentTime,
                            first_play: (new Date()).getTime(),
                            last_pause: (new Date()).getTime(),
                            finish: (currentTime <= duration && currentTime >= duration - 2) ? true : false,
                            finish_play: (currentTime <= duration && currentTime >= duration - 2) ? (new Date()).getTime() : null,
                        }
                        this.lesson_videos.push(info);
                        this.submit();
                    }
                }
            }
        },
        submit() {
            let data = Object.assign({});
            data.user_id = this.userSession.id;
            data.course_videos = this.course_videos ? JSON.stringify(this.course_videos) : null;
            data.lesson_videos = this.lesson_videos ? JSON.stringify(this.lesson_videos) : null;
            this.$emit('setData', data);
        },
    },
}
</script>