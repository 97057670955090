<template>
    <v-row v-if="!isLoggedIn" class="section__login" justify="center" style="margin: 0;">
        <v-col cols="6" md="7" class="v-col-md-7 v-col-6 d-none d-md-block image__login">
            <!--<v-img alt="" cover src="@/assets/images/login.png" width="100%" height="100%"/>-->
        </v-col>
        <v-col cols="12" md="5" sm="8" align="center" class="position-relative">
            <div class="content_info__login px-lg-14 px-md-10 px-4 pt-md-0 pt-8">
                <v-img alt="" class="align-center mb-12 d-md-none d-flex" container src="@/assets/images/Brand.png"
                       width="80"/>

                <v-form action @submit.prevent="login" class="form__login">
                    <div class="text__login font-main-bold text-md-left text-center mb-8">INGRESAR A VIDA LEGACY</div>

                    <v-text-field label="Usuario" required id="user_identity" v-model="user_identity" density="compact"
                                  variant="outlined"
                                  color="E7E7EA">
                    </v-text-field>
                    <v-text-field id="password" v-model="password"  :type="type_input" label="Contraseña" variant="outlined"
                                  density="compact"
                                  :append-inner-icon="icon_password"
                                  @click:append-inner="clickEye"
                                  color="E7E7EA">
                    </v-text-field>
                    <div class="text-caption text-left mb-4">
                        <a href="/reset-password" class="text-medium-emphasis">
                            Olvidé mi contraseña
                        </a>
                    </div>
                    <div class="text-caption text-left mb-4">
                        Al ingresar usted acepta nuestros: 
                        <router-link :to="{ name: 'Terms' }" target="_blank">Términos y condiciones</router-link>
                    </div>
                    <p v-if="error" class="error">
                        Has introducido mal el usuario o la contraseña.
                    </p>
                    <v-btn color="#00FFFF" class="font-weight-bold text-capitalize" rounded="xl" size="x-large" block
                           elevation="0" value="login" type="submit" :disabled="loading">
                        <v-progress-circular v-if="loadingSpinner" indeterminate class="mr-2"></v-progress-circular> Iniciar sesión
                    </v-btn>
                    <v-btn color="#00FFFF" class="font-weight-bold text-capitalize mt-2" rounded="xl" size="x-large" block
                           elevation="0" value="login" type="button" :disabled="loadingGuest" @click="loginGuest()">
                        <v-progress-circular v-if="loadingGuestSpinner" indeterminate class="mr-2"></v-progress-circular> Invitado
                    </v-btn>
                    <div class="mt-6">También puedes acceder con</div>
                    <div class="content_buttons__login">
                        <v-btn @click="openModal()" class="text_button__google font-weight-bold text-capitalize px-lg-10 px-6" rounded="xl"
                            elevation="0"
                            value="login" type="button" variant="outlined">
                            <v-img alt="" class="mr-2" container src="@/assets/images/google.png" width="20" height="20"/>
                            Google
                        </v-btn>
                        <v-btn class="text_button__apple font-weight-bold text-capitalize  px-lg-10 px-6" rounded="xl"
                               elevation="0" @click="loginApple()"
                               value="login" type="button" variant="outlined">
                            <v-img alt="" class="mr-2" container src="@/assets/images/apple.png" width="20" height="20"/>
                            Apple ID
                        </v-btn>
                    </div>
                </v-form>
            </div>
            <template>
                <v-row justify="center">
                    <v-dialog
                    v-model="enableModal"
                    persistent
                    width="450"
                    >
                    <v-card>
                        <v-card-title class="text-h5">
                            <p>Bienvenid@</p>
                        </v-card-title>
                        <v-card-text class="pt-0">
                            <v-checkbox-btn
                                v-model="enableReference"
                                label="¿Es un usuario nuevo?"
                                class="pe-2">
                            </v-checkbox-btn>
                            <template v-if="enableReference">
                                <v-text-field class="pt-3"
                                    id="reference"
                                    :disabled="!enableReference"
                                    hide-details
                                    v-on:keyup="dataReference($event)"
                                    type="number"
                                    label="Ingrese el ULI de su patrocinador:"
                                    variant="outlined"
                                    density="compact">
                                </v-text-field>
                            </template>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="close"
                                variant="text"
                                @click="enableModal = false, enableReference = false"
                            >
                                Cerrar
                            </v-btn>
                            <v-btn
                                color="info"
                                variant="text"
                                :disabled="validate"
                                @click="loginGoogle()"
                            >
                                Continuar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-row>
            </template>
            <div class="footer__login">
                <div class="content__footer">
                    <label>© LEGACY</label>
                    <label>2023</label>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<style scoped>
.section__login {
    height: 100%;
}
.content_info__login{
    width: 100%;
    position: absolute;
    top: 42%;
    transform: translateY(-50%);
}
.content_buttons__login{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    padding-top: 15px;
    align-items: center;
}
.text_button__google,
.text_button__apple{
    font-size: 12px !important;
    color: #000000 !important;
    border: 1px solid #CFD0D4;
    height: 44px;
}
.text_button__google .v-btn__content,
.text_button__apple .v-btn__content{
    width: 88px !important;
}
.text-h3 {
  font-family: "BebasNeue-Regular" !important;
}
.error {
    margin: 1rem 0 0;
    color: #ff4a96;
    font-family: "BebasNeue-Regular" !important;

}
.text__login {
    font-size: 68px;
}
.form__login {
    width: 70%;
}

.footer__login{
    width: 58%;
    bottom: 40px;
    position: absolute;
    left: 52%;
    transform: translateX(-50%);
}
.footer__login .content__footer{
    display: flex;
    justify-content: space-between;
}
.footer__login .content__footer label{
    font-size: 12px;
    font-weight: 400;
    color: #868994;
}
@media (max-width: 1520px) {
    .form__login {
        width: 100%;
    }
    .footer__login {
        width: 80%;
    }
}
@media (max-width: 1150px) {
    .text__login{
        font-size: 35px;
    }
    .footer__login {
        bottom: 15px;
    }
}
@media (max-width: 959px) {
    .content_info__login{
        top: 0%;
        transform: none;
        position: relative;
    }
}
@media (max-width: 342px) {
    .content_buttons__login{
        justify-content: center;
    }
}
@media (max-height: 640px) {
    .content_info__login {
        position: relative;
        top: 0;
        transform: none;
    }
    .footer__login {
        position: relative;
        left: 0;
        transform: none;
        bottom: 0px;
        padding-top: 40px;
    }
}
</style>

<script>
import { toast } from 'vue3-toastify';
import { googleAuthCodeLogin } from "vue3-google-login"
import axios from 'axios';

export default {
    name: "Login",
    data: () => ({
        loadingGuest: false,
        loadingGuestSpinner: false,
        loading: false,
        loadingSpinner: false,
        name: null,
        user_identity: null,
        password: "",
        error: false,
        icon_password: 'mdi-eye-off-outline',
        type_input: 'password',
        change_type: false,
        enableModal: false,
        reference: null,
        enableReference: false,
        validateNext: true,
        authMethod: null,
    }),
    computed: {
        isLoggedIn : function(){
        return this.$store.getters.isLoggedIn
        },
        validate: function(){
            if (this.enableReference) {
                return this.reference ? false : true;
            } else {
                return false;
            }
        }
    },
    mounted() {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const code = params.get('code');
        const token = params.get('id_token');
        if (this.$store.getters.initApple) {
            if (code) {
                this.handleAuthentication(code, null);
            } else if (token){
                this.handleAuthentication(null, token);
            } else {
                this.$store.dispatch('setInitApple', false);
                this.loading = false;
                this.loadingSpinner = false;
                this.loadingGuest = false;
                toast.error('No se recibió una respuesta de Apple', {autoClose: 2000});
            }
        }        
    },
    methods: {
        handleAuthentication(codeUser, tokenUser) {
            this.loading = true;
            this.loadingSpinner = true;
            this.loadingGuest = true;
            this.$store.dispatch("loginApple", {data: {code: codeUser, token: tokenUser}}).then(response => {
                this.$store.dispatch('setInitApple', false);
                this.loading = false;
                this.loadingSpinner = false;
                this.loadingGuest = false;
                this.$router.push("/inicio");
            }).catch((err) => {
                this.$store.dispatch('setInitApple', false);
                this.loading = false;
                this.loadingSpinner = false;
                this.loadingGuest = false;
                toast.error(err.message, {autoClose: 2000});
            });
        },
        loginGuest() {
            this.loading = true;
            this.loadingGuest = true;
            this.loadingGuestSpinner = true;
            this.$store.dispatch("login", {user:{ user_identity: 'guest', password: '' }}).then(response => {
                this.loading = false;
                this.loadingGuest = false;
                this.loadingGuestSpinner = false;
                if (response.data.user.role == "admin") {
                    this.$router.push("/");
                } else {
                    this.$router.push("/inicio");
                }
            }).catch((err) => {
                this.loading = false;
                this.loadingGuest = false;
                this.loadingGuestSpinner = false;
                toast.error(err.message, {autoClose: 2000});
                console.log(err);
            });
        },
        loginGoogle() {
            this.enableModal = false;
            this.loading = true;
            this.loadingSpinner = true;
            googleAuthCodeLogin().then((res) => {
                this.$store.dispatch("loginGoogle", {user:{ code: res.code, reference: this.reference }}).then(response => {
                    this.loading = false;
                    if (response.data.user.role == "admin") {
                        this.$router.push("/");
                    } else {
                        this.$router.push("/inicio");
                    }
                }).catch((err) => {
                    this.enableReference = false;
                    this.reference = null;
                    this.loading = false;
                    this.loadingSpinner = false;
                    toast.error(err.message, {autoClose: 2000});
                    console.log(err);
                });
            }).catch((err) => {
                this.enableReference = false;
                this.reference = null;
                this.loading = false;
                this.loadingSpinner = false;
            });
        },
        loginApple() {
            this.$store.dispatch('setInitApple', true);
            const client_id = process.env.VUE_APP_CLIENT_ID;
            const redirect_uri = process.env.VUE_APP_REDIRECT_URI;
            const scope = 'name email';
            const state = (new Date).toString();
            const responseType = 'code';
            const responseMode = 'form_post';
            const appleLoginURL = `https://appleid.apple.com/auth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}&response_type=${responseType}&response_mode=${responseMode}`;
            window.location.href = appleLoginURL;
        },
        login() {
            this.loading = true;
            this.loadingSpinner = true;
            this.loadingGuest = true;
            this.$store.dispatch("login", {user:{ user_identity: this.user_identity, password: this.password }}).then(response => {
                this.loading = false;
                this.loadingSpinner = false;
                this.loadingGuest = false;
                if (response.data.user.role == "admin") {
                    this.$router.push("/");
                } else {
                    this.$router.push("/inicio");
                }
            }).catch((err) => {
                this.loading = false;
                this.loadingSpinner = false;
                this.loadingGuest = false;
                toast.error(err.message, {autoClose: 2000});
                console.log(err);
            });
        },
        openModal() {
            this.enableModal = true;
        },
        dataReference(e) {
            this.reference = e.target.value ?? null;
        },
        clickEye() {
            this.change_type = !this.change_type;
            if (this.change_type) {
                this.type_input = 'text';
                this.icon_password = 'mdi-eye-outline';
            } else {
                this.type_input = 'password';
                this.icon_password = 'mdi-eye-off-outline';
            }
        }
    },
};
</script>
