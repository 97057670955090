<template>
  <v-app :class="{'change-backgroud': !isLoggedIn}">
    <Sidebar v-if="isLoggedIn"/>
    <v-main id="mainContent" :class="{'more_padding': isLoggedIn}">
      <router-view></router-view>
    </v-main>
    <!--
    <v-footer app>
    </v-footer>
    -->
  </v-app>
</template>

<style>
@font-face {
  font-family: "BebasNeue-Regular";
  src: url(./assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf)
}
.font-main-bold {
  font-family: "BebasNeue-Regular" !important;
    font-weight: 400;
}
.text-h3 {
  font-family: "BebasNeue-Regular" !important;
}
.v-navigation-drawer__content {
    background: transparent;
    border: 0;
}
.v-navigation-drawer--active .v-list {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgb(197 197 197 / 16%);
    border-radius: 32px;
}
.v-navigation-drawer--temporary.v-navigation-drawer--active .v-list{
  border-radius: 0px;
  box-shadow: none;
}
.v-list-item--nav .v-list-item-title {
  font-weight: 700;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.v-list-item-title {
  color: #000;
  text-align: left !important;
}

.v-list {
  background: #FFFFFF;
}
</style>
<script>

import Sidebar from "@/components/layout/Sidebar";

export default {
  name: 'App',
  components: {
    Sidebar
  },
  computed: {
    isLoggedIn : function(){
      return this.$store.getters.isLoggedIn
    },
  },

}

</script>