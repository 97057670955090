<template>
  <!--<div class="next_release">
    <div class="content-info">
      <h1>¡PRONTO!</h1>
      <div class="spinner">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <div class="bar4"></div>
        <div class="bar5"></div>
        <div class="bar6"></div>
        <div class="bar7"></div>
        <div class="bar8"></div>
      </div>
      <div class="text">
        <p>ESTAMOS PREPARANDO UNA<br/>NUEVA EXPERIENCIA PARA TI</p>
      </div>
      <div class="botton_logout">
        <v-btn color="#555A6A" class="font-weight-bold text-capitalize" rounded="xl" size="x-large" block
          elevation="0" value="login" type="button" @click="logout()">
          Cerrar sesión
        </v-btn>
      </div>
    </div>
  </div>-->
  <v-container>
    <v-row class="mt-0">
      <v-col cols="12" md="12" class="d-sm-none d-block ">
        <div class="font-main-bold" style="font-size: 20px;">DASHBOARD</div>
      </v-col>
      <!-- Vista responsive inicio -->
      <v-col cols="12" class="d-sm-none d-block">
        <v-tabs class="tabs-range-user" v-model="tab" bg-color="transparent" align-tabs="center" >
          <v-tab value="one" class="font-weight-bold text-capitalize">Rango actual</v-tab>
          <v-tab value="two" class="font-weight-bold text-capitalize">Próximo rango</v-tab>
        </v-tabs>
      </v-col>
      

      <v-window v-if="userSession.data_dashboard" v-model="tab" class="d-sm-none d-block" style="width: 100%;">
        <v-window-item value="one">
          <v-col cols="12" md="8" sm="12">

            <v-row class="mt-mb-3">
              <v-col cols="6" md="12">
                <v-img alt="" class="align-center justify-space-between flex-sm-row-reverse" cover height="184"
                  src="@/assets/images/fondo_dashboard_mobile.png" style="border-radius: 16px;">
                  <v-row align="center" justify="space-between" no-gutters class="flex-sm-row-reverse">
                    <v-col cols="12" md="8" class="order-1 pt-4">
                      <h1 class="font-main-bold mb-0 title-ejecutivo" style="color: #868994; font-size: 14px;">
                        {{ current_range?.value }}
                      </h1>
                    </v-col>
                    <v-col cols="12" md="4" align="center">
                      <ImagesRange :data="current_range"/>
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_personales !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-img alt="" class="" container src="@/assets/images/user.png" max-width="24" />
                      <v-spacer></v-spacer>
                      <!--<div>+10%</div>-->
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_personales }}</div>
                    <div class="text-subtext-box">Puntos personales</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_grupales !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-icon>mdi-account-multiple-outline</v-icon>
                      <v-spacer></v-spacer>
                      <!--<div>+10%</div>-->
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_grupales }}</div>
                    <div class="text-subtext-box">Puntos grupales</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_cliente_preferente !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-img alt="" class="" container src="@/assets/images/diversity.png" max-width="24" />
                      <v-spacer></v-spacer>
                      <!--<div>+10%</div>-->
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">
                      {{ userSession.data_dashboard.puntos_cliente_preferente }}
                    </div>
                    <div class="text-subtext-box">
                        Consumidores preferentes
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_frontales !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-icon>mdi-account-group-outline</v-icon>
                      <v-spacer></v-spacer>
                      <!--<div>+10%</div>-->
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_frontales }}</div>
                    <div class="text-subtext-box">Puntos frontales</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.bonificaciones !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-icon>mdi-currency-usd</v-icon>
                      <v-spacer></v-spacer>
                      <!--<div>+10%</div>-->
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">S/ {{ userSession.data_dashboard.bonificaciones }}</div>
                    <div class="text-subtext-box">Bonificaciones</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.role !== 'guest'">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-img alt="" class="" container src="@/assets/images/edu.png" max-width="24" />
                      <v-spacer></v-spacer>
                      <!--<div>+10%</div>-->
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ my_points?.total_score }}</div>
                    <div class="text-subtext-box">Puntos E-learning</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-window-item>
        <v-window-item value="two">
          <v-col cols="12" md="8" sm="12">

            <v-row class="mt-mb-3">
              <v-col cols="6" md="12">
                <v-img alt="" class="align-center justify-space-between flex-sm-row-reverse" cover height="184"
                  src="@/assets/images/fondo_dashboard_mobile.png" style="border-radius: 16px;">
                  <v-row align="center" justify="space-between" no-gutters class="flex-sm-row-reverse">
                    <v-col cols="12" md="8" class="order-1 pt-4">
                      <h1 class="font-main-bold mb-0 title-ejecutivo" style="color: #868994; font-size: 14px;">
                        {{ next_range?.value }}
                      </h1>
                    </v-col>
                    <v-col cols="12" md="4" align="center">
                      <ImagesRange :data="next_range"/>
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_personales_requeridos !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-img alt="" class="" container src="@/assets/images/user.png"/>
                      <v-spacer></v-spacer>
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_personales_requeridos }}</div>
                    <div class="text-subtext-box">Puntos Personales Requeridos</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_personales_acumulados !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-img alt="" class="" container src="@/assets/images/user.png"/>
                      <v-spacer></v-spacer>
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_personales_acumulados }}</div>
                    <div class="text-subtext-box">Puntos Personales Acumulados</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_personales_faltantes !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-img alt="" class="" container src="@/assets/images/user.png"/>
                      <v-spacer></v-spacer>
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_personales_faltantes }}</div>
                    <div class="text-subtext-box">Puntos Personales Faltantes</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_grupales_requeridos !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-icon>mdi-account-multiple-outline</v-icon>
                      <v-spacer></v-spacer>
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_grupales_requeridos }}</div>
                    <div class="text-subtext-box">Puntos Grupales Requeridos</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_grupales_acumulados !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-icon>mdi-account-multiple-outline</v-icon>
                      <v-spacer></v-spacer>
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_grupales_acumulados }}</div>
                    <div class="text-subtext-box">Puntos Grupales Acumulados</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_grupales_faltantes !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-icon>mdi-account-multiple-outline</v-icon>
                      <v-spacer></v-spacer>
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_grupales_faltantes }}</div>
                    <div class="text-subtext-box">Puntos Grupales Faltantes</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_frontales_requeridos !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-icon>mdi-account-group-outline</v-icon>
                      <v-spacer></v-spacer>
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_frontales_requeridos }}</div>
                    <div class="text-subtext-box">Frontales Requeridos</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_frontales_acumulados !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-icon>mdi-account-group-outline</v-icon>
                      <v-spacer></v-spacer>
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_frontales_acumulados }}</div>
                    <div class="text-subtext-box">Frontales Acumulados</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_frontales_faltantes !== null">
                <v-card min-height="184" class="content-info-card">
                  <v-card-item class="pb-12">
                    <div class="d-flex flex-row space-between align-center">
                      <v-icon>mdi-account-group-outline</v-icon>
                      <v-spacer></v-spacer>
                    </div>

                  </v-card-item>
                  <v-card-text justify="end">
                    <div class="text-number-box">{{ userSession.data_dashboard.puntos_frontales_faltantes }}</div>
                    <div class="text-subtext-box">Frontales Faltantes</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" v-if="userSession.data_dashboard.lideres_con_nivel !== null">
                <div class="text-leader-level pl-2">Lideres con Nivel: {{ userSession.data_dashboard.lideres_con_nivel }}</div>
                <v-row class="pt-4">
                  <v-col cols="6" md="4" v-if="userSession.data_dashboard.lideres_requeridos !== null">
                    <v-card min-height="184" class="content-info-card">
                      <v-card-item class="pb-12">
                        <div class="d-flex flex-row space-between align-center">
                          <v-icon>mdi-account-multiple-outline</v-icon>
                          <v-spacer></v-spacer>
                        </div>

                      </v-card-item>
                      <v-card-text justify="end">
                        <div class="text-number-box">{{ userSession.data_dashboard.lideres_requeridos }}</div>
                        <div class="text-subtext-box">Lideres Requeridos</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6" md="4" v-if="userSession.data_dashboard.lideres_acumulados !== null">
                    <v-card min-height="184" class="content-info-card">
                      <v-card-item class="pb-12">
                        <div class="d-flex flex-row space-between align-center">
                          <v-icon>mdi-account-multiple-outline</v-icon>
                          <v-spacer></v-spacer>
                        </div>

                      </v-card-item>
                      <v-card-text justify="end">
                        <div class="text-number-box">{{ userSession.data_dashboard.lideres_acumulados }}</div>
                        <div class="text-subtext-box">Lideres Acumulados</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6" md="4" v-if="userSession.data_dashboard.lideres_faltantes !== null">
                    <v-card min-height="184" class="content-info-card">
                      <v-card-item class="pb-12">
                        <div class="d-flex flex-row space-between align-center">
                          <v-icon>mdi-account-multiple-outline</v-icon>
                          <v-spacer></v-spacer>
                        </div>

                      </v-card-item>
                      <v-card-text justify="end">
                        <div class="text-number-box">{{ userSession.data_dashboard.lideres_faltantes }}</div>
                        <div class="text-subtext-box">Lideres Faltantes</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-window-item>
      </v-window>
      <!-- Vista responsive fin -->
      <v-col cols="12" sm="12" md="7" lg="7" class="d-none d-sm-block ">
        <v-row v-if="!userSession.data_dashboard">
          <v-col cols="6" md="12">
            <v-img alt="" class="content-image-range d-none d-md-flex align-center justify-space-between" cover min-width="100%"
              src="@/assets/images/fondo.png" width="80">
              <v-row align="center" justify="space-between" no-gutters>
                <v-col cols="12" md="8">
                  <h1 class="font-main-bold mb-0 title-ejecutivo">
                    {{ current_range?.value }}
                  </h1>
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <ImagesRange :data="current_range"/>
                </v-col>
              </v-row>
            </v-img>
            <v-img alt="" class="content-image-range d-flex d-md-none align-center justify-space-between" cover
              src="@/assets/images/fondo.png" width="100%" height="100%" style="border-radius: 16px;">
              <v-row align="center" justify="space-between" no-gutters>
                <v-col cols="12" md="8" class="pt-sm-3 order-sm-1">
                  <h1 class="font-main-bold mb-0 title-ejecutivo">
                    {{ current_range?.value }}
                  </h1>
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <ImagesRange :data="current_range"/>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
        <v-row v-if="userSession.data_dashboard" class="">
          
          <v-col cols="6" md="12">
            <v-img alt="" class="content-image-range d-none d-md-flex align-center justify-space-between" cover min-width="100%"
              src="@/assets/images/fondo.png" width="80">
              <v-row align="center" justify="space-between" no-gutters>
                <v-col cols="12" md="8">
                  <h1 class="font-main-bold mb-0 title-ejecutivo">
                    {{ current_range?.value }}
                  </h1>
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <ImagesRange :data="current_range"/>
                </v-col>
              </v-row>
            </v-img>
            <v-img alt="" class="content-image-range d-flex d-md-none align-center justify-space-between" cover
              src="@/assets/images/fondo.png" width="100%" height="100%" style="border-radius: 16px;">
              <v-row align="center" justify="space-between" no-gutters>
                <v-col cols="12" md="8" class="pt-sm-3 order-sm-1">
                  <h1 class="font-main-bold mb-0 title-ejecutivo">
                    {{ current_range?.value }}
                  </h1>
                </v-col>
                <v-col cols="12" md="4" align="center">
                  <ImagesRange :data="current_range"/>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
          <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_personales !== null">
            <v-card min-height="184" class="content-info-card">
              <v-card-item class="pb-12">
                <div class="d-flex flex-row space-between align-center">
                  <v-img alt="" class="" container src="@/assets/images/user.png"/>
                  <v-spacer></v-spacer>
                  <!--<div>+10%</div>-->
                </div>

              </v-card-item>
              <v-card-text justify="end">
                <div class="text-number-box">{{ userSession.data_dashboard.puntos_personales }}</div>
                <div class="text-subtext-box">Puntos personales</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_grupales !== null">
            <v-card min-height="184" class="content-info-card">
              <v-card-item class="pb-12">
                <div class="d-flex flex-row space-between align-center">
                  <v-icon>mdi-account-multiple-outline</v-icon>
                  <v-spacer></v-spacer>
                  <!--<div>+10%</div>-->
                </div>

              </v-card-item>
              <v-card-text justify="end">
                <div class="text-number-box">{{ userSession.data_dashboard.puntos_grupales }}</div>
                <div class="text-subtext-box">Puntos grupales</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_cliente_preferente !== null">
            <v-card min-height="184" class="content-info-card">
              <v-card-item class="pb-12">
                <div class="d-flex flex-row space-between align-center">
                  <v-img alt="" class="" container src="@/assets/images/diversity.png"/>
                  <v-spacer></v-spacer>
                  <!--<div>+10%</div>-->
                </div>

              </v-card-item>
              <v-card-text justify="end">
                <div class="text-number-box">{{ userSession.data_dashboard.puntos_cliente_preferente }}</div>
                <div class="text-subtext-box">Consumidores
                  preferentes</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" md="4" v-if="userSession.data_dashboard.puntos_frontales !== null">
            <v-card min-height="184" class="content-info-card">
              <v-card-item class="pb-12">
                <div class="d-flex flex-row space-between align-center">
                  <v-icon>mdi-account-group-outline</v-icon>
                  <v-spacer></v-spacer>
                  <!--<div>+10%</div>-->
                </div>

              </v-card-item>
              <v-card-text justify="end">
                <div class="text-number-box">{{ userSession.data_dashboard.puntos_frontales }}</div>
                <div class="text-subtext-box">Puntos frontales</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" md="4" v-if="userSession.data_dashboard.bonificaciones !== null">
            <v-card min-height="184" class="content-info-card">
              <v-card-item class="pb-12">
                <div class="d-flex flex-row space-between align-center">
                  <v-icon>mdi-currency-usd</v-icon>
                  <v-spacer></v-spacer>
                  <!--<div>+10%</div>-->
                </div>

              </v-card-item>
              <v-card-text justify="end">
                <div class="text-number-box">S/ {{ userSession.data_dashboard.bonificaciones }}</div>
                <div class="text-subtext-box">Bonificaciones</div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" md="4" v-if="userSession.role !== 'guest'">
            <v-card min-height="184" class="content-info-card">
              <v-card-item class="pb-12">
                <div class="d-flex flex-row space-between align-center">
                  <v-img alt="" class="" container src="@/assets/images/edu.png"/>
                  <v-spacer></v-spacer>
                  <!--<div>+10%</div>-->
                </div>

              </v-card-item>
              <v-card-text justify="end">
                <div class="text-number-box">{{ my_points?.total_score ?? 0 }}</div>
                <div class="text-subtext-box">Puntos E-learning</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="1" lg="1">
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <TopUsers/>
        <div class="mt-8">
          <template v-if="userSession.role == 'student'">
            <div v-if="!loading_video && validate_position_video.position_video.length > 0">
              <Video :videoInfo="users" :userData="userSession"></Video>
            </div>
          </template>
          <v-skeleton-loader v-if="loading_video" :loading="true" type="heading, image">
          </v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

.next_release{
  font-family: "BebasNeue-Regular";
  position: absolute;
  z-index: 9999;
  background-color: #ffffffed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.next_release .content-info {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  place-content: center;
  place-items: center;
  top: 45%;
  left: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
}
.next_release .content-info h1{
  font-size: 120px;
  font-weight: 500;
  line-height: 100px;
}
div.spinner {
  position: relative;
  width: 250px;
  height: 250px;
}

div.spinner div {
  width: 6%;
  height: 16%;
  background: #000000;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  animation: fade 1s linear infinite;
}
@keyframes fade {
  from {opacity: 1;}
  to {opacity: 0.25;}
}
div.spinner div.bar1 {
  transform:rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}    
div.spinner div.bar2 {
  transform:rotate(45deg) translate(0, -130%); 
  animation-delay: -0.9167s;
}
div.spinner div.bar3 {
  transform:rotate(90deg) translate(0, -130%); 
  animation-delay: -0.833s;
}
div.spinner div.bar4 {
  transform:rotate(135deg) translate(0, -130%); 
  animation-delay: -0.7497s;
}
div.spinner div.bar5 {
  transform:rotate(180deg) translate(0, -130%); 
  animation-delay: -0.667s;
}
div.spinner div.bar6 {
  transform:rotate(225deg) translate(0, -130%); 
  animation-delay: -0.5837s;
}
div.spinner div.bar7 {
  transform:rotate(270deg) translate(0, -130%); 
  animation-delay: -0.5s;
}
div.spinner div.bar8 {
  transform:rotate(315deg) translate(0, -130%); 
  animation-delay: -0.4167s;
}
.content-info .text p{
  font-size: 50px;
  margin: 0;
  line-height: 60px;
}
.content-info .botton_logout {
  color: #ffffff;
  padding-top: 30px;
}
.content-info .botton_logout button{
  padding: 0px 100px;
}

.content-image-range {
  filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.14));
}
.content-info-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(38, 47, 78, 0.12);
  border-radius: 16px;
}
.content-info-card .v-img{
  max-width: 24px;
  max-height: 24px;
}
.content-top-users,
.content-last-video {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.16);
  border-radius: 28px;
}
.content-top-users .info-users {
  padding: 24px 35px;
}
.see-all {
  font-size: 10px;
  font-weight: 700;
  color: #8B8694;
  cursor: pointer;
}
.title-ejecutivo {
  font-size: 40px;
}
.title-top-users{
    text-align: justify;
    font-size: 16px;
    font-weight: 700;
    color: #0D1329;
}
.table-title{
  color: #8B8694;
  font-size: 14px;
}
.name-user {
  font-size: 12px;
  font-weight: 400;
}
.text-number-box {
  text-align: left;
  line-height: 3.125rem;
  font-size: 38px;
  font-weight: 700;
  color: #0D1329;
}
.text-subtext-box {
  font-size: 10px;
  font-weight: 700;
  color: #868994;
  text-align: left;
  width: 70%;
  line-height: 14px;
}
.text-leader-level {
    font-size: 15px;
    font-weight: 700;
    text-align: left;
    color: #0D1329;
    line-height: 16px;
  }
.text-h3 {
  font-family: "BebasNeue-Regular" !important;
}

@media (max-width: 768px) {
  .title-ejecutivo {
    font-size: 30px;
  }
}
@media (max-width: 1450px) {
  .text-number-box {
    font-size: 30px;
  }
}
@media (max-width: 1279px) {
  .text-number-box {
    font-size: 24px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import userService from "@/composables/user";
import TopUsers from "../views/topUsers.vue"
import Video from '../views/video.vue';
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';
import ImagesRange from "../views/dashboard/imagesRange.vue";

export default {
  name: "HomeView",
  components: {
    VSkeletonLoader,
    TopUsers,
    Video,
    ImagesRange
  },
  data: () => ({
    tab: null,
    loading_video: true,
    users: [],
    my_points : null,
    current_range: null,
    next_range: null,
    validate_position_video: null
  }),
  computed: {
    ...mapGetters(["userSession"]),
  },
  mounted () {
    if (this.userSession.role == 'student') {
      this.getUsers();
      this.getMyPoints();
      this.convertData();
    } else {
      this.loading_video = false;
    }
  },
  methods: {
    getUsers() {
      this.loading_video = true;
      this.top_users = [];
      userService.getUsers().then(response => {
          this.users = response.data.data;
          this.validate_position_video = this.users.find(item => item.id == this.userSession.id);
          setTimeout(() => {
              this.loading_video = false;
          }, 1000)
      }).catch(e => {
          this.loading_video = false;
          console.log(e);
      })
    },
    getMyPoints() {
      userService.getMyPoints(this.userSession.id).then(response => {
          this.my_points = response.data.data;
      }).catch(e => {
          console.log(e);
      })
    },
    convertData() {
      let data = this.userSession.data_dashboard;
      if (data) {
        this.current_range = this.userSession.ranges.find(item => item.id == data.rango_red);
        this.next_range = this.userSession.ranges.find(item => item.id == (data.rango_red + 1));
      }
    },
    logout() {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    },
  }

};
</script>
