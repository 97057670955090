<template>
    <v-breadcrumbs :items="items" class="secondary--text">
        <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
        </template>
    </v-breadcrumbs>
</template>
  
<script>
export default {
    props: ['items'],
    name: 'Breadcrumbs',
    components: {

    },
    data: () => ({

    }),
}
</script>
  