<template>
    <div v-if="data?.id == 0">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Inactivo@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Inactivo@4x.png" width="80"></v-img>
    </div>
    <div v-if="data?.id == 1">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Activo@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Activo@4x.png" width="80"></v-img>
    </div>
    <div v-if="data?.id == 2">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Club_Plata@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Club_Plata@4x.png" width="80"></v-img>
    </div>
    <div v-if="data?.id == 3">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Club_Oro@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Club_Oro@4x.png" width="80"></v-img>
    </div>
    <div v-if="data?.id == 4">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Ejecutivo_Jade_4@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Ejecutivo_Jade_4@4x.png" width="80"></v-img>
    </div>
    <div v-if="data?.id == 5">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Ejecutivo_Zafiro_4@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Ejecutivo_Zafiro_4@4x.png" width="80"></v-img>
    </div>
    <div v-if="data?.id == 6">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Ejecutivo_Rubi_2@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Ejecutivo_Rubi_2@4x.png" width="80"></v-img>
    </div>
    <div v-if="data?.id == 7">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Ejecutivo_Esmeralda_2@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Ejecutivo_Esmeralda_2@4x.png" width="80"></v-img>
    </div>
    <div v-if="data?.id == 8">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Diamante_2@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Diamante_2@4x.png" width="80"></v-img>
    </div>
    <div v-if="data?.id == 9">
        <v-img alt="" class="align-center d-none d-lg-block" cover :min-width="width??'140'"
            src="@/assets/images/pines/Doble_Diamante_2@4x.png" width="80"></v-img>
        <v-img alt="" class="align-center d-block d-lg-none" cover :min-width="width??'80'"
            src="@/assets/images/pines/Doble_Diamante_2@4x.png" width="80"></v-img>
    </div>
    
</template>

<script>
export default {
    name: 'Images range',
    props: ['data', 'width'],
    methods: {}
}
</script>