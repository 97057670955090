<template>
    <div class="ma-lg-6 ma-0">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"/>
        <v-card>
            <Breadcrumbs :items="breadcrumbs"/>
            <v-form v-model="valid" v-on:submit="submit" class="px-3 py-2">
                <v-row>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Tipo beneficio</h4>
                        <v-select
                            v-model="select_type_benefit"
                            :items="type_benefit"
                            :rules="rules.type"
                            item-title="name"
                            item-value="id"
                            label="Tipo beneficio"
                            required
                            outlined
                            solo
                        ></v-select>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Descripción</h4>
                        <v-textarea
                            v-model="benefit.description"
                            :rules="rules.description"
                            label="Descripción"
                            rows="2"
                            required
                        ></v-textarea>
                    </v-col>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Imagen</h4>
                        <v-file-input 
                            variant="underlined"
                            :label="'Imagen (recomendado width:'+recomend_width_img+'px, height:'+recomend_height_img+'px)'"
                            accept="image/png, image/jpg, image/jpeg"
                            @change="uploadArchive1($event)">
                        </v-file-input>
                        <v-col v-if="benefit.image" class="position-relative" cols="12" sm="6" md="3">
                            <v-img :src="benefit.image"/>
                        </v-col>
                    </v-col>
                </v-row>

                <v-row class="pb-2">
                    <v-col cols="12" md="12" class="d-flex">
                        <v-btn :disabled="!valid" class="mx-auto btn-blue" @click="submit">
                            Agregar beneficio
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import benefitService from "@/composables/benefits";
import { toast } from 'vue3-toastify';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name: 'BenefitCreated',
    components: {
        Breadcrumbs,
        Loading
    },
    data: () => ({
        isLoading: false,
        breadcrumbs: [
            {text: 'Beneficios', disabled: false, to: '/beneficios',},
        ],
        benefit: {
            description: null,
            image: null,
            type: null,
        },
        recomend_width_img: 370,
        recomend_height_img: 370,
        select_type_benefit: null,
        type_benefit: [
            {name: 'Descuentos', id: 1},
            {name: 'Promociones', id: 2},
        ],
        valid: false,
        rules: {
            type: [
                v => !!v || 'Tipo beneficio es requerido',
            ],
            description: [
                v => !!v || 'Descripción es requerido',
            ]  
        },
    }),
    methods: {
        submit() {
            this.isLoading = true;
            let item = Object.assign({}, this.benefit);
            item.type = this.select_type_benefit;
            if (!item.image) {
                toast.error('Necesita agregar una imagen', {autoClose: 2000});
                this.isLoading = false;
                return;
            }

            benefitService.store(item).then(response => {
                this.isLoading = false;
                this.$router.push({path: '/beneficios'})
            }, error => {
                toast.error(JSON.stringify(error.response.data.errors), {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            });
        },
        uploadArchive1(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('width', this.recomend_width_img);
                formData.append('height', this.recomend_height_img);
                formData.append('file', file);
                formData.append('path', 'beneficios-image');
                
                benefitService.uploadFileImage(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.benefit.image = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        }
    }
}
</script>