<template>
    <v-container>
        <v-row v-if="!info_product" class="pt-3">
            <template v-if="!loading_product">
                <v-col v-for="(item, i) in products" cols="12" sm="6" md="4" lg="4">
                    <div class="content-img" @click="infoProduct(item, i)">
                        <v-img :src="item.image"/>
                        <p class="name-img">{{ item.name }}</p>
                        <div class="background_opacity"></div>
                    </div>
                </v-col>
            </template>
            <template v-if="loading_product">
                <v-col v-for="n in 6" cols="12" sm="6" md="4" lg="4">
                    <v-skeleton-loader class="skeleton_product" :loading="loading_product" type="card">
                    </v-skeleton-loader>
                </v-col>
            </template>
            
        </v-row>
        <div v-for="(item, i) in products">
            <div v-if="info_product && index_product == i" class="content-info-product pt-3 px-5">
                <div class="back_product" @click="info_product = !info_product; loading_info_product = true">
                    <v-icon>mdi-arrow-left</v-icon>
                </div>
                <v-row class="banner-img">
                    <v-col cols="12" sm="6" md="4">
                        <v-skeleton-loader class="skeleton_info_product" :loading="loading_info_product" type="image">
                            <v-img :src="item.image"/>
                        </v-skeleton-loader>
                    </v-col>
                </v-row>
                <h1 class="title_product">{{ item.name }}</h1>
                <v-tabs v-model="tab" class="tab-products">
                    <v-tab value="one"  class="name-tab-product">Resumen</v-tab>
                    <v-tab value="two" class="name-tab-product">Preparación</v-tab>
                    <v-tab value="three" class="name-tab-product">Ficha</v-tab>
                    <v-tab value="four" class="name-tab-product">FAQ</v-tab>
                </v-tabs>
                <v-window v-model="tab">
                    <v-window-item value="one">
                        <section class="pt-4">
                            <template v-if="!loading_info_product">
                                <p class="title_section">Resumen</p>
                                <div v-for="data in JSON.parse(item.resume)" class="info-resumen">
                                    <div class="resumen_text">
                                        <p>{{ data.text }}</p>
                                    </div>
                                    <div v-if="data.image" class="resumen_img">
                                        <v-row class="justify-center my-4">
                                            <v-col cols="12" sm="6" md="4">
                                                <v-img :src="data.image"/>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </template>
                            <template v-if="loading_info_product">
                                <v-skeleton-loader class="skeleton_info_product" :loading="loading_info_product" type="heading">
                                </v-skeleton-loader>
                                <div v-for="n in 2" class="info-resumen">
                                    <v-skeleton-loader class="skeleton_info_product" :loading="loading_info_product" type="paragraph, image">
                                    </v-skeleton-loader>
                                </div>
                            </template>
                        </section>
                    </v-window-item>

                    <v-window-item value="two">
                        <section class="pt-4">
                            <template v-if="!loading_info_product">
                                <p class="title_section">Preparación</p>
                                <div class="preparacion_img">
                                    <v-row class="justify-start my-4">
                                        <v-col cols="12" sm="6" md="3">
                                            <v-img :src="item.preparation_image"/>
                                        </v-col>
                                    </v-row>
                                </div>
                                <p class="title_section">Recuerda</p>
                                <div v-if="JSON.parse(item.remember).length > 0 && JSON.parse(item.remember)[0].text" class="list_tips">
                                    <ul>
                                        <li v-for="data in JSON.parse(item.remember)"><v-icon>mdi-check</v-icon>{{ data.text }}</li>
                                    </ul>
                                </div>
                            </template>
                            <template v-if="loading_info_product">
                                <v-skeleton-loader class="skeleton_info_product" :loading="loading_info_product" type="heading">
                                </v-skeleton-loader>
                                <div class="preparacion_img">
                                    <v-row class="justify-start my-4">
                                        <v-col cols="12" md="3">
                                            <v-skeleton-loader class="skeleton_info_product" :loading="loading_info_product" type="image">
                                            </v-skeleton-loader>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-skeleton-loader class="skeleton_info_product" :loading="loading_info_product" type="heading">
                                </v-skeleton-loader>
                                <div v-for="n in 3" class="list_tips">
                                    <v-skeleton-loader class="skeleton_info_product" :loading="loading_info_product" type="paragraph">
                                    </v-skeleton-loader>
                                </div>
                            </template>
                        </section>
                    </v-window-item>

                    <v-window-item value="three">
                        <section class="pt-4">
                            <template v-if="!loading_info_product">
                                <p class="title_section">Ficha</p>
                                <v-row v-if="item.file" class="ficha_pdf justify-start my-4">
                                    <v-col cols="12" md="12" >
                                        <iframe height="100%" width=100% :src="item.file+'#toolbar=0'"></iframe>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-if="loading_info_product">
                                <v-skeleton-loader class="skeleton_info_product" :loading="loading_info_product" type="heading">
                                </v-skeleton-loader>
                                <v-row class="ficha_pdf justify-start my-4">
                                    <v-col cols="12" md="12">
                                        <v-skeleton-loader class="skeleton_info_product_pdf" :loading="loading_info_product" type="image">
                                        </v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </template>
                        </section>
                    </v-window-item>

                    <v-window-item value="four">
                        <section class="pt-4">
                            <template v-if="!loading_info_product">
                                <p class="title_section">FAQ</p>
                                <v-row v-if="item.file_faq" class="ficha_pdf justify-start my-4">
                                    <v-col cols="12" md="12" >
                                        <iframe height="100%" width=100% :src="item.file_faq+'#toolbar=0'"></iframe>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-if="loading_info_product">
                                <v-skeleton-loader class="skeleton_info_product" :loading="loading_info_product" type="heading">
                                </v-skeleton-loader>
                                <v-row class="ficha_pdf justify-start my-4">
                                    <v-col cols="12" md="12">
                                        <v-skeleton-loader class="skeleton_info_product_pdf" :loading="loading_info_product" type="image">
                                        </v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </template>
                        </section>
                    </v-window-item>
                </v-window>
            </div>
        </div>
    </v-container>
</template>

<style>
.content-img {
    position: relative;
    background: #E6FFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    cursor: pointer;
}
.content-img img {
    border-radius: 16px;
}
.content-img .name-img{
    position: absolute;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    bottom: 12px;
    left: 22px;
    z-index: 10;
}
.content-img .background_opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    bottom: 0;
    background: linear-gradient(180deg, rgba(13, 19, 41, 0) 52%, #0D1329 100%);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}
.back_product {
    position: absolute;
    left: -50px;
    cursor: pointer;
}
.content-info-product {
    position: relative;
    text-align: justify;
}
.content-info-product .banner-img{
    background-color: #ffffff;
    border-radius: 16px;
    justify-content: center;
    padding: 5px 0px;

}
.title_product {
    font-size: 20px;
    font-weight: 700;
    padding: 45px 0px 20px 0px;
}
.tab-products {
    height: max-content;
}
.tab-products .v-slide-group__content {
    gap: 18px;
    flex-wrap: wrap;
    width: 100%;
}
.tab-products .v-slide-group-item--active {
    background: #0D1329;
    border-radius: 34px;
    color: #FFFFFF !important;
}
.tab-products .v-btn.v-btn--density-default {
    height: max-content;
    padding: 6px 42px;
}
.tab-products .name-tab-product{
    text-transform: none;
    border: 1px solid #CFD0D4;
    border-radius: 34px;
    color: #3D4254;
    font-size: 12px;
    font-weight: 400;
}
.title_section {
    font-size: 24px;
    font-weight: 700;
    color: #0D1329;
    padding-bottom: 10px;
}
.list_tips{
    list-style: none;
}
.list_tips li{
    display: flex;
    align-items: center;
    gap: 8px;
}
.list_tips li i{
    font-size: 16px;
}
.ficha_pdf {
    height: 800px;
}
.skeleton_product .v-skeleton-loader__card .v-skeleton-loader__image{
    height: 250px;
}
.skeleton_info_product,
.skeleton_info_product_pdf {
    background-color: transparent;
}
.skeleton_info_product .v-skeleton-loader__heading {
    max-width: 35%;
}
.skeleton_info_product .v-skeleton-loader__heading, 
.skeleton_info_product .v-skeleton-loader__paragraph .v-skeleton-loader__text {
    margin-left: 0;
}
.skeleton_info_product_pdf {
    height: 100%;
}
.skeleton_info_product_pdf .v-skeleton-loader__image {
    height: 100%;
}
</style>

<script>
import productService from '@/composables/products'
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';

export default {
    name: 'Club Products',
    components: {
        VSkeletonLoader
    },
    data: () => ({
        loading_product: true,
        loading_info_product: true,
        count_product: [],
        tab: null,
        index_product: null,
        info_product: false,
        products: [],
    }),
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading_product = true;
            productService.getProducts().then(response => {
                this.products = response.data;
                this.products.forEach(item => {
                    let info = {
                        id: item.id,
                        count: 0
                    };
                    this.count_product.push(info);
                })
                setTimeout(() => {
                    this.loading_product = false;
                }, 1000);
            }, error => {
                console.log(error);
            })
        },
        infoProduct(item, index) {
            this.index_product = index;
            this.info_product = !this.info_product;

            this.count_product.forEach(data => {
                if (data.id == item.id) {
                    data.count++;
                    if (data.count < 2) {
                        setTimeout(() => {
                            this.loading_info_product = false;
                        }, 1000)
                    } else {
                        this.loading_info_product = false;
                    }
                }
            })
        }
    }
}
</script>