import axios from "axios";

const ENDPOINT_PATH = process.env.VUE_APP_BASE_URL;

export default {
    getLessons () {
        return axios.get(ENDPOINT_PATH + `/lessons`);
    },
    show(id) {
        return axios.get(ENDPOINT_PATH + `/lessons/${id}`);
    },
    store(data) {
        return axios.post(ENDPOINT_PATH + `/lessons`, data);
    },
    update(data) {
        return axios.put(ENDPOINT_PATH + `/lessons/${data.id}`, data);
    },
    delete(id) {
        return axios.delete(ENDPOINT_PATH + `/lessons/${id}`);
    },
    uploadFile(formData) {
        return axios.post(ENDPOINT_PATH + `/store-file`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    },
};

