<template>
    <div class="ma-lg-6 ma-0">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"/>
        <v-card>
            <Breadcrumbs :items="breadcrumbs"/>
            <v-form v-model="valid" v-on:submit="submit" class="px-3 py-2">
                <v-row>
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Nombre</h4>
                        <v-text-field
                            v-model="categoria.name"
                            :rules="rules.name"
                            label="Nombre"
                            required
                            outlined
                            solo
                        ></v-text-field>
                    </v-col>
                    <!--<v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Descripción</h4>
                        <v-text-field
                            v-model="categoria.description"
                            :rules="rules.description"
                            label="Descripción"
                            required
                            outlined
                            solo
                        ></v-text-field>
                    </v-col>-->
                    <v-col align="start" cols="12" md="8">
                        <h4 class="mb-3">Imagen</h4>
                        <v-file-input 
                            variant="underlined"
                            :label="'Imagen (recomendado width:'+recomend_width_img+'px, height:'+recomend_height_img+'px)'"
                            accept="image/png, image/jpg, image/jpeg"
                            @change="uploadFile($event)">
                        </v-file-input>
                        <v-col v-if="categoria.image" class="position-relative" cols="12" sm="6" md="4">
                            <v-img cover :src="categoria.image"/>
                        </v-col>
                    </v-col>
                </v-row>

                <v-row class="pb-2">
                    <v-col cols="12" md="12" class="d-flex">
                        <v-btn :disabled="!valid" class="mx-auto btn-blue" @click="submit">
                            Agregar categoría
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/layout/Breadcrumbs";
import categoryService from "@/composables/categories";
import { toast } from 'vue3-toastify';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name: 'CategoryCreated',
    components: {
        Breadcrumbs,
        Loading
    },
    data: () => ({
        isLoading: false,
        breadcrumbs: [
            {text: 'Categorías', disabled: false, to: '/categorias',},
        ],
        valid: false,
        categoria: {
            name: null,
            description: null,
            image: null,
            created_by: null,
            updated_by: null,
        },
        recomend_width_img: 320,
        recomend_height_img: 420,
        rules: {
            name: [
                v => !!v || 'Nombre es requerido',
            ],
        },
    }),
    computed: {
        userSession: function () {
            return this.$store.getters.userSession;
        },
    },
    methods: {
        submit() {
            this.isLoading = true;
            this.categoria.created_by = this.userSession.id;
            categoryService.store(this.categoria).then(response => {
                this.isLoading = false;
                this.$router.push({path: '/categorias'});
            }, error => {
                toast.error(JSON.stringify(error.response.data.errors), {autoClose: 1500});
                this.isLoading = false;
                console.log(error);
            });
        },
        uploadFile(e) {
            if(e.target.files.length > 0) {
                this.isLoading = true;
                let file = e.target.files[0];
                let formData = new FormData();
                formData.append('width', this.recomend_width_img);
                formData.append('height', this.recomend_height_img);
                formData.append('file', file);
                formData.append('path', 'categorias');
                
                categoryService.uploadFileImage(formData).then(response => {
                    toast.success(response.data.message, {autoClose: 1500});
                    this.categoria.image = response.data.file_url;
                    this.isLoading = false;
                }, error => {
                    toast.error(error.response.data.message, {autoClose: 1500});
                    this.isLoading = false;
                    console.log(error);
                });
            }
        }
    }
}
</script>