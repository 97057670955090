<template>
    <div v-if="!loading_users" class="info-top-users">
        <div class="info-title">
            <p class="title-top-users">Top Users</p>
            <p class="see-all" @click="see_all = !see_all">Ver Todo</p>
        </div>
        <div class="info-table" :class="{'active-all-users': see_all && top_users.length > 10}">
            <div class="table-title pb-2">
                <p>Nombre</p>
                <p>P. Personales</p>
            </div>
            <div v-if="!see_all" class="table-body" v-for="item in top_users.slice(0, 3)">
                <div class="info-user">
                    <div class="img-user">
                        <v-img v-if="item.image" :src="item.image"/>
                        <v-img v-if="!item.image" src="@/assets/images/image4.png"/>
                    </div>
                    <p class="name-user">{{ item.name }}</p>
                </div>
                <p>{{ item.total_score }}</p>
            </div>
            <div v-if="see_all" class="table-body" v-for="item in top_users">
                <div class="info-user">
                    <div class="img-user">
                        <v-img v-if="item.image" :src="item.image"/>
                        <v-img v-if="!item.image" src="@/assets/images/image4.png"/>
                    </div>
                    <p class="name-user">{{ item.name }}</p>
                </div>
                <p>{{ item.total_score }}</p>
            </div>
        </div>
    </div>
    <v-skeleton-loader v-if="loading_users" class="skeleton_users d-block ml-0" :loading="true" :elevation="0" type="sentences, list-item-avatar, list-item-avatar, list-item-avatar">
    </v-skeleton-loader>
</template>

<style>
.info-top-users{
    background-color: #ffffff;
    padding: 15px 30px;
    border-radius: 28px;
    box-shadow: 0px 4px 4px rgb(197 197 197 / 16%);
}
.info-top-users .info-title {
    display: flex;
    justify-content: space-between;
}
.info-top-users .info-title .see-all{
    font-size: 10px;
    font-weight: 700;
    color: #8B8694;
    cursor: pointer;
}
.info-top-users .info-title .title-top-users{
    text-align: justify;
    font-size: 16px;
    font-weight: 700;
    color: #0D1329;
}
.info-top-users .active-all-users {
    max-height: 500px;
    padding-right: 12px;
    overflow-y: scroll;
}
.info-top-users .active-all-users::-webkit-scrollbar-track {
  border-radius: 15px;
  background-color: rgba(39, 43, 65, 0.05);
}
.info-top-users .active-all-users::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.info-top-users .active-all-users::-webkit-scrollbar-thumb {
  background-color: #e3e6ef;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
.info-top-users .info-table{
    padding-top: 10px;
}
.info-top-users .info-table .table-title {
    display: flex;
    justify-content: space-between;
    color: #8B8694;
    font-size: 14px;
}
.info-top-users .info-table .table-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: justify;
    font-size: 12px;
    font-weight: 700;
    color: #0D1329;
    padding: 6px 0px;
}
.info-top-users .info-table .table-body .info-user {
    display: flex;
    align-items: center;
}
.info-top-users .info-table .table-body .info-user .name-user {
    padding-left: 12px;
    font-weight: 400;
}
.info-top-users .info-table .table-body .info-user .img-user {
    width: 36px;
    height: 36px;
}
.info-top-users .info-table .table-body .info-user .img-user .v-img__img--contain {
    border-radius: 12px;
    object-fit: cover !important;   
}
.skeleton_users {
    border-radius: 28px;
}
</style>

<script>
import userService from "@/composables/user";
import {VSkeletonLoader} from 'vuetify/labs/VSkeletonLoader';
import { mapGetters } from "vuex";

export default {
    name: "Top Users",
    components: {
        VSkeletonLoader
    },
    data: () => ({
        loading_users: true,
        top_users: [],
        see_all: false
    }),
    computed: {
        ...mapGetters(["userSession"]),
    },
    mounted () {
        if (this.userSession.role == 'student') {
            this.getUsersPoints();
        } else {
            this.loading_users = false;
        }
    },
    methods: {
        getUsersPoints() {
            this.loading_users = true;
            userService.getUsersPoints().then(response => {
                this.top_users = response.data.data;
                //Ordenamos los puntajes de mayor a menor
                this.top_users = this.top_users.sort((a, b) => {return b.total_score - a.total_score});
                setTimeout(() => {
                    this.loading_users = false;
                }, 1000)
            }).catch(e => {
                console.log(e);
            })
        },
    }
}
</script>